import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';
import { FiatFavoritesEmpty, IFiatFavorites, IServiceResponse } from '@2ndmarket/types';

const useFiatFavorites = (
  operation: string,
  filter: string,
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IFiatFavorites[]> => {
  const key = {
    endpoint: `/v4/fiat/users/${user_id}/favorites/${operation}?filter=${filter}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<Array<IFiatFavorites>, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? FiatFavoritesEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useFiatFavorites;
