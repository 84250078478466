import { Icon, themes } from '@2ndmarket/components';
import {
  Crypto,
  ICryptoAsset,
  cryptoAssets,
} from '@2ndmarket/helpers';
import { DrawerHeader } from './styles';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCrypto: ICryptoAsset;
  setSelectedCrypto: React.Dispatch<
    React.SetStateAction<ICryptoAsset>
  >;
}

const CryptoSelect: React.FC<Props> = ({
  open,
  setOpen,
  selectedCrypto,
  setSelectedCrypto,
}) => {
  function handleToggle(targetCrypto: ICryptoAsset) {
    setSelectedCrypto(targetCrypto);
    setOpen(false);
  }

  return (
    <Drawer
      open={open}
      anchor="right"
      role="presentation"
      onClose={() => setOpen(false)}
      sx={{
        '.MuiPaper-root': {
          backgroundColor: 'grey.900',
        },
      }}
    >
      <DrawerHeader
        sx={{
          pl: 3,
          width: {
            xs: 315,
            sm: 415,
            md: 415,
            lg: 415,
          },
        }}
      >
        <Stack
          gap={6}
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
        >
          <IconButton onClick={() => setOpen(false)}>
            <Icon
              size={15}
              name="arrow-alternative"
              rotate="180deg"
              color={themes.bankai.palette.grey[300]}
            />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Selecione a moeda
          </Typography>
        </Stack>
      </DrawerHeader>

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        height="100%"
        pb={4}
        sx={{
          px: 4,
          width: {
            xs: 315,
            sm: 415,
            md: 415,
            lg: 415,
          },
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '.MuiListItem-root': {
              width: '100%',
            },
            '.MuiAvatar-root': {
              mr: 2,
              ml: 1,
            },
            '.MuiTypography-root': {
              fontSize: '16px',
            },
            '.MuiListItemText-secondary': {
              fontSize: '16px',
              color: 'grey.300',
            },
          }}
        >
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleToggle(cryptoAssets.BTC)}
              dense
            >
              <Checkbox
                edge="start"
                checked={
                  selectedCrypto.abbreviation == Crypto.BTC
                }
                tabIndex={-1}
                disableRipple
              />
              <Avatar sx={{ bgcolor: 'crypto.bitcoin' }}>
                <Icon name="bitcoin" size={20} />
              </Avatar>
              <ListItemText primary="Bitcoin" secondary="BTC" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleToggle(cryptoAssets.BKAI)}
              dense
            >
              <Checkbox
                edge="start"
                checked={
                  selectedCrypto.abbreviation == Crypto.BKAI
                }
                tabIndex={-1}
                disableRipple
              />
              <Avatar sx={{ bgcolor: 'primary.main' }}>
                <Icon name="bitcoin" size={20} />
              </Avatar>
              <ListItemText
                primary="Bkaicoin"
                secondary="BKAI"
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Button
          type="button"
          onClick={() => setOpen(false)}
          sx={{
            bgcolor: 'grey.200',
            width: '100%',
            color: 'primary.main',
            '&:hover': {
              bgcolor: 'grey.200',
              border:
                '1px solid ' +
                themes.bankai.palette.primary.main,
            },
          }}
        >
          Selecionar
        </Button>
      </Box>
    </Drawer>
  );
};
export default CryptoSelect;
