import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { theme } from 'styled-tools';

interface Props {
  interaction?: boolean;
}

export const Container = styled.div<SpaceProps>`
  overflow-x: auto;
  ${space}
`;

export const Content = styled.div<Props>`
  @media (max-width: ${theme('breakpoints.mobile')}) {
    justify-content: flex-start;
  }

  table {
    border: 1px solid ${theme('colors.secondary.4')};
    border-spacing: 0;
    cursor: ${props => props.interaction && 'pointer'};
    width: 100%;

    th {
      background-color: ${theme('colors.background')};
      border-bottom: 1px solid ${theme('colors.secondary.4')};
      color: ${theme('colors.secondary.1')};
      padding: 12px 4px;
      text-align: left;

      &:first-of-type {
        padding-left: 20px;
      }

      &:last-of-type {
        padding-right: 20px;
      }
    }

    td {
      border: 0;
      color: ${theme('colors.secondary.2')};
      padding: 18px 4px;

      &:first-of-type {
        padding-left: 20px;
      }

      &:last-of-type {
        padding-right: 20px;
      }

      i {
        color: ${theme('colors.secondary.1')};
      }
    }

    tr:nth-child(odd) {
      background-color:rgba(0, 0, 0, 0.025);
    }

    tbody {
      tr {
        transition: background-color 0.25s ease-in-out;
      }
      tr:hover {
        background-color: ${props =>
    props.interaction && '#eff1f6'};
      }
    }
  }
`;
