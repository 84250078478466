import Targets from "../constants/Targets";

export const MenuDashboard = [
	{
		label: "Painel",
		target: Targets.ROOT,
	},
	{
		label: "Meus dados",
		target: Targets.DATA,
	},
	{
		label: "Endereço",
		target: Targets.ADDRESS,
	},
	{
		label: "Documento",
		target: Targets.DOCUMENT,
	},
	{
		label: "Telefone",
		target: Targets.PHONE,
	},
	{
		label: "Senha",
		target: Targets.PASSWORD,
	},
];
