export interface IAnnouncements {
  id: number,
  created_at: string,
  updated_at: string,
  name: string,
  description: string,
}

export const AnnouncementsEmpty: IAnnouncements = {
  id: 0,
  created_at: '',
  updated_at: '',
  name: '',
  description: '',
};