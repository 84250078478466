import { number } from './../../../helpers/translations/yup-pt/translations';
import { ReviewStep } from '@2ndmarket/helpers';

export interface IPhone {
  ddi: string;
  ddd: string;
  number: string;
  status: ReviewStep;
  updated_at: string;
}

export const PhoneEmpty: IPhone = {
  ddi: '',
  ddd: '',
  number: '',
  status: ReviewStep.APPROVED,
  updated_at: '',
};

export interface IPhoneInput {
  number: string;
  code: string;
}

export const PhoneInputEmpty: IPhoneInput = {
  number: '',
  code: '',
};
