export interface IPrice {
  id: number,
  value: number,
  fiat: string,
  created_at: Date | string,
  updated_at: Date | string,
  tendency: string,
  variation: number
}

export const PriceEmpty: IPrice = {
  id: 0,
  value: 0,
  fiat: '',
  created_at: "2023-11-09T14:38:16.141Z",
  updated_at: "2023-11-09T14:38:16.141Z",
  tendency: '',
  variation: 0
};
