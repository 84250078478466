import React from 'react';

import Badge from '@mui/material/Badge';

export interface MuiBulletProps {
  variant:
    | 'active'
    | 'nokyc'
    | 'analising'
    | 'locked'
    | 'deactivated';
}

function convert(newColor: any) {
  switch (newColor) {
    case 'pending': {
      return 'analising';
    }
    case 'refused': {
      return 'nokyc';
    }
    case 'blocked': {
      return 'locked';
    }
    case 'approved': {
      return 'active';
    }
    case 'disabled': {
      return 'deactivated';
    }
    default: {
      return newColor;
    }
  }
}

const MuiBullet: React.FC<MuiBulletProps> = ({ variant }) => {
  const types = {
    active: 'Ativos',
    nokyc: 'Sem KYC',
    analising: 'Em análise',
    locked: 'Bloqueados',
    deactivated: 'Desativados',
  };

  return (
    <Badge
      title={types[variant]}
      variant={convert(variant)}
      color={convert(variant)}
    />
  );
};

export default MuiBullet;
