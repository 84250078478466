import Documents from "../constants/Document";

export const RegisterDocuments = [
  {
    value: Documents.CPF,
    label: 'CPF',
  },
  {
    value: Documents.CNPJ,
    label: 'CNPJ',
  },
];