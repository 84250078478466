const enum ReviewStep {
	NONE = "none",
	REFUSED = "refused",
	PENDING = "pending",
	RECEIVED = "received",
	APPROVED = "approved",
	ANALYZING = "analysing",
	PROCESSING = "processing",
}

const enum ReviewName {
	REFUSED = "Rejeitado",
	PENDING = "Pendente",
	RECEIVED = "Recebido",
	APPROVED = "Aprovado",
	ANALYZING = "Analisando",
	PROCESSING = "Processando",
}

const enum ReviewTitle {
	REJECTED = "Recusado",
	REFUSED = "Rejeitado",
	PENDING = "Pendente",
	BLOCKED = "Bloqueado",
	APPROVED = "Ativo",
	ANALYZING = "Aguardando aprovação",
}

export { ReviewStep, ReviewName, ReviewTitle };
