import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatBRL, parseBRL, yupPt } from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ContentTitle } from '../../../../components';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  setTransferAmount: React.Dispatch<
    React.SetStateAction<number>
  >;
}

const steps = ['Início', 'P2P', 'Valor'];

const AmountStep: React.FC<Props> = ({
  setStep,
  setTransferAmount,
}) => {
  const router = useRouter();
  const [amount, setAmount] = useState<number>();

  const defaultValues = { amount: 0 };
  const schema = yupPt.object().shape({
    amount: yupPt
      .string()
      .transform(originalValue => {
        const formattedValue = originalValue.replace(
          /[^\d]/g,
          '',
        );
        return formattedValue;
      })
      .test('min-amount', 'Valor mínimo de R$1,00', value => {
        const centsValue = Number(value);
        return centsValue >= 100;
      }),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: { amount: number }) {
    if (!amount) {
      return;
    }
    setTransferAmount(amount);
    setStep('step2');
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <ContentTitle title="Transferir" steps={steps} />
      <TextField
        {...register('amount', { required: true })}
        fullWidth
        margin="dense"
        label="Valor"
        autoComplete="off"
        value={amount ? formatBRL(amount) : ''}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setAmount(parseBRL(event.target.value));
        }}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
            maxLength: 16,
          },
        }}
        error={Boolean(errors.amount)}
        helperText={errors.amount && errors.amount.message}
        sx={{ mb: 4 }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => router.back()}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
    </Box>
  );
};
export default AmountStep;
