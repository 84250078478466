import UserStatus from "../constants/UserStatus";

export const UserStatuses = [
	{
		value: UserStatus.DISABLED,
		label: "Desabilitado",
	},
	{
		value: UserStatus.BLOCKED,
		label: "Bloqueado",
	},
	{
		value: UserStatus.PENDING,
		label: "Pendente",
	},
	{
		value: UserStatus.REFUSED,
		label: "Recusado",
	},
	{
		value: UserStatus.APPROVED,
		label: "Aprovado",
	},
];
