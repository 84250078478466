import { Icon } from '@2ndmarket/components';
import { useRouter } from 'next/router';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface Props {
  cryptoName: string;
}

const CryptoPaymentActions: React.FC<Props> = ({
  cryptoName,
}) => {
  const router = useRouter();

  return (
    <Box
      sx={{
        display: {
          xs: 'grid',
          md: 'grid',
        },
        mb: 4,
        gap: '12px',
        gridTemplateColumns: {
          xs: '1fr 1fr',
          md: '1fr 1fr',
        },
        whiteSpace: {
          xs: 'nowrap',
          md: 'normal',
        },
        overflowX: {
          xs: 'auto',
          md: 'visible',
        },
        '.MuiButtonBase-root': {
          height: 'auto',
          bgcolor: 'common.white',
          p: {
            xs: '10px 12px',
            md: '12px 16px',
          },
          justifyContent: 'start',
          gap: 1,
          mr: {
            xs: '8px',
            md: '0',
          },
          borderRadius: '3px',
          '&:hover': {
            transition:
              'box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow:
              '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
            bgcolor: 'common.white',
          },
        },
        '.MuiAvatar-root': {
          bgcolor: 'grey.400',
          width: {
            xs: '42px',
            md: '52px',
          },
          height: {
            xs: '42px',
            md: '52px',
          },
          i: {
            color: 'primary.main',
          },
        },
        '.MuiTypography-root': {
          color: 'text.primary',
        },
      }}
    >
      <Button
        variant="text"
        onClick={() =>
          router.push(
            `/carteira-cripto/${cryptoName}/transferir`,
          )
        }
      >
        <Avatar>
          <Icon name="send" size={20} />
        </Avatar>
        <Typography component="p" variant="body1">
          Transferir
        </Typography>
      </Button>
      <Button
        variant="text"
        onClick={() =>
          router.push(`/carteira-cripto/${cryptoName}/receber`)
        }
      >
        <Avatar>
          <Icon name="receive" size={18} />
        </Avatar>
        <Typography component="p" variant="body1">
          Receber
        </Typography>
      </Button>
    </Box>
  );
};

export default CryptoPaymentActions;
