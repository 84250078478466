const Avatar = (theme: any) => {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 52,
          height: 52,
          fontSize: 16,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
  };
};

export default Avatar;
