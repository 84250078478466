import { Icon } from '@2ndmarket/components';
import { dayjsUtc, formatBRL } from '@2ndmarket/helpers';
import { useBillCashIn } from '@2ndmarket/services';

import { ContentTitle } from '../../../../components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  billData: any;
  cashinId: number;
}

const steps = ['Início', 'Valor', 'Revisão'];

const InfoStep: React.FC<Props> = ({
  setStep,
  billData,
  cashinId,
}) => {
  const { data: cashin, loading: cashinLoading } =
    useBillCashIn(cashinId);

  function copyData(data: string) {
    navigator.clipboard.writeText(data);
  }

  if (typeof navigator !== 'undefined') {
    var sharePromise = navigator.share;
  }

  async function share() {
    navigator.share(cashin?.slip_typeable_line);
  }

  return (
    <Box>
      <ContentTitle title="Boleto" steps={steps} />
      <Typography
        component="p"
        sx={{ fontWeight: '500', color: 'text.secondary' }}
      >
        Depositando
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        color="primary.main"
      >
        {formatBRL(billData.amount)}
      </Typography>
      <Box>
        {cashinLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              mb: 3,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              type="button"
              onClick={() =>
                copyData(cashin?.slip_typeable_line)
              }
              variant="clear"
              fullWidth
              sx={{
                bgcolor: 'secondary.light',
                position: 'relative',
                display: 'block',
                py: 2,
                pl: {
                  xs: 2,
                  md: 3,
                },
                pr: {
                  xs: 5,
                  md: 3,
                },
                mt: 2,
                mb: 3,
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
                '& i': {
                  position: 'absolute',
                  right: '16px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'grey.300',
                },
                '&:hover': {
                  bgcolor: 'secondary.main',
                },
              }}
            >
              <Typography
                component="span"
                variant="body1"
                color="primary.main"
                sx={{
                  overflowWrap: 'break-word',
                  color: 'text.primary',
                }}
              >
                {cashin?.slip_typeable_line}
              </Typography>
              <Icon name="copy" />
            </Button>
            <TextField
              value={dayjsUtc(
                cashin.slip_due_date,
                'YYYY-MM-DD',
              ).format('DD/MM/YYYY')}
              disabled
              sx={{ marginBottom: 5 }}
              fullWidth
              label="Vencimento"
            />
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => setStep('step1')}
        >
          Voltar
        </Button>

        {
          //@ts-ignore
          sharePromise && (
            <Button
              onClick={share}
              type="button"
              variant="contained"
            >
              Compartilhar
            </Button>
          )
        }
      </Box>
    </Box>
  );
};
export default InfoStep;
