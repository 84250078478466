import React, { useState, useEffect } from 'react';
import Icon from '../Icon';
import {
  WrapCustomSelect,
  SearchBox,
  SearchBoxHeader,
  WrapSearchBox,
} from './styles';

interface OptionsProps {
  value: string | number;
  label: string;
  icon?: string;
}

interface Props {
  value: string | number;
  defaultValue: string | number;
  options: OptionsProps[];
  error?: string;
  onChange: (param: string) => any;
  errorMessage?: string;
  name: string;
  placeholder: string;
  searchPlaceholder: string;
  disabled?: boolean;
  icon?: boolean;
  noPlaceholder?: boolean;
  dataTestid?: string;
}

const CustomSelect: React.FC<Props> = React.forwardRef(
  (
    {
      value,
      defaultValue,
      options,
      error,
      onChange,
      errorMessage,
      name,
      placeholder,
      searchPlaceholder,
      disabled,
      icon,
      noPlaceholder,
      dataTestid,
    },
    ref,
  ) => {
    const [searchBox, setSearchBox] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<
      OptionsProps[]
    >([]);
    const [searchValue, setSearchValue] = useState('');
    const [inputContent, setInputContent] = useState('');

    const [selectedIcon, setSelectedIcon] = useState('');

    const LoadedIcon = () => {
      const initialIcon = options.find(
        x => x.value == value,
      )?.icon;
      return (
        <img src={initialIcon} alt="" className="selectIcon" />
      );
    };

    useEffect(() => {
      if (options) {
        setFilteredOptions(options);

        const currentValue = value
          ? value
          : defaultValue
          ? defaultValue
          : '';

        if (currentValue) {
          const currentOptions = options.find(elem => {
            return elem.value == value;
          });
          if (currentOptions) {
            setInputContent(currentOptions.label);
          }
        }
      }
    }, [defaultValue, options, value]);

    const selectAction = (event: any) => {
      const newVal = event.target.getAttribute('data-val');
      const newLabel = event.target.getAttribute('data-text');

      setInputContent(newLabel);
      setSearchBox(false);
      onChange(newVal);
    };

    const filter = (e: any) => {
      const keyword = e.target.value;

      if (keyword != '') {
        const results = options.filter(item => {
          return item.label
            .toLowerCase()
            .includes(keyword.toLowerCase());
        });
        setFilteredOptions(results);
      } else {
        setFilteredOptions(options);
      }
      setSearchValue(keyword);
    };

    useEffect(() => {}, [searchBox]);

    return (
      <>
        <WrapCustomSelect
          filled={value ? true : false}
          disabled={disabled}
          tabIndex={0}
          error={!!error}
          icon={icon}
          noPlaceholder={noPlaceholder}
          onClick={() => {
            setSearchBox(true);
          }}
        >
          <Icon name="arrow" />
          {icon && value && !selectedIcon && <LoadedIcon />}

          {selectedIcon && (
            <img
              src={selectedIcon}
              alt=""
              className="selectIcon"
            />
          )}
          <span className="inputContent">
            {inputContent.length > 30
              ? inputContent.substring(0, 30) + '...'
              : inputContent}
          </span>
          <input
            type="hidden"
            value={value}
            data-testid={dataTestid}
            onChange={(e: any) => {
              onChange(e.toString());
            }}
          />
          <label htmlFor={name}>{placeholder}</label>
          <span className="errorMessage">
            {errorMessage ? errorMessage : 'Campo inválido'}
          </span>
        </WrapCustomSelect>

        {searchBox && (
          <WrapSearchBox>
            <SearchBox>
              <SearchBoxHeader>
                <h2>{placeholder}</h2>
                <button
                  className="closeButton"
                  onClick={() => setSearchBox(false)}
                >
                  <Icon name="close" />
                </button>
              </SearchBoxHeader>
              <div className="wrapSearch">
                <Icon name="search" size={15} />
                <input
                  type="text"
                  value={searchValue}
                  autoComplete="new-password"
                  placeholder={searchPlaceholder}
                  onChange={filter}
                />
              </div>
              <ul className="selectList">
                {filteredOptions.map((item, key) => (
                  <li
                    onClick={selectAction}
                    data-val={item.value}
                    data-text={item.label}
                    key={key}
                  >
                    <span
                      className={
                        item.value == value ? 'checked' : ''
                      }
                    >
                      <Icon name="check" size={10} />
                    </span>
                    {item.label}
                  </li>
                ))}
              </ul>
              <h4
                className="closeText"
                onClick={() => setSearchBox(false)}
              >
                Cancelar
              </h4>
            </SearchBox>
          </WrapSearchBox>
        )}
      </>
    );
  },
);

CustomSelect.displayName = 'CustomSelect';

export default CustomSelect;
