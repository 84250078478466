import {
  Operation,
  Permission,
  validatePermission,
} from '@2ndmarket/helpers';

class PermissionError extends Error {
  constructor() {
    super(
      'Você não possui permissão para acessar esta informação.',
    );
    this.name = 'PermissionError';
  }
}

interface Props {
  permission: Permission;
  operation: Operation;
  children: any;
}

const PermissionBarrier: React.FC<Props> = ({
  children,
  permission,
  operation,
}) => {
  return children;
};

export default PermissionBarrier;
