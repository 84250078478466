import { parseJwt } from "../../formatters/jwt";
import LocalStorageKeys from "../../providers/localStorageKeys";
import Operation from "../../constants/Operation";
import Permission from "../../constants/Permission";

function getPermissionsFromJwt() {

  const isServerSideRendering = !(typeof window !== 'undefined')
  if (isServerSideRendering) {
    return true
  }

  const currentJwt = window.localStorage.getItem(LocalStorageKeys.JWT)

  const jwtPayload = parseJwt(currentJwt ? currentJwt : '')

  if (jwtPayload?.permissions) {
    return jwtPayload.permissions
  }

  return {}
}

export const validatePermission = function (
  permission: Permission,
  operation: Operation
) {

    return true;

  const isServerSideRendering = !(typeof window !== 'undefined')
  if (isServerSideRendering) {
    return true
  }

  const permissions = getPermissionsFromJwt();

  return (permissions.hasOwnProperty(permission) &&
    permissions[permission].includes(operation))
};
