import { useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatBRL, yupPt } from '@2ndmarket/helpers';
import { usePrice } from '@2ndmarket/services';
import { ContentTitle } from '../../../../components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  setTransferAmount: React.Dispatch<
    React.SetStateAction<string>
  >;
  cryptoName: string;
}

const AmountStep: React.FC<Props> = ({
  setStep,
  setTransferAmount,
  cryptoName,
}) => {
  const steps = ['Início', cryptoName.toUpperCase(), 'Valor'];
  const router = useRouter();
  const [amount, setAmount] = useState<string>('');
  const [amountComma, setAmountComma] = useState<string>('');
  const { data: coinPrice } = usePrice(cryptoName);

  const defaultValues = { amount: 0 };
  const schema = yupPt.object().shape({
    amount: yupPt
      .string()
      .transform(originalValue => {
        const formattedValue = originalValue.replace(
          /[^\d]/g,
          '',
        );
        return formattedValue;
      })
      .test(
        'min-amount',
        'Deve ser maior ou igual a 1 satoshi',
        value => {
          const centsValue = Number(value);
          return centsValue >= 0.00000001;
        },
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleNumberChange = (value: string) => {
    let cleanedValue = value
      .replace(',', '.')
      .replace(/[^0-9.]/g, '');

    const decimalCount = cleanedValue.split('.').length - 1;
    if (decimalCount > 1) {
      cleanedValue = cleanedValue.slice(
        0,
        cleanedValue.lastIndexOf('.'),
      );
    }
    setAmountComma(cleanedValue.replace('.', ','));
    setAmount(cleanedValue);
  };

  function onSubmit(data: { amount: number }) {
    if (!amount) {
      return;
    }
    setTransferAmount(amount);
    setStep('step2');
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <ContentTitle title="Transferir" steps={steps} />
      <TextField
        {...register('amount', { required: true })}
        fullWidth
        margin="dense"
        label="Valor"
        autoComplete="off"
        value={amountComma}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          handleNumberChange(event.target.value);
        }}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
          },
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                '.MuiTypography-root': {
                  color: 'grey.300',
                  fontSize: '14px',
                },
              }}
            >
              {`≈ ${formatBRL(
                Number(amount) * coinPrice.value,
              )}`}
            </InputAdornment>
          ),
        }}
        error={Boolean(errors.amount)}
        helperText={errors.amount && errors.amount.message}
        sx={{ mb: 4 }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => router.back()}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
    </Box>
  );
};
export default AmountStep;
