import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';
import { IPhone, IServiceResponse, PhoneEmpty } from '@2ndmarket/types';

const usePhone = (
  user_id: number | AppConstants.ME = AppConstants.ME
):IServiceResponse<IPhone> => {
  const key = {
    endpoint: `/v4/users/${user_id}/phone`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IPhone, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? PhoneEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default usePhone;
