import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const fiatRequest = function (
  user_id: number | AppConstants.ME = AppConstants.ME,
) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/users/${user_id}/digital-account/open`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};

export default fiatRequest;
