import React, { useEffect, useState } from 'react';
import TextField from '../TextField';
import { TextFieldProps } from '../TextField/TextField';
import textToNumber from '@2ndmarket/helpers/converters/textToNumber';
import { BTC_DECIMAL_PLACES } from '@2ndmarket/helpers/providers/crypto/bitcoin';
import { toBitcoin } from '@2ndmarket/helpers';

const TextFieldCrypto: React.FC<TextFieldProps> =
  React.forwardRef(
    (
      {
        id,
        name,
        value = '',
        placeholder,
        error,
        disabled,
        mb,
        mt,
        readOnly = false,
        onChange = () => {},
        invalidChars,
        className,
        maxLength,
      },
      ref,
    ) => {
      const [text, setText] = useState(value);

      const onTextChange = (newText: string) => {
        const trimmedText = newText.trim().replaceAll('.', ',');

        if (trimmedText == ',') {
          onChange('0');
          setText('0,');
          return;
        }

        const amountInBtc = textToNumber(
          trimmedText,
          BTC_DECIMAL_PLACES,
        );

        onChange(amountInBtc.toString());
        setText(trimmedText);
      };

      let cryptoBalanceFormatted = toBitcoin(value);
      if (cryptoBalanceFormatted == '0,00') {
        cryptoBalanceFormatted = '';
      }

      useEffect(() => {
        if (value === '' || value === undefined) {
          setText(value);
        }
      }, [value]);

      return (
        <TextField
          id={id}
          name={name}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          mb={mb}
          mt={mt}
          invalidChars={invalidChars}
          readOnly={readOnly}
          value={text ? text : cryptoBalanceFormatted}
          onChange={onTextChange}
          className={className}
          autocomplete="off"
          maxLength={maxLength}
          dataTestid="textfield-input"
        />
      );
    },
  );

TextFieldCrypto.displayName = 'TextFieldCrypto';

export default TextFieldCrypto;
