import * as yup from "yup";
import { string } from "yup";
import { dayjsUtc } from "../..";
import localeShort from "./translations";
import {
	validateBirthDate,
	validateExpirationDate,
	validateIssueDate,
} from "@2ndmarket/helpers";

yup.setLocale(localeShort);

yup.addMethod(string, "cep", function () {
	return this.test("test-cep", "CEP Inválido", function (value) {
		if (!value || value == "") {
			return false;
		}
		const onlyNumbers = value.replace(/\D/g, "");
		return onlyNumbers.length == 8;
	});
});

yup.addMethod(string, "dateBirth", function () {
	return this.test(
		"test-date-birth",
		"",
		function (value, { createError }) {
			if (!value || value == "") {
				return true;
			}

			const data = dayjsUtc(value, "DD/MM/YYYY", true);
			if (!data.isValid()) {
				return createError({
					message: "Data inválida",
				});
			}

			return true;
		}
	);
});

yup.addMethod(string, "dateGreater", function () {
	return this.test(
		"test-date-greater",
		"Data inválida",
		function (value) {
			if (!value || value == "") {
				return true;
			}
			return validateExpirationDate(value);
		}
	);
});

yup.addMethod(string, "dateSmaller", function () {
	return this.test(
		"test-date-smaller",
		"Data inválida",
		function (value) {
			if (!value || value == "") {
				return true;
			}
			return validateIssueDate(value);
		}
	);
});

export default yup;
