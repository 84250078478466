import { useState } from 'react';
import { Icon, themes } from '@2ndmarket/components';
import { formatCpfCnpj } from '@2ndmarket/helpers';
import { useMe, usePhone } from '@2ndmarket/services';
import { DrawerHeader } from './styles';
import CloseAccount from './CloseAccount';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerData: React.FC<Props> = ({ open, setOpen }) => {
  const { data: me } = useMe();
  const { data: phone } = usePhone();
  const cpfLength = 11;
  const [openClose, setOpenClose] = useState(false);

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={() => setOpen(false)}
        sx={{
          '.MuiPaper-root': {
            backgroundColor: themes.bankai.palette.grey[400],
          },
        }}
      >
        <DrawerHeader
          sx={{
            pl: 3,
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 490,
            },
          }}
        >
          <Stack
            gap={6}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="arrow-alternative"
                rotate="180deg"
                color={themes.bankai.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Meus dados
            </Typography>
          </Stack>
        </DrawerHeader>

        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            px: 4,
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 490,
            },
            '.MuiListItem-root': {
              bgcolor: 'common.white',
              'span.MuiTypography-root': {
                fontWeight: '500',
              },
              'p.MuiTypography-root': {
                color: 'grey.300',
              },
            },
          }}
        >
          <ListItem>
            <ListItemText primary={me.name} secondary="Nome" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={me.email}
              secondary="E-mail"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                '(' +
                phone.ddd +
                ') ' +
                phone.number.replace(/(\d)(\d{4})$/, '$1-$2')
              }
              secondary="Telefone"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={formatCpfCnpj(me.cpf_cnpj)}
              secondary={
                me.cpf_cnpj.length > cpfLength ? 'CNPJ' : 'CPF'
              }
            />
          </ListItem>
          <ListItem>
            <Button
              variant="text"
              onClick={() => setOpenClose(true)}
              sx={{
                width: '100%',
                justifyContent: 'start',
                p: 0,
                textDecoration: 'underline',
                '&:hover': {
                  bgcolor: 'common.white',
                  color: 'primary.dark',
                  textDecoration: 'underline',
                },
              }}
            >
              Encerrar minha conta
            </Button>
          </ListItem>
        </List>
      </Drawer>
      <CloseAccount open={openClose} setOpen={setOpenClose} />
    </>
  );
};
export default CustomerData;
