import { useCallback, useEffect, useRef, useState } from 'react';
import { toPng } from 'html-to-image';

import { Icon, themes } from '@2ndmarket/components';
import { IReceiptData, PropsReceipt } from '@2ndmarket/types';
import { IconFormat } from '../../Icon/Icon';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Product } from '@2ndmarket/helpers';

const Receipt: React.FC<PropsReceipt> = ({ infos, type }) => {
  const [avatar, setAvatar] = useState('');

  const ref =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  function fileName() {
    return `comprovante-${infos?.date}`;
  }

  const downloadPng = useCallback(() => {
    toPng(ref.current!, { cacheBust: true }).then(dataUrl => {
      const link = document.createElement('a');
      link.download = fileName();
      link.href = dataUrl;
      link.click();
      link.remove();
    });
  }, [ref]);

  useEffect(() => {
    switch (infos.icon) {
      case 'check':
        setAvatar(themes.authentication.palette.success.light);
        break;
      case 'time':
        setAvatar(themes.authentication.palette.warning.light);
        break;
      case 'close':
        setAvatar(themes.authentication.palette.error.light);
        break;
      case 'bag':
        setAvatar(themes.authentication.palette.info.light);
        break;
      case 'half':
        setAvatar(themes.authentication.palette.info.light);
        break;
    }
  }, [infos, setAvatar]);

  const Line = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '13px 0',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  }));

  const Title = styled(Box)(() => ({
    gap: '16px',
    display: 'flex',
    alignItems: 'center',
    margin: '40px 0 13px 0',
  }));

  return (
    <Box paddingX="100px" id="receipt">
      {type != Product.BACKOFFICE && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="clear"
            onClick={() => downloadPng()}
            sx={{ px: 4, py: 2, mr: -4, mt: -2 }}
          >
            <Icon name="download" size={22} />
          </Button>
        </Box>
      )}
      <Box
        gap={2}
        width="100%"
        display="flex"
        padding="26px 30px"
        flexDirection="column"
        sx={{
          borderRadius: '3px',
          backgroundColor: 'white',
        }}
        ref={ref}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography component="h3" variant="h3">
              {infos.title}
            </Typography>
            <Typography component="p" variant="body2">
              {infos.date}
            </Typography>
          </Box>
          <Icon
            size={26}
            name="logo-2nd"
            viewBox="0 0 32 32"
            format={IconFormat.SVG}
            color={themes.authentication.palette.grey[700]}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Box
            sx={{
              backgroundColor: avatar,
              borderRadius: '50%',
              lineHeight: 0,
              px: '19px',
              pt: '21px',
              pb: '17px',
            }}
          >
            <Icon
              size={16}
              name={infos.icon}
              color={infos.color}
              viewBox="0 0 20 20"
              format={IconFormat.SVG}
            />
          </Box>
        </Box>
        <Box>
          {infos.data.map((item: IReceiptData, key) => {
            switch (item.type) {
              case 'text':
                return (
                  <Line key={key}>
                    <Typography
                      component="p"
                      variant="body1"
                      color={
                        themes.authentication.palette.text
                          .disabled
                      }
                      sx={{ width: '50%' }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{
                        width: '50%',
                        textAlign: 'right',
                        wordWrap: 'break-word',
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Line>
                );
              case 'text-icon':
                return (
                  <Title key={key}>
                    <Icon
                      size={20}
                      name={item.icon}
                      viewBox="0 0 20 20"
                      format={IconFormat.SVG}
                    />
                    <Typography variant="body1" component="h4">
                      {item.value}
                    </Typography>
                  </Title>
                );
              case 'text-message':
                return (
                  <Line key={key}>
                    <Typography component="h3" variant="body1">
                      {item.label}
                    </Typography>
                    <Typography component="p" variant="body1">
                      {item.value}
                    </Typography>
                  </Line>
                );
            }
          })}
        </Box>
        <Box>
          <Typography component="p" variant="body1">
            {infos.company}
          </Typography>
          <Typography component="p" variant="body1">
            CNPJ {infos.cnpj}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Receipt;
