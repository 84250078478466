import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const sendFiatP2p = function (
  amount: number,
  description: string,
  cpf_cnpj: string,
  pin: string,
  add_favorite: boolean,
  user_id: number | AppConstants.ME = AppConstants.ME
) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/users/${user_id}/p2p`,
    headers: {
      'Content-Type': 'application/json',
      'user-pin': pin,
    },
    method: 'POST',
    body: JSON.stringify({
      amount: amount,
      description: description,
      cpf_cnpj: cpf_cnpj,
      add_favorite: add_favorite,
    }),
  });
};

export default sendFiatP2p;
