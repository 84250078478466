import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

interface BrandProps {
  brand?: string;
}

export const InnerModal = styled.div<BrandProps>`
  background-color: ${theme('colors.background')};
  width: 400px;
  padding: 0 20px 30px 20px;

  @media only screen and (max-width: 1200px) {
    width: auto;
    padding: 0 0 30px 0;
  }

  h3 {
    color: ${theme('colors.secondary.1')};
    font-size: 24px;
    margin-bottom: 6px;
    font-weight: 600;

    ${props =>
      props.brand === 'braex' &&
      css`
        color: ${theme('colors.white')};
      `}
  }

  p {
    color: ${theme('colors.secondary.2')};
    font-size: 16px;
    margin-bottom: 80px;

    ${props =>
      props.brand === 'braex' &&
      css`
        margin-top: 20px;
        color: ${theme('colors.secondary.4')};
      `}

    b {
      ${props =>
        props.brand === 'braex' &&
        css`
          color: ${theme('colors.white')};
        `}
    }
  }

  span {
    width: 100%;
    display: flex;
    font-size: 14px;
    padding-bottom: 20px;
    align-items: center;
    align-content: center;
    color: ${theme('colors.secondary.2')};

    b {
      color: ${theme('colors.white')};
      font-size: 14px;
      margin-left: 0;
    }
  }

  a,
  button {
    display: block;
    width: 100%;
    text-align: center;
    background-color: ${theme('colors.primary.main')};
    color: ${theme('colors.white')};
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    height: 45px;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.25s;

    &:hover {
      background-color: ${theme('colors.primary.dark')};
    }
  }

  button {
    margin-top: 10px;
    background-color: ${theme('colors.secondary.4')};
    color: ${theme('colors.primary.main')};

    ${props =>
      props.brand === 'braex' &&
      css`
        color: ${theme('colors.primary.main')};
        background-color: ${theme('colors.secondary.1')};
      `}

    &:hover {
      color: ${theme('colors.white')};
      background-color: ${theme('colors.primary.light')};
    }
  }
`;
