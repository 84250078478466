import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { IServiceResponse, ITransactionDatetime, TransactionDatetimeEmpty } from '@2ndmarket/types';

const useTransactionDateInfo = () : IServiceResponse<ITransactionDatetime> => {
  const key = {
    endpoint: `/v4/locale/transaction`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<ITransactionDatetime>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? TransactionDatetimeEmpty,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useTransactionDateInfo;
