import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

interface BrandProps {
  brand?: string;
}

export const InnerModal = styled.div<BrandProps>`
  background-color: ${theme('colors.background')};
  padding: 0 10px 30px 10px;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 400px;

  @media only screen and (max-width: 1200px) {
    width: auto;
    padding: 0 0 30px 0;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    font-family: ${theme('fontFamily.0')};

    line-height: 30px;
    margin-bottom: 15px;
    color: ${theme('colors.modal.warnings.title')};
  }

  p {
    width: 100%;
    display: flex;

    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    color: ${theme('colors.modal.warnings.paragraph')};
  }

  span {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    font-family: ${theme('fontFamily.0')};

    line-height: 35px;
    align-items: center;
    align-content: center;
    color: ${theme('colors.modal.warnings.span')};
  }

  img, svg {
    display: flex;
    text-align: center;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }

  ul {
    width: 100%;
    display: flex;
    list-style: none;
    text-align: left;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    color: ${theme('colors.modal.warnings.title')};

    li {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      font-family: ${theme('fontFamily.0')};
    }
    li::before {
      content: '✔';
      padding-right: 5px;
      color: ${theme('colors.modal.warnings.check')};
    }
  }

  a {
    width: 100%;
    height: 45px;
    display: block;
    text-align: center;
    color: ${theme('colors.white')};
    background-color: ${theme('colors.primary.main')};

    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    border-radius: 2px;
    padding: 10px 10px;
    transition: background-color 0.25s;

    &:hover {
      background-color: ${theme('colors.primary.dark')};
    }
  }

  a.btn-gray {
    margin: 15px 0;
    color: ${theme('colors.primary.main')};
    background-color: ${theme('colors.modal.warnings.button')};

    > span {
      justify-content: center;
      align-items: center;
      line-height: 25px;
    }

    &:hover {
      color: ${theme('colors.primary.main')};
      background-color: ${theme('colors.primary.light')};
    }
  }
`;
