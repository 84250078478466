import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HttpError,
  IntentStatus,
  Status,
  formatBRL,
  parseBRL,
  yupPt,
} from '@2ndmarket/helpers';
import { sendBillCashIn } from '@2ndmarket/services';
import {
  ContentTitle,
  TransactionModal,
  TransferAwait,
} from '../../../../components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  setTransferAmount: React.Dispatch<
    React.SetStateAction<number>
  >;
  setBillData: (value: object) => void;
  setBillId: React.Dispatch<React.SetStateAction<number>>;
  setCashinId: React.Dispatch<React.SetStateAction<number>>;
}

const AmountStep: React.FC<Props> = ({
  setStep,
  setBillData,
  setBillId,
  setCashinId,
}) => {
  const router = useRouter();
  const [amount, setAmount] = useState<number>(0);
  const [modal, setModal] = useState(false);
  const [intentStatus, setIntentStatus] = useState(
    IntentStatus.NONE,
  );
  const [intentDescription, setIntentDescription] = useState<
    string | null
  >(null);
  const modalTitle = 'Boleto de depósito';
  const [modalText, setModalText] = useState<string | null>('');
  const [transferDataPromise, setTransferDataPromise] =
    useState<any>();

  const schema = yupPt.object().shape({
    doc: yupPt.number().min(100, 'Valor mínimo de R$1,00'),
  });
  const defaultValues = { amount: 0 };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: { amount: number }) {
    setIntentStatus(IntentStatus.NONE);
    setModal(true);
    sendBillCashIn(amount)
      .then(() => {
        const responseBillCashIn = localStorage.getItem(
          '@locationHeader',
        );

        const response = responseBillCashIn
          ?.split('?')[1]
          .split('&')
          .reduce(
            (accumulator: any, item: any) => {
              const [key, value] = item.split('=');
              accumulator[key] = value;
              return accumulator;
            },
            {
              amount: amount,
              date: new Date(),
            },
          );

        setTransferDataPromise({
          id: response?.intent_id,
          type: response?.type,
          register: response?.is_register,
        });

        setBillData({
          amount: response?.amount,
          id: response?.intent_id,
          type: response?.type,
          register: response?.is_register,
          date: response?.date.toLocaleString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        });
      })
      .catch((error: HttpError) => {
        setValue('amount', 0);
        setModalText(error.error);
        setIntentStatus(IntentStatus.FAILURE);
      });
  }

  const steps = ['Início', 'Valor'];

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <ContentTitle title="Boleto" steps={steps} />
      <TextField
        {...register('amount', { required: true })}
        fullWidth
        margin="dense"
        label="Valor do depósito"
        autoComplete="off"
        value={amount ? formatBRL(amount) : ''}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setAmount(parseBRL(event.target.value));
        }}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
            maxLength: 16,
          },
        }}
        error={Boolean(errors.amount)}
        helperText={errors.amount && errors.amount.message}
        sx={{ mb: 4 }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => router.back()}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
      {transferDataPromise?.id && (
        <TransferAwait
          data={transferDataPromise}
          setIntentStatus={setIntentStatus}
          setIntentDescription={setModalText}
          setBillId={setBillId}
          setCashinId={setCashinId}
          stepRedirect={setStep}
          redirect="step2"
        />
      )}
      {modal && (
        <TransactionModal
          open={modal}
          title={modalTitle}
          text={modalText}
          status={intentStatus}
          onClose={() => setModal(false)}
        />
      )}
    </Box>
  );
};
export default AmountStep;
