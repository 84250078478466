const GREY = {
  0: '#FFFFFF',
  100: '#FDFDFD',
  200: '#E5E9EE',
  300: '#B4B9C7',
  400: '#F5F5F8',
  500: '#919EAB',
  600: '#535668',
  700: '#292D37',
  800: '#EB3E65',
  900: '#FDFDFF',
};

const PRIMARY = {
  main: '#FF8724',
  dark: '#d76100',
  light: '#F4A3AC',
  contrastText: '#FFEBF0',
};

const SECONDARY = {
  main: '#FFEBF0',
  dark: '#F4A3AC',
  light: '#E5E9EE',
  contrastText: '#E71346',
};

const SUCCESS = {
  main: '#4BD281',
  contrastText: '#E9F7F3',
};

const ERROR = {
  main: '#D12100',
  contrastText: '#F8ECEE',
};

const WARNING = {
  main: '#F7931A',
  contrastText: '#F9F2EA',
};

const INFO = {
  main: '#E71346',
  contrastText: '#E9F7F3',
};

const CRYPTO = {
  bitcoin: '#FF8724',
}

const palette = {
  mode: 'light',
  common: {
    black: '#000',
    white: '#FFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  error: ERROR,
  warning: WARNING,
  success: SUCCESS,  
  info: INFO,
  crypto: CRYPTO,
  grey: GREY,
  text: {
    primary: '#292D37',
    secondary: '#535668',
    disabled: '#B4B9C7',
  },
  background: {
    paper: GREY[0],
    default: GREY[400],
    neutral: GREY[100],
  },
};

export default palette;
