import NextLink from 'next/link';
import { Icon, themes } from '@2ndmarket/components';
import { ExternalUrls } from '@2ndmarket/helpers';
import { DrawerHeader } from './styles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseAccount: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <Drawer
      open={open}
      anchor="right"
      role="presentation"
      onClose={() => setOpen(false)}
      sx={{
        '.MuiPaper-root': {
          backgroundColor: themes.bankai.palette.grey[400],
        },
      }}
    >
      <DrawerHeader
        sx={{
          pl: 3,
          width: {
            xs: 315,
            sm: 415,
            md: 415,
            lg: 490,
          },
        }}
      >
        <Stack
          gap={6}
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
        >
          <IconButton onClick={() => setOpen(false)}>
            <Icon
              size={15}
              name="arrow-alternative"
              rotate="180deg"
              color={themes.bankai.palette.grey[300]}
            />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Deseja encerrar sua conta?
          </Typography>
        </Stack>
      </DrawerHeader>

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        height="100%"
        pb={4}
        sx={{
          px: 4,
          width: {
            xs: 315,
            sm: 415,
            md: 415,
            lg: 490,
          },
        }}
      >
        <Box>
          <Typography component="p" variant="body1" mb={2}>
            Você é muito importante para o nosso ecossistema!
            Porém, para encerrar sua conta, é preciso atender os
            seguintes critérios:
          </Typography>
          <Typography
            component="p"
            variant="body1"
            color="primary.main"
            mb={2}
          >
            Saldo em reais deve ser zero; <br />
            Saldo em cripto deve ser zero;
          </Typography>
          <Typography component="p" variant="body1">
            Caso ambos saldos já estejam zerados, clique no botão
            abaixo parea solicitar o cancelamento da conta
            enviando um e-mail para nosso canal de suporte.
          </Typography>
        </Box>
        <NextLink
          href={'mailto:' + ExternalUrls.EMAIL_SUPPORT}
          passHref
        >
          <Button
            type="button"
            sx={{
              bgcolor: 'grey.200',
              width: '100%',
              color: 'primary.main',
              '&:hover': {
                bgcolor: 'grey.200',
                border:
                  '1px solid ' +
                  themes.bankai.palette.primary.main,
              },
            }}
          >
            Encerrar minha conta
          </Button>
        </NextLink>
      </Box>
    </Drawer>
  );
};
export default CloseAccount;
