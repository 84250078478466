const enum OnboardingStep {
	PROFILE = "profile_step",
	ADDRESS = "address_step",
	DOCUMENT = "document_step",
	PHONE = "phone_step",
	PIN = "pin_step",
	SELFIE = "selfie_step",
}

export default OnboardingStep;
