import React from 'react';
import { Container, ComingSoon } from './styles';

interface Props {
  value: boolean;
  error?: string;
  onChange?: (param: boolean) => any;
  errorMessage?: string;
  name: string;
  placeholder: string;
  disabled?: boolean;
  readOnly?: boolean;
  children?: React.ReactNode;
  subtitle?: string;
  soon?: boolean;
}

const FieldCheckbox: React.FC<Props> = React.forwardRef(
  (
    {
      value,
      error,
      onChange,
      errorMessage,
      name,
      placeholder,
      disabled,
      readOnly,
      children,
      subtitle,
      soon,
    },
    ref,
  ) => {
    return (
      <Container subtitle={subtitle ? true : false} soon={soon}>
        <input
          type="checkbox"
          id={name}
          name={name}
          onChange={(value: any) => {
            onChange ? onChange(value) : null;
          }}
          checked={value}
          disabled={readOnly || soon}
        />
        <label htmlFor={name}>
          <span />
          <div>
            <span>{placeholder}</span>
            <p>{subtitle}</p>
          </div>

          {soon && <ComingSoon>Em breve</ComingSoon>}
        </label>
        {children}
      </Container>
    );
  },
);

FieldCheckbox.displayName = 'FieldCheckbox';

export default FieldCheckbox;
