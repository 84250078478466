import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  steps: Array<string>;
}

const ContentTitle: React.FC<Props> = ({ title, steps }) => {
  return (
    <Box mb={5} display="flex" alignItems="center" gap="30px">
      <Typography variant="h3" component="h3">
        {title}
      </Typography>
      <List
        sx={{
          p: 0,
          gap: '10px',
          '.MuiListItem-root': {
            bgcolor: 'grey.200',
            borderRadius: '3px',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            px: 1,
            display: {
              xs: 'none',
              sm: 'flex',
            },
            '&:first-child': {
              display: 'flex',
            },
            '&:last-child': {
              bgcolor: 'primary.main',
              color: 'common.white',
              display: 'flex',
            },
          },
        }}
      >
        {steps.map((item, index) => {
          return (
            <ListItem dense key={index}>
              {item}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
export default ContentTitle;
