import styled, { css, keyframes } from 'styled-components';
import { theme } from 'styled-tools';
import { typography } from '../themes';

export interface ButtonStyleProps {
  variant?: 'primary' | 'secundary';
  disabled?: boolean;
  block?: boolean;
}

const type = {
  primary: css`
    background-color: ${theme('colors.primary.main')};
    box-shadow: 0 10px 25px ${theme('colors.primary.main')}13;
    color: ${theme('colors.white')};
    border: none;

    &:focus {
      background-color: ${theme('colors.primary.main')};
      box-shadow: 0 10px 25px ${theme('colors.primary.main')}33;
      text-decoration: underline;
    }

    &:hover,
    &:active {
      background-color: ${theme('colors.primary.dark')};
      box-shadow: 0 10px 25px ${theme('colors.primary.dark')}33;
    }

    & > div {
      background-color: ${theme('colors.primary.dark')};
    }
  `,
  secundary: css`
    background-color: ${theme('colors.secondary.4')};
    color: ${theme('colors.primary.main')};
    border: none;

    &:focus,
    &:active {
      background-color: ${theme('colors.secondary.4')};
      border: 1px solid ${theme('colors.primary.main')};
    }

    &:hover {
      background-color: ${theme('colors.primary.main')};
      color: ${theme('colors.white')};
    }

    & > div {
      background-color: ${theme('colors.secondary.3')};
    }
  `,
};

const progressAnimation = keyframes`
  0% {
    width: 0%;
  }
  20% {
    width: 80%;
  }
  40% {
    width: 85%;
  }
  50% {
    width: 90%;
  }
  100% {
    width: 95%;
  }
`;

const styles = css<ButtonStyleProps>`
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: center;
  overflow: hidden;
  padding: 14px 40px;
  position: relative;
  transition: all 0.25s ease;
  width: ${({ block }) => (block ? '100%' : 'fit-content')};

  ${typography.body2}

  ${({ variant }) => type[variant ?? 'primary']}

  &:disabled {
    background-color: ${theme('colors.secondary.4')};
    box-shadow: none;
    color: ${theme('colors.secondary.3')};
    cursor: not-allowed;
  }

  & > span {
    position: relative;
  }
`;

export const Btn = styled.button`
  ${styles}
`;

export const BtnLink = styled.a`
  ${styles}
`;

export const Progress = styled.div`
  animation: ${progressAnimation} 5s ease-in-out;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
`;
