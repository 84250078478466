import useSWR from 'swr';

const asyncFunc = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(''), 2000);
  });
};

const mockOrderFilter = async () => {
  await asyncFunc();

  return {
    tabs: [
      {
        tab: 'Todas',
        filter: 'all',
      },
      {
        tab: 'Abertas',
        filter: 'processing',
      },
      {
        tab: 'Executadas',
        filter: 'executed',
      },
      {
        tab: 'Canceladas',
        filter: 'cancelled',
      },
    ],
  };
};

const useOrderFilter = () => {
  const key = {
    endpoint: `/v1/orders/filter`,
  };

  const options = {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  };

  const { data, error } = useSWR(key, mockOrderFilter, options);

  return {
    orderFilter: data,
    loadings: !error && !data,
    error,
  };
};

export default useOrderFilter;
