export interface IFavorite {
  id: 0,
  user_id: 0,
  account: string,
  address: string,
  agency: string,
  cpf_cnpj: string,
  coin_type: string,
  institution: string,
  institution_name: string,
  name: string,
  operation: string,
  pix_key: string,
  pix_type: string,
  updated_by: 0,
  created_at: string | Date,
  updated_at: string | Date
}

export interface IFavorites {
  totalRecords: number,
  pageSize: number,
  startRecord: number,
  lastPage: number,
  currentPage: number,
  results: [IFavorite]
}

export const FavoritesEmpty: IFavorites = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [
    {
      id: 0,
      user_id: 0,
      account: "string",
      address: "string",
      agency: "string",
      cpf_cnpj: "string",
      coin_type: "BKAI",
      institution: "string",
      institution_name: "string",
      name: "string",
      operation: "TED",
      pix_key: "string",
      pix_type: "CPF",
      updated_by: 0,
      created_at: "2023-11-29T13:34:26.010Z",
      updated_at: "2023-11-29T13:34:26.010Z"
    }
  ]
}