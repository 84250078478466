import {
  HeaderNav,
  HeaderBalance,
  HeaderBalanceCrypto,
} from '../../components';

import Box from '@mui/material/Box';

interface Props {
  title?: string;
  toggleBalance?: boolean;
  removeBalance?: boolean;
  accountData?: boolean;
  crypto?: any;
}

const Header: React.FC<Props> = ({
  children,
  title,
  toggleBalance,
  removeBalance,
  accountData,
  crypto,
}) => {
  return (
    <Box
      component="header"
      width="100%"
      height={removeBalance ? '78px' : '126px'}
      paddingX={3}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      mb={accountData ? '99px' : removeBalance ? '26px' : '47px'}
      sx={{
        backgroundColor: 'primary.main',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
      }}
    >
      {children ?? (
        <>
          <HeaderNav title={title} />
          {crypto ? (
            <HeaderBalanceCrypto
              toggleBalance={toggleBalance}
              accountData={accountData}
              crypto={crypto}
            />
          ) : (
            !removeBalance && (
              <HeaderBalance
                toggleBalance={toggleBalance}
                accountData={accountData}
              />
            )
          )}
        </>
      )}
    </Box>
  );
};

export default Header;
