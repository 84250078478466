import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Icon, IconName, themes } from '@2ndmarket/components';
import { months } from '@2ndmarket/helpers';
import {
  useCryptoStatement,
  useCryptoWallet,
  useStatement,
} from '@2ndmarket/services';
import { IStatementRequest } from '@2ndmarket/types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { QRCodeSVG } from 'qrcode.react';

interface Props {
  crypto: string;
}

const CryptoDeposit: React.FC<Props> = ({ crypto }) => {
  const { data: wallet } = useCryptoWallet(crypto);

  if (typeof navigator !== 'undefined') {
    var sharePromise = navigator.share;
  }

  async function share() {
    try {
      await navigator.share({ text: wallet[0]?.address });
    } catch (e) {}
  }

  function copy() {
    navigator.clipboard.writeText(wallet[0]?.address);
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <QRCodeSVG
        value={wallet[0]?.address}
        bgColor="transparent"
        size={190}
      />
      <Typography
        variant="body1"
        component="p"
        sx={{ mt: 3, mb: 1 }}
      >
        {wallet[0]?.address}
      </Typography>
      <Button
        type="button"
        variant="link"
        onClick={copy}
        sx={{ mb: 4 }}
      >
        Copiar
      </Button>
      {
        //@ts-ignore
        sharePromise && (
          <Button
            onClick={share}
            type="button"
            color="secondary"
            variant="contained"
          >
            Compartilhar
          </Button>
        )
      }
    </Box>
  );
};

export default CryptoDeposit;
