import { useEffect, useState } from 'react';

import { themes } from '@2ndmarket/components';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface Props {
  loaderState: boolean;
}

const Squares: React.FC<Props> = ({ loaderState }) => {
  const [loaderShow, setLoaderShow] = useState(loaderState);

  useEffect(() => {
    setLoaderShow(loaderState);
  }, [loaderState]);

  const BoxLoader = styled(Box)({
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    position: 'relative',
    '@keyframes loader_one': {
      '0%': {
        top: 0,
        left: 0,
      },
      '25%': {
        top: 0,
        left: 'calc(50% - 75px)',
      },
      '50%': {
        top: 'calc(50% - 75px)',
        left: 'calc(50% - 75px)',
        height: '20px',
        width: '20px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        top: 'calc(50% - 75px)',
        left: 0,
      },
      '100%': {
        top: 0,
        left: 0,
      },
    },
    '@keyframes loader_two': {
      '0%': {
        left: 'calc(50% - 75px)',
        top: 'calc(50% - 75px)',
        height: '20px',
        width: '20px',
      },
      '25%': {
        left: 0,
        top: 'calc(50% - 75px)',
      },
      '50%': {
        left: 0,
        top: 0,
        height: '20px',
        width: '20px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        left: 'calc(50% - 75px)',
        top: 0,
      },
      '100%': {
        left: 'calc(50% - 75px)',
        top: 'calc(50% - 75px)',
        height: '20px',
        width: '20px',
      },
    },
    '@-webkit-keyframes loader_one': {
      '0%': {
        top: 0,
        left: 0,
      },
      '25%': {
        top: 0,
        left: 'calc(50% - 75px)',
      },
      '50%': {
        top: 'calc(50% - 75px)',
        left: 'calc(50% - 75px)',
        height: '20px',
        width: '20px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        top: 'calc(50% - 75px)',
        left: 0,
      },
      '100%': {
        top: 0,
        left: 0,
      },
    },
    '@-webkit-keyframes loader_two': {
      '0%': {
        left: 'calc(50% - 75px)',
        top: 'calc(50% - 75px)',
        height: '20px',
        width: '20px',
      },
      '25%': {
        left: 0,
        top: 'calc(50% - 75px)',
      },
      '50%': {
        left: 0,
        top: 0,
        height: '20px',
        width: '20px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        left: 'calc(50% - 75px)',
        top: 0,
      },
      '100%': {
        left: 'calc(50% - 75px)',
        top: 'calc(50% - 75px)',
        height: '20px',
        width: '20px',
      },
    },
    '.square': {
      margin: 52,
      borderRadius: '0%',
      position: 'absolute',
      boxShadow: '0 0 12px rgba(0,0,0,0.1)',
      '&.first': {
        width: '20px',
        height: '20px',
        animation: 'loader_one 2s linear infinite',
        borderWidth: '4px',
        borderStyle: 'solid',
        WebkitAnimation: 'loader_one 2s linear infinite',
      },
      '&.second': {
        width: '20px',
        height: '20px',
        top: 'calc(100% - 125px)',
        left: 'calc(100% - 125px)',
        animation: 'loader_two 2s linear infinite',
        borderWidth: '4px',
        borderStyle: 'solid',
        WebkitAnimation: 'loader_two 2s linear infinite',
      },
    },
  });

  return (
    <>
      {loaderShow && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BoxLoader sx={{ bgcolor: 'grey.400' }}>
            <Box
              component="div"
              className="square first"
              sx={{ borderColor: 'primary.main' }}
            />
            <Box
              component="div"
              className="square second"
              sx={{ borderColor: 'primary.light' }}
            />
          </BoxLoader>
        </Box>
      )}
    </>
  );
};

export default Squares;
