import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HttpError,
  IntentStatus,
  Masks,
  Status,
  formatBRL,
  parseBRL,
  yupPt,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  ContentTitle,
  TransactionModal,
  TransferAwait,
} from '../../../../components';
import { MaskedInput } from '@2ndmarket/components';
import { sendBillRegister } from '@2ndmarket/services';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  setBillCode: React.Dispatch<React.SetStateAction<string>>;
  setBillId: React.Dispatch<React.SetStateAction<number>>;
}

const TypeableLine: React.FC<Props> = ({
  setStep,
  setBillCode,
  setBillId,
}) => {
  const router = useRouter();
  const [transferDataPromise, setTransferDataPromise] = useState(
    {
      id: null,
      type: '',
      register: false,
    },
  );
  //constantes da intent
  const [transactionId, setTransactionId] = useState('');
  //constantes da modal
  const [modal, setModal] = useState(false);
  const [intentStatus, setIntentStatus] = useState(
    IntentStatus.NONE,
  );
  const modalTitle = 'Boleto bancário';
  const [modalText, setModalText] = useState<string | null>('');

  const schema = yupPt
    .object()
    .shape({ typeable_line: yupPt.string().required() });
  const defaultValues = { typeable_line: '' };

  const {
    handleSubmit,
    register,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: { typeable_line: string }) {
    let pureValue = data.typeable_line.replace(/[^\d]/g, '');

    if (pureValue.length < 46 || pureValue.length > 48) {
      setError('typeable_line', {
        type: 'custom',
        message: 'Linha digitável inválida',
      });

      return;
    }
    setBillCode(data.typeable_line);
    setIntentStatus(IntentStatus.NONE);
    setModal(true);
    sendBillRegister(pureValue, '')
      .then(() => {
        const responseBillRegister = localStorage.getItem(
          '@locationHeader',
        );

        const response = responseBillRegister
          ?.split('?')[1]
          .split('&')
          .reduce((accumulator: any, item: any) => {
            const [key, value] = item.split('=');
            accumulator[key] = value;
            return accumulator;
          }, {});

        setTransferDataPromise({
          id: response?.intent_id,
          type: response?.type,
          register: response?.is_register,
        });
      })
      .catch(error => {
        if (error.status == 422) {
          for (let fieldError of error.errors) {
            setError(fieldError.field, {
              type: 'custom',
              message: fieldError.message,
            });
          }
        } else {
          setIntentStatus(IntentStatus.FAILURE);
          setModalText(error.error);
        }
      })
      .catch((error: HttpError) => {
        setValue('typeable_line', '');
        setModalText(error.error);
        setIntentStatus(IntentStatus.FAILURE);
      });
  }

  const steps = ['Início', 'Linha digitável'];

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <ContentTitle title="Boleto" steps={steps} />
      <TextField
        {...register('typeable_line', { required: true })}
        sx={{ mb: 4 }}
        fullWidth
        label="Linha digitável"
        error={Boolean(errors.typeable_line)}
        helperText={
          errors.typeable_line && errors.typeable_line.message
        }
        InputProps={{
          inputComponent: MaskedInput,
          inputProps: {
            unmask: true,
            mask: Masks.TYPEABLE_LINE,
            inputMode: 'numeric',
          },
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => router.back()}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
      {transferDataPromise?.id && (
        <TransferAwait
          data={transferDataPromise}
          setIntentStatus={setIntentStatus}
          setIntentDescription={setModalText}
          redirect="step2"
          stepRedirect={setStep}
          setTransactionId={setTransactionId}
          setBillId={setBillId}
        />
      )}
      {modal && (
        <TransactionModal
          open={modal}
          title={modalTitle}
          text={modalText}
          status={intentStatus}
          onClose={() => setModal(false)}
        />
      )}
    </Box>
  );
};
export default TypeableLine;
