import React from 'react';
import TextField from '../TextField';
import { TextFieldProps } from '../TextField/TextField';
import {
  formatBRL,
  parseBRL,
} from '@2ndmarket/helpers/formatters/fiatFormat';

const TextFieldFiat: React.FC<TextFieldProps> = React.forwardRef(
  (
    {
      name,
      value,
      placeholder,
      error,
      disabled,
      mb,
      mt,
      readOnly = false,
      inputMode,
      onChange = () => {},
      invalidChars,
    },
    ref,
  ) => {
    const onTextChange = (newText: string) => {
      const newFiatValue = parseBRL(newText);
      onChange(newFiatValue.toString());
    };

    return (
      <TextField
        name={name}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        mb={mb}
        mt={mt}
        invalidChars={invalidChars}
        readOnly={readOnly}
        value={formatBRL(value)}
        onChange={onTextChange}
        inputMode={inputMode}
        autocomplete="off"
      />
    );
  },
);

TextFieldFiat.displayName = 'TextFieldFiat';

export default TextFieldFiat;
