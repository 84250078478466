import { Copyright } from '@2ndmarket/components';
import { ProductCnpjs } from '@2ndmarket/helpers';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const BaseGrid: React.FC = ({ children }) => {
  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        height: '100%',
        paddingX: {
          xs: 0,
          sm: 3,
          md: 3,
          lg: 3,
        },
      }}
    >
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ minHeight: '100svh' }}
      >
        <Box>{children}</Box>
        <Box component="footer" mt={8}>
          <Copyright cnpj={ProductCnpjs.BANKAI} />
        </Box>
      </Grid>
    </Container>
  );
};

export default BaseGrid;
