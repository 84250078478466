import { useEffect } from 'react';

type GoogleTagManagerProps = {
  userOrigin: () => any;
  theme?: any;
};

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({
  userOrigin,
  theme,
}) => {
  const { data: me } = userOrigin();

  useEffect(() => {
    if (typeof window == 'undefined' || !me) {
      return;
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      user_id:
        theme?.name?.toString() === 'backoffice'
          ? 'b' + me?.id
          : me?.id,
    });
  }, [me, theme]);

  return <></>;
};
export default GoogleTagManager;
