import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants, TypesReceipt } from '@2ndmarket/helpers';
import {
  IReceipt,
  IServiceResponse,
  ReceiptEmpty,
} from '@2ndmarket/types';

const useCryptoReceipt = (
  operation: string,
  uuid: string,
  coin_type: TypesReceipt | string = TypesReceipt.BTC,
  user_id: number | AppConstants.ME = AppConstants.ME,
): IServiceResponse<IReceipt> => {
  const coin = coin_type.toUpperCase();
  if(operation) {
    operation = operation.toUpperCase();
  }

  const key = {
    endpoint: `/v4/crypto/users/${user_id}/coin/${coin}/receipt/${operation}/${uuid}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IReceipt, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? ReceiptEmpty,
    loading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export default useCryptoReceipt;
