import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const sendCryptoTrade = function (
  wallet_id: number,
  coin_type: string,
  trade_type : string,
  crypto_value : number,
  pin: string,
  user_id: number | AppConstants.ME = AppConstants.ME
) {
  coin_type = coin_type.toUpperCase();
  trade_type = trade_type.toUpperCase();
  return authenticatedFetcher({
    endpoint: `/v4/crypto/users/${user_id}/coin/${coin_type}/wallet/${wallet_id}/trade/${trade_type.toUpperCase()}`,
    headers: {
      'Content-Type': 'application/json',
      'user-pin': pin,
    },
    method: 'POST',
    body: JSON.stringify({
      crypto_value: crypto_value,
    }),
  });
};

export default sendCryptoTrade;
