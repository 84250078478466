import React, {
  useState,
  useEffect,
  FocusEventHandler,
  KeyboardEventHandler,
  HTMLInputTypeAttribute,
} from 'react';
import InputMask from 'react-input-mask';
import { Icon, themes } from '@2ndmarket/components';

import {
  ErrorMessage,
  Input,
  Parent,
  Placeholder,
} from './styles';

export interface TextFieldProps {
  id?: string;
  name: string;
  placeholder: string;
  value?: any;
  error?: string;
  disabled?: boolean;
  mask?: string | Array<string | RegExp>;
  maskPlaceholder?: string | null;
  mb?: number;
  mt?: number;
  type?: HTMLInputTypeAttribute;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  invalidChars?: string;
  maxLength?: number;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  className?: string;
  autocomplete?: string;
  ref?: any;
  dataTestid?: string;
}

const TextField: React.FC<TextFieldProps> = React.forwardRef(
  (
    {
      id,
      name,
      value,
      placeholder,
      error,
      disabled,
      mask,
      maskPlaceholder,
      mb,
      mt,
      type,
      readOnly = false,
      onChange,
      onKeyPress,
      onFocus,
      invalidChars,
      maxLength,
      inputMode,
      className,
      autocomplete,
      dataTestid = 'textfield-input',
    },
    ref,
  ) => {
    const [showPin, setShowPin] = useState(true);

    useEffect(() => {
      if (invalidChars) {
        const inputBox = document.getElementById(name);

        inputBox!.addEventListener('keydown', function (e) {
          if (invalidChars.includes(e.key)) {
            e.preventDefault();
          }
        });
      }

      if (
        className === 'password' ||
        className === 'password_confirm'
      ) {
        const inputPassword = document.getElementById(name);
        if (showPin) {
          inputPassword?.setAttribute('type', 'password');
        } else {
          inputPassword?.setAttribute('type', 'number');
        }
      }
    }, [invalidChars, name, showPin, className]);

    const handleIcon = () => {
      if (
        className === 'password' ||
        className === 'password_confirm'
      ) {
        return (
          <button
            type="button"
            tabIndex={-1}
            onClick={() => setShowPin(!showPin)}
          >
            {showPin ? (
              <Icon
                size={20}
                name="hide"
                color={themes.default2nd.colors.secondary[3]}
              />
            ) : (
              <Icon
                size={20}
                name="view"
                color={themes.default2nd.colors.secondary[3]}
              />
            )}
          </button>
        );
      }
    };

    return (
      <Parent mt={mt} mb={mb} error={!!error} className={name}>
        {mask ? (
          <InputMask
            data-testid={dataTestid}
            id={name}
            name={name}
            mask={mask}
            type={type}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            className={className}
            inputMode={inputMode}
            autoComplete={autocomplete}
            maskPlaceholder={maskPlaceholder}
            onChange={e => onChange && onChange(e.target.value)}
          />
        ) : (
          <Input
            id={id}
            data-testid={dataTestid}
            name={name}
            value={value}
            error={!!error}
            onFocus={onFocus}
            readOnly={readOnly}
            disabled={disabled}
            maxLength={maxLength}
            inputMode={inputMode}
            className={className}
            onKeyPress={onKeyPress}
            filled={value?.length > 0}
            type={type ? type : 'text'}
            autoComplete={autocomplete}
            onChange={e => onChange && onChange(e.target.value)}
          />
        )}

        <Placeholder
          htmlFor={name}
          error={!!error}
          className={className}
          filled={value != undefined && value !== ''}
        >
          {placeholder}
        </Placeholder>

        {handleIcon()}

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Parent>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
