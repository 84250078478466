import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const sendBillRegister = function (
  typeable_line: string,
  barcode: string,
  user_id: number | AppConstants.ME = AppConstants.ME
) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/users/${user_id}/bill-slip`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      typeable_line: typeable_line,
      barcode: barcode,
    }),
  });
};

export default sendBillRegister;
