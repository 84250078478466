import { DialogProps } from '@2ndmarket/types';
import { themes } from '@2ndmarket/components';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const ModalDialog: React.FC<DialogProps> = ({
  errorMessage,
  errorTitle,
  children,
  maxWidth,
  onClose,
  error,
  open,
}) => {
  const defaultErrorTitle = 'Falha de login';

  if (open) {
    return (
      <Dialog open={true} maxWidth={maxWidth} fullWidth>
        {error || errorMessage ? (
          <Grid paddingX={2} paddingY={3}>
            <DialogTitle
              variant="h2"
              component="h2"
              color="text.primary"
            >
              {errorTitle ? errorTitle : defaultErrorTitle}
            </DialogTitle>
            {(error || errorMessage) && (
              <DialogContent>
                <Typography
                  component="p"
                  variant="body1"
                  color="text.secondary"
                >
                  {errorMessage}
                </Typography>
              </DialogContent>
            )}
            <DialogActions sx={{ paddingX: 3, mt: 15 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onClose()}
                sx={{
                  boxShadow: 'none',
                }}
              >
                Entendi
              </Button>
            </DialogActions>
          </Grid>
        ) : (
          <Grid paddingX={2} paddingY={3}>
            {children}
          </Grid>
        )}
      </Dialog>
    );
  }

  return null;
};

export default ModalDialog;
