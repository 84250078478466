import useSWR from 'swr';
import { AppConstants } from '@2ndmarket/helpers';
import { 
  IServiceResponse,
  IStatementCrypto,
  IStatementRequest,
  StatementCryptoEmpty 
} from '@2ndmarket/types';
import authenticatedFetcher from '../authenticatedFetcher';

const useCryptoStatement = (
  coin_type: string,
  wallet_id: number,
  params?: IStatementRequest,
  user_id: number | AppConstants.ME = AppConstants.ME,
):IServiceResponse<IStatementCrypto> => {
  const urlParams = new URLSearchParams();
  for (const key in params) {
    const value = params[key as keyof IStatementRequest];
    if (value !== '' && value !== undefined) {
      urlParams.append(key, value.toString());
    }
  }

  const coin = coin_type.toUpperCase();
  let endpoint = `/v4/crypto/users/${user_id}/coin/${coin}/wallet/${wallet_id}/statement?${urlParams.toString()}`;

  const key = {
    endpoint: endpoint,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IStatementCrypto, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? StatementCryptoEmpty,
    loading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export default useCryptoStatement;
