import { useRouter } from 'next/router';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

const Transfer: React.FC = () => {
  const router = useRouter();
  return (
    <ButtonGroup
      orientation="vertical"
      aria-label="vertical outlined button group"
      sx={{
        gap: '10px',
        width: '100%',
        '.MuiButtonBase-root': {
          height: 'auto',
          bgcolor: 'common.white',
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          borderRadius: '3px',
          '&:hover': {
            transition:
              'box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow:
              '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
          },
          '.leftContent': {
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            '.text': {
              textAlign: 'left',
              color: 'text.primary',
              '& p': {
                fontWeight: '500',
                lineHeight: '1',
              },
              '& span': {
                color: 'text.secondary',
                fontSize: '14px',
              },
            },
          },
        },
      }}
    >
      <Button
        variant="text"
        onClick={() => router.push('transferir/ted')}
      >
        <Box className="leftContent">
          <Icon name="bank" size={20} />
          <Box className="text">
            <Typography component="p" variant="body1">
              TED
            </Typography>
            <Typography component="span" variant="body1">
              Informe agência e conta
            </Typography>
          </Box>
        </Box>
        <Icon
          name="arrow"
          rotate="-90deg"
          size={16}
          color={themes.bankai.palette.text.disabled}
        />
      </Button>
      <Button
        variant="text"
        onClick={() => router.push('transferir/p2p')}
      >
        <Box className="leftContent">
          <Icon name="bkai-coin" size={20} />
          <Box className="text">
            <Typography component="p" variant="body1">
              P2P
            </Typography>
            <Typography component="span" variant="body1">
              Entre contas Bank.ai
            </Typography>
          </Box>
        </Box>
        <Icon
          name="arrow"
          rotate="-90deg"
          size={16}
          color={themes.bankai.palette.text.disabled}
        />
      </Button>
    </ButtonGroup>
  );
};

export default Transfer;
