import { ReviewStep } from "../constants/ReviewStep";

export const ReviewSteps = [
	{
		value: ReviewStep.NONE,
		label: "Pendente",
	},
	{
		value: ReviewStep.PENDING,
		label: "Pendente",
	},
	{
		value: ReviewStep.PROCESSING,
		label: "Em análise",
	},
	{
		value: ReviewStep.RECEIVED,
		label: "Em análise",
	},
	{
		value: ReviewStep.ANALYZING,
		label: "Em análise",
	},
	{
		value: ReviewStep.REFUSED,
		label: "Recusado",
	},
	{
		value: ReviewStep.APPROVED,
		label: "Aprovado",
	},
];
