import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Button = styled.button`
  background-color: transparent !important;
  text-decoration: underline;
  color: ${theme('colors.primary.main')};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: ${theme('colors.primary.dark')};
  }
`;

export const Span = styled.span`
  background-color: transparent;
  color: ${theme('colors.secondary.5')};
  font-size: 14px;
  font-weight: 500;
`;
