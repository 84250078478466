import NextLink from 'next/link';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Icon,
  ModalDialog,
  Squares,
  themes,
} from '@2ndmarket/components';
import {
  ExternalUrls,
  HttpError,
  ICryptoAsset,
  yupPt,
} from '@2ndmarket/helpers';
import { createCryptoWallet } from '@2ndmarket/services';
import { DrawerHeader } from './styles';
import CryptoSelect from './CryptoSelect';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  crypto: ICryptoAsset;
}

interface IForm {
  selected_crypto: string;
  wallet_name: string;
  policy: boolean;
  terms: boolean;
}

const OpenWallet: React.FC<Props> = ({
  open,
  setOpen,
  crypto,
}) => {
  const router = useRouter();
  const [cryptoSelect, setCryptoSelect] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState(crypto);
  const [loaderState, setLoaderState] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const schema = yupPt.object().shape({
    wallet_name: yupPt.string().required(),
    policy: yupPt
      .boolean()
      .oneOf([true], 'Aceite a política de privacidade'),
    terms: yupPt
      .boolean()
      .oneOf([true], 'Aceite os termos de uso'),
  });

  const defaultValues = {
    selected_crypto: crypto.name,
    wallet_name: '',
    policy: false,
    terms: false,
  };

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const selectedCryptoWatcher = useWatch({
    control,
    name: 'selected_crypto',
  });

  useEffect(() => {
    setValue('selected_crypto', selectedCrypto.name);
  }, [selectedCrypto, setSelectedCrypto, setValue]);

  const onSubmit = (data: IForm) => {
    setLoaderState(true);
    createCryptoWallet({
      coin: selectedCrypto.abbreviation,
      name: data.wallet_name,
    })
      .then(() => {
        setLoaderState(true);
        setTimeout(() => {
          router.push(
            `/carteira-cripto/${selectedCrypto.abbreviation}`,
          );
        }, 2000);
      })
      .catch((error: HttpError) => {
        setShowErrorModal(true);
        setErrorModalMessage(error.error);
      })
      .finally(() => {
        setLoaderState(false);
      });
  };

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={() => setOpen(false)}
        sx={{
          '.MuiPaper-root': {
            backgroundColor: 'grey.900',
          },
        }}
      >
        <DrawerHeader
          sx={{
            pl: 3,
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 415,
            },
          }}
        >
          <Stack
            gap={6}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="arrow-alternative"
                rotate="180deg"
                color={themes.bankai.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Abertura de carteira
            </Typography>
          </Stack>
        </DrawerHeader>

        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100%"
          component="form"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          pb={4}
          sx={{
            position: 'relative',
            px: 4,
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 415,
            },
          }}
        >
          {loaderState ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: 4,
              }}
            >
              <Typography
                component="p"
                variant="body1"
                sx={{ mb: 2, textAlign: 'center' }}
              >
                Relizando abertura da carteira
              </Typography>
              <Squares loaderState />
            </Box>
          ) : (
            <>
              <Box>
                <Typography component="p" variant="body1" mb={2}>
                  Informe os dados da sua carteira. Você não
                  poderá alterar esses dados depois.
                </Typography>
                <TextField
                  label="Selecione a moeda"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  {...register('selected_crypto')}
                  onClick={() => setCryptoSelect(true)}
                  error={Boolean(errors.selected_crypto)}
                  helperText={
                    errors.selected_crypto &&
                    errors.selected_crypto.message
                  }
                  InputProps={{
                    inputProps: { readOnly: true },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          name="arrow"
                          size={12}
                          rotate="-90deg"
                        />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: selectedCryptoWatcher ? true : false,
                  }}
                  sx={{
                    mb: '12px',
                    input: { cursor: 'pointer' },
                  }}
                />
                <TextField
                  {...register('wallet_name', {
                    required: true,
                  })}
                  fullWidth
                  label="Nome da carteira"
                  error={Boolean(errors.wallet_name)}
                  helperText={
                    errors.wallet_name &&
                    errors.wallet_name.message
                  }
                />
                <FormGroup
                  sx={{
                    '.MuiFormControlLabel-root': {
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      bgcolor: 'grey.400',
                      px: 2,
                      py: 1,
                      ml: 0,
                      mr: 0,
                      mt: '10px',
                    },
                    '.MuiFormHelperText-root': {
                      mt: 0,
                    },
                  }}
                >
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="policy"
                          {...register('policy', {
                            required: true,
                          })}
                          checked={watch('policy')}
                          sx={{
                            color: 'grey.200',
                          }}
                          {...(errors.policy && {
                            sx: {
                              color: 'error.main',
                            },
                          })}
                        />
                      }
                      id="policy"
                      name="policy"
                      label={
                        <Typography
                          component="p"
                          variant="body1"
                          color="text.secondary"
                        >
                          Aceito a{' '}
                          <NextLink
                            href={ExternalUrls.PRIVACY_POLICY}
                            passHref
                          >
                            <Link
                              target="_blank"
                              variant="body1"
                            >
                              Política de privacidade
                            </Link>
                          </NextLink>
                        </Typography>
                      }
                    />
                    <FormHelperText error>
                      {errors.policy
                        ? errors.policy.message
                        : ''}
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="terms"
                          {...register('terms', {
                            required: true,
                          })}
                          checked={watch('terms')}
                          sx={{
                            color: 'grey.200',
                          }}
                          {...(errors.terms && {
                            sx: {
                              color: 'error.main',
                            },
                          })}
                        />
                      }
                      id="terms"
                      name="terms"
                      label={
                        <Typography
                          component="p"
                          variant="body1"
                          color="text.secondary"
                        >
                          Aceito os{' '}
                          <NextLink
                            href={
                              ExternalUrls.TERMS_AND_CONDITIONS
                            }
                            passHref
                          >
                            <Link
                              target="_blank"
                              variant="body1"
                            >
                              Termos de uso
                            </Link>
                          </NextLink>
                        </Typography>
                      }
                    />
                    <FormHelperText error>
                      {errors.terms ? errors.terms.message : ''}
                    </FormHelperText>
                  </FormControl>
                </FormGroup>
              </Box>
              <Button
                type="submit"
                sx={{
                  bgcolor: 'grey.200',
                  width: '100%',
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: 'grey.200',
                    border:
                      '1px solid ' +
                      themes.bankai.palette.primary.main,
                  },
                }}
              >
                Abrir carteira
              </Button>
            </>
          )}
        </Box>
      </Drawer>
      <CryptoSelect
        open={cryptoSelect}
        setOpen={setCryptoSelect}
        selectedCrypto={selectedCrypto}
        setSelectedCrypto={setSelectedCrypto}
      />
      {showErrorModal && (
        <ModalDialog
          error
          errorTitle="Problema ao abrir carteira"
          maxWidth="sm"
          open={showErrorModal}
          errorMessage={errorModalMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </>
  );
};
export default OpenWallet;
