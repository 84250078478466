import React from 'react';
import { Container } from './styles';

interface CheckboxProps {
  id: string;
  onChange: any;
  checked: boolean;
  label?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = React.forwardRef(
  ({ checked, id, label, onChange, disabled }, ref) => {
    return (
      <Container>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <label htmlFor={id}>
          <span /> {label}
        </label>
      </Container>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
