import { useEffect, useState } from 'react';

import { Icon } from '@2ndmarket/components';
import { PaginationAmount } from '@2ndmarket/helpers';
import { IBanksResult } from '@2ndmarket/types';
import { useBanks } from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Loader from '@2ndmarket/components/src/Mui/Loader/Loader';

interface Props {
  bankOpen: boolean;
  setBankOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBank: IBanksResult;
  setSelectedBank: React.Dispatch<
    React.SetStateAction<IBanksResult>
  >;
}

const BankDrawer: React.FC<Props> = ({
  bankOpen,
  setBankOpen,
  selectedBank,
  setSelectedBank,
}) => {
  const startPage = 1;
  const banksOrder = 'asc';
  const initialPageSize = 20;

  const [page, setPage] = useState(startPage);
  const [banksFilter, setBanksFilter] = useState('');
  const [pageSize, setPageSize] = useState(initialPageSize);

  const { data: banks, loading } = useBanks(
    banksFilter,
    banksOrder,
    page,
    pageSize,
  );

  useEffect(() => {
    if (bankOpen) {
      setBanksFilter('');
    }
  }, [bankOpen]);

  let timer: any = null;
  function filter(event: React.ChangeEvent<HTMLInputElement>) {
    const keyword = event.target.value;
    clearTimeout(timer);

    if (keyword.length >= 3) {
      setPage(startPage);
      timer = setTimeout(() => {
        setBanksFilter(keyword.toLowerCase());
      }, 500);
    } else {
      setBanksFilter('');
    }
  }

  function changePage(selectedPage: number) {
    if (selectedPage < startPage) {
      return;
    }
    if (selectedPage > banks.lastPage) {
      return;
    }
    setPage(selectedPage);
  }

  function selectBank(item: IBanksResult) {
    setSelectedBank(item);
    setBankOpen(false);
  }

  return (
    <Drawer
      open={bankOpen}
      anchor="right"
      role="presentation"
      onClose={() => setBankOpen(false)}
    >
      <Box p={4}>
        <Stack
          gap={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          pr={6}
        >
          <IconButton
            onClick={() => setBankOpen(false)}
            sx={{ '& i': { color: 'grey.300' } }}
          >
            <Icon size={15} name="close" />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
          >
            Selecione a instituição
          </Typography>
        </Stack>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="Busca"
          name="bank-filter"
          sx={{ marginTop: 3 }}
          onChange={filter}
        />
        {loading ? (
          <Box
            sx={{
              width: '360px',
              height: 'calc(100svh - 260px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 'calc(100vw - 64px)',
            }}
          >
            <Loader loaderState={true} />
          </Box>
        ) : (
          <List
            sx={{
              width: '360px',
              maxHeight: 'calc(100svh - 260px)',
              overflowY: 'auto',
              flexWrap: 'wrap',
              maxWidth: 'calc(100vw - 64px)',
            }}
          >
            {banks.totalRecords == 0 ? (
              <ListItem
                sx={{
                  padding: 1,
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Nenhuma instituição encontrada
              </ListItem>
            ) : (
              banks.results.map((item: IBanksResult) => (
                <ListItem
                  key={item.id}
                  sx={{
                    padding: 0,
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => selectBank(item)}
                    dense
                    sx={{ paddingX: 1 }}
                  >
                    <ListItemIcon sx={{ minWidth: 'auto' }}>
                      <Checkbox
                        edge="start"
                        checked={item.id == selectedBank.id}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.code + ' - ' + item.name}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        )}
      </Box>
      <Box
        p={4}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: 1,
          backgroundColor: 'background.neutral',
        }}
      >
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={() => changePage(page - 1)}
          disabled={banks.currentPage == startPage}
        >
          Anterior
        </Button>
        <TextField
          select
          fullWidth
          margin="dense"
          variant="outlined"
          defaultValue={initialPageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          sx={{
            m: 0,
            '.MuiInputBase-root': {
              height: '50px',
            },
          }}
        >
          {PaginationAmount.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          disabled={banks.lastPage == page}
          onClick={() => changePage(page + 1)}
        >
          Próximo
        </Button>
      </Box>
    </Drawer>
  );
};
export default BankDrawer;
