import { createTheme } from '@mui/material/styles';

import componentsOverrides from './overrides';

import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import customShadows from './customShadows';

const authTheme = {
  name: 'bankai',
  colors: {
    products: {
      braex: '#7E3FF2',
      bankai: '#E71346',
      custon: '#FC9C44',
      tokenaim: '#46DCAF',
      dashboard: '#313EE2',
      backoffice: '#313EE2',
      authentication: '#313EE2',
    },
  },
};

const themeOptions = {
  name: authTheme.name,
  colors: authTheme.colors,
  palette: palette,
  typography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shadows: shadows(),
  customShadows: customShadows(),
};

const bankai = createTheme(themeOptions as object);
bankai.components = componentsOverrides(bankai);

export default bankai;
