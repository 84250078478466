export interface IWalletCrypto {
  id: number;
  name: string;
  address: string;
  wallet_id: string;
  status: string;
  user_id: number;
}

export const WalletCryptoEmpty: IWalletCrypto[] = [
  {
    id: 0,
    name: '',
    address: '',
    wallet_id: '',
    status: '',
    user_id: 0,
  },
];

export interface WalletCryptoProps {
  coin: string;
  user_id: number;
  wallet_id?: number;
}
