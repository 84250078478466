import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

const useBillCashIn = (id: number) => {
  const key = {
    endpoint: `/v4/fiat/users/me/slip-cashin/${id}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useBillCashIn;
