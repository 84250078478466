export const cryptoList = [
  {
    code: "ETH",
    name: "Ethereum",
    icon: "ethereum",
  },
  {
    code: "BCH",
    name: "Bitcoin Cash",
    icon: "bitcoin-cash",
  },
  {
    code: "USDT",
    name: "Tether",
    icon: "tether",
  },
  {
    code: "ADA",
    name: "Cardano",
    icon: "cardano",
  },
  {
    code: "RPP",
    name: "Ripple",
    icon: "bkai-coin",
  },
  {
    code: "LTC",
    name: "Litecoin",
    icon: "bkai-coin",
  },
];
