import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  display: inline-block;
  text-align: center;

  label {
    align-items: center;
    color: #8c96a0;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    position: relative;
  }

  input {
    height: 0;
    opacity: 0;
    width: 0;
    display: flex;
  }

  label > span {
    border: 1px solid ${theme('colors.secondary.4')};
    border-radius: 3px;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 15px;
    transition: all 0.3s;
    width: 18px;
  }

  input:checked + label > span {
    animation: bounce 250ms;
    border: 9px solid ${theme('colors.primary.main')};
  }

  input:checked + label > span::before {
    animation: checked-box 125ms 250ms forwards;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    content: '';
    left: 3px;
    top: 9px;
    position: absolute;
    transform-origin: 0% 100%;
    transform: rotate(45deg);
  }

  @keyframes checked-box {
    0% {
      border-color: #fbfbff;
      height: 0;
      transform: translate(0, 0) rotate(45deg);
      width: 0;
    }
    33% {
      border-color: #fbfbff;
      height: 0;
      transform: translate(0, 0) rotate(45deg);
      width: 6px;
    }
    100% {
      border-color: #fbfbff;
      height: 11px;
      transform: translate(0, -11px) rotate(45deg);
      width: 6px;
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    33% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
`;
