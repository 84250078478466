import { useState } from 'react';
import { Icon } from '@2ndmarket/components';
import { formatCpfCnpj, getInitials } from '@2ndmarket/helpers';
import { useFiatFavorites } from '@2ndmarket/services';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Loader from '@2ndmarket/components/src/Mui/Loader/Loader';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  favoriteOpen: boolean;
  setFavoriteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFavorite: React.Dispatch<React.SetStateAction<any>>;
}

const FavoriteDrawer: React.FC<Props> = ({
  favoriteOpen,
  setFavoriteOpen,
  setSelectedFavorite,
}) => {
  const { data: favorites, loading: favoritesLoading } =
    useFiatFavorites('P2P', '0');
  const [favoritesFiltered, setFavoritesFiltered] = useState(
    favoritesLoading ? [] : favorites,
  );

  function selectFavorite(item: any) {
    setSelectedFavorite(item);
    setFavoriteOpen(false);
  }

  function filter(event: React.ChangeEvent<HTMLInputElement>) {
    const keyword = event.target.value;

    if (keyword != '') {
      const results = favorites.filter(item => {
        return item.name
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setFavoritesFiltered(results);
    } else {
      setFavoritesFiltered(favorites);
    }
  }

  return (
    <Drawer
      open={favoriteOpen}
      anchor="right"
      role="presentation"
      onClose={() => setFavoriteOpen(false)}
    >
      <Box p={4}>
        <Stack
          gap={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          pr={6}
        >
          <IconButton
            onClick={() => setFavoriteOpen(false)}
            sx={{ '& i': { color: 'grey.300' } }}
          >
            <Icon size={15} name="close" />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
          >
            Favoritos
          </Typography>
        </Stack>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="Busca"
          name="bank-filter"
          sx={{ marginTop: 3 }}
          onChange={filter}
        />
        {favoritesLoading ? (
          <Box
            sx={{
              width: '360px',
              height: 'calc(100svh - 260px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 'calc(100vw - 64px)',
            }}
          >
            <Loader loaderState={true} />
          </Box>
        ) : (
          <List
            sx={{
              width: '360px',
              maxHeight: 'calc(100svh - 260px)',
              overflowY: 'auto',
              flexWrap: 'wrap',
              maxWidth: 'calc(100vw - 64px)',
            }}
          >
            {favoritesFiltered.length == 0 ? (
              <ListItem
                sx={{
                  padding: 1,
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Nenhum favorito encontrado
              </ListItem>
            ) : (
              favoritesFiltered.map((item: any) => (
                <ListItem
                  key={item.id}
                  sx={{
                    padding: 0,
                  }}
                >
                  <ListItemButton
                    sx={{
                      gap: '10px',
                      px: 1,
                      py: 1,
                      '&:hover': { bgcolor: 'grey.200' },
                    }}
                    onClick={() => selectFavorite(item)}
                    dense
                  >
                    <Avatar
                      sx={{
                        width: 56,
                        height: 56,
                        cursor: 'pointer',
                        marginLeft: '0',
                        bgcolor: 'grey.400',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: 'primary.main' }}
                      >
                        {getInitials(item.name)}
                      </Typography>
                    </Avatar>
                    <ListItemText>
                      <Typography
                        component="p"
                        variant="subtitle1"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="p"
                        variant="body2"
                        color="grey.300"
                      >
                        CPF {formatCpfCnpj(item.cpf_cnpj)}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        )}
      </Box>
    </Drawer>
  );
};
export default FavoriteDrawer;
