export const formatCpfCnpj = (value: string) => {
  if (value) {
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    }
  }

  return value;
};

export const formatTrace = (value: string) => {
  if (value) {
    const slicedValue = value.slice(0, -1);
    const lastValue = value.substring(value.length - 1);
    value = slicedValue + '-' + lastValue;
  }
  return value;
};
