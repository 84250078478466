import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@2ndmarket/components';
import {
  OperationType,
  dayjsUtc,
  formatBRL,
  parseBRL,
  yupPt,
} from '@2ndmarket/helpers';
import { useBusinessHours } from '@2ndmarket/services';
import { IBusinessHours } from '@2ndmarket/types';
import { ContentTitle } from '../../../../components';
import { IReviewData } from './Types';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  setTransferAmount: React.Dispatch<
    React.SetStateAction<number>
  >;
  setReviewData: React.Dispatch<
    React.SetStateAction<IReviewData>
  >;
}

const AmountStep: React.FC<Props> = ({
  setStep,
  setTransferAmount,
  setReviewData,
}) => {
  const router = useRouter();
  const [amount, setAmount] = useState<number>();

  const {
    data: businessHours,
    loading: businessHoursLoading,
    loading: businessHoursError,
  } = useBusinessHours();

  const defaultValues = { amount: 0 };
  const schema = yupPt.object().shape({
    amount: yupPt
      .string()
      .transform(originalValue => {
        const formattedValue = originalValue.replace(
          /[^\d]/g,
          '',
        );
        return formattedValue;
      })
      .test('min-amount', 'Valor mínimo de R$5,00', value => {
        const centsValue = Number(value);
        return centsValue >= 500;
      }),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: { amount: number }) {
    setReviewData({});
    if (!amount) {
      return;
    }
    setTransferAmount(amount);
    setStep('step2');
  }

  const steps = ['Início', 'TED', 'Valor'];

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <ContentTitle title="Transferir" steps={steps} />
      {!businessHoursLoading &&
        !businessHoursError &&
        businessHours.map(
          (item: IBusinessHours, key: number) => {
            if (item.payment_method == OperationType.TED) {
              return (
                <Alert
                  key={key}
                  variant="outlined"
                  severity="warning"
                  icon={<Icon name="clock" size={18} />}
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'warning.contrastText',
                    display: 'inline-flex',
                    color: 'warning.main',
                    py: 0,
                    mt: -1,
                    mb: 2,
                  }}
                >
                  Disponível das{' '}
                  {dayjsUtc(item.start, 'HH:mm:ss').format(
                    'H[h]m',
                  )}{' '}
                  às{' '}
                  {dayjsUtc(item.end, 'HH:mm:ss').format(
                    'H[h]m',
                  )}
                </Alert>
              );
            }
          },
        )}
      <TextField
        {...register('amount', { required: true })}
        fullWidth
        margin="dense"
        label="Valor"
        autoComplete="off"
        value={amount ? formatBRL(amount) : ''}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setAmount(parseBRL(event.target.value));
        }}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
            maxLength: 16,
          },
        }}
        error={Boolean(errors.amount)}
        helperText={errors.amount && errors.amount.message}
        sx={{ mb: 4 }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => router.back()}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
    </Box>
  );
};
export default AmountStep;
