const enum Targets {
	ROOT = "/",
	ONBOARDING = "/cadastro",
	ONBOARDING_DATA = "/cadastro/dados",
	ONBOARDING_DOCUMENT = "/cadastro/documento",
	ONBOARDING_ADDRESS = "/cadastro/endereco",
	ONBOARDING_PHONE = "/cadastro/telefone",
	ONBOARDING_PASSWORD = "/cadastro/senha",

	DATA = "/meus-dados",
	ADDRESS = "/endereco",
	DOCUMENT = "/documento",
	PHONE = "/telefone",
	PASSWORD = "/senha",

	ANALYSIS = "/em-analise",
	REFUSED = "/nao-aprovado",

	BACKOFFICE_DASHBOARD = "/",
	BACKOFFICE_USERS_LIST = "/usuarios",
	BACKOFFICE_USERS_CREATE = "/usuarios/novo",

	BACKOFFICE_ONBOARDING = "/usuarios/onboarding",
	BACKOFFICE_ONBOARDING_USER_ID = "/usuarios/onboarding/[user_id]",

	BACKOFFICE_USERS_PROFILE = "/usuarios/perfil",
	BACKOFFICE_USERS_PROFILE_USER_ID = "/usuarios/perfil/[user_id]",

	BACKOFFICE_USERS_ADDRESS = "/usuarios/endereco",
	BACKOFFICE_USERS_ADDRESS_USER_ID = "/usuarios/endereco/[user_id]",

	BACKOFFICE_USERS_DOCUMENT = "/usuarios/documento",
	BACKOFFICE_USERS_DOCUMENT_USER_ID = "/usuarios/documento/[user_id]",

	BACKOFFICE_USERS_PHONE = "/usuarios/telefone",
	BACKOFFICE_USERS_PHONE_USER_ID = "/usuarios/telefone/[user_id]",

	BACKOFFICE_USERS_PASSWORD = "/usuarios/senha",
	BACKOFFICE_USERS_PASSWORD_USER_ID = "/usuarios/senha/[user_id]",

	BACKOFFICE_USERS_WALLET_FIAT = "/usuarios/carteira-fiat",
	BACKOFFICE_USERS_WALLET_FIAT_USER_ID = "/usuarios/carteira-fiat/[user_id]",
	BACKOFFICE_USERS_WALLET_FIAT_RECEIPT_ID = "/usuarios/carteira-fiat/[user_id]/comprovante/[operation]/[receipt_id]",

	BACKOFFICE_USERS_WALLET_FIAT_TRANSFER = "/usuarios/carteira-fiat/[user_id]/transferir",
	BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_TED = "/usuarios/carteira-fiat/[user_id]/transferir/ted",
	BACKOFFICE_USERS_WALLET_FIAT_TRANSFER_P2P = "/usuarios/carteira-fiat/[user_id]/transferir/p2p",

	BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT = "/usuarios/carteira-fiat/[user_id]/depositar",
	BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT_TED = "/usuarios/carteira-fiat/[user_id]/depositar/ted",
	BACKOFFICE_USERS_WALLET_FIAT_DEPOSIT_PIX = "/usuarios/carteira-fiat/[user_id]/depositar/pix",

	BACKOFFICE_USERS_WALLET_FIAT_TICKET = "/usuarios/carteira-fiat/[user_id]/boleto",

	BACKOFFICE_USERS_WALLET_CRIPTO = "/usuarios/carteira-cripto",
	BACKOFFICE_USERS_WALLET_CRIPTO_RECEIPT_ID = "/usuarios/carteira-cripto/[crypto]/[user_id]/comprovante/[operation]/[receipt_id]",

	BACKOFFICE_USERS_CRIPTO_BTC = "/usuarios/carteira-cripto/btc",
	BACKOFFICE_USERS_CRIPTO_BTC_USER_ID = "/usuarios/carteira-cripto/btc/[user_id]",
	BACKOFFICE_USERS_WALLET_CRIPTO_BTC_TRANSFER = "/usuarios/carteira-cripto/btc/[user_id]/transferir",
	BACKOFFICE_USERS_WALLET_CRIPTO_BTC_DEPOSIT = "/usuarios/carteira-cripto/btc/[user_id]/depositar",

	BACKOFFICE_USERS_CRIPTO_BKAI = "/usuarios/carteira-cripto/bkai",
	BACKOFFICE_USERS_CRIPTO_BKAI_USER_ID = "/usuarios/carteira-cripto/bkai/[user_id]",
	BACKOFFICE_USERS_WALLET_CRIPTO_BKAI_TRANSFER = "/usuarios/carteira-cripto/bkai/[user_id]/transferir",
	BACKOFFICE_USERS_WALLET_CRIPTO_BKAI_DEPOSIT = "/usuarios/carteira-cripto/bkai/[user_id]/depositar",

	BACKOFFICE_TEAM_LIST = "/equipe",
	BACKOFFICE_TEAM_CREATE = "/equipe/novo",
	BACKOFFICE_TEAM_EDIT = "/equipe",
	BACKOFFICE_TEAMID_EDIT = "/equipe/[team_id]",

	BACKOFFICE_CONF_BANK_LIST = "/configuracoes/bancos",
	BACKOFFICE_CONF_BANK_CREATE = "/configuracoes/bancos/novo",
	BACKOFFICE_CONF_BANK_EDIT = "/configuracoes/bancos",
	BACKOFFICE_CONF_BANKID_EDIT = "/configuracoes/bancos/[bank_id]",

	BACKOFFICE_CONF_CITY_LIST = "/configuracoes/cidades",
	BACKOFFICE_CONF_CITY_CREATE = "/configuracoes/cidades/novo",
	BACKOFFICE_CONF_CITY_EDIT = "/configuracoes/cidades",
	BACKOFFICE_CONF_CITYID_EDIT = "/configuracoes/cidades/[city_id]",

	BACKOFFICE_CONF_STATE_LIST = "/configuracoes/estados",
	BACKOFFICE_CONF_STATE_CREATE = "/configuracoes/estados/novo",
	BACKOFFICE_CONF_STATE_EDIT = "/configuracoes/estados",
	BACKOFFICE_CONF_STATEID_EDIT = "/configuracoes/estados/[state_id]",

	BACKOFFICE_CONF_COUNTRY_LIST = "/configuracoes/paises",
	BACKOFFICE_CONF_COUNTRY_CREATE = "/configuracoes/paises/novo",
	BACKOFFICE_CONF_COUNTRY_EDIT = "/configuracoes/paises",
	BACKOFFICE_CONF_COUNTRYID_EDIT = "/configuracoes/paises/[country_id]",

	BACKOFFICE_CONF_PERMISSION_LIST = "/configuracoes/permissoes",
	BACKOFFICE_CONF_PERMISSION_CREATE = "/configuracoes/permissoes/novo",
	BACKOFFICE_CONF_PERMISSION_EDIT = "/configuracoes/permissoes",
	BACKOFFICE_CONF_PERMISSIONID_EDIT = "/configuracoes/permissoes/[permission_id]",

	BACKOFFICE_CONF_FUNCTION_LIST = "/configuracoes/funcoes",
	BACKOFFICE_CONF_FUNCTION_CREATE = "/configuracoes/funcoes/novo",
	BACKOFFICE_CONF_FUNCTION_EDIT = "/configuracoes/funcoes",
	BACKOFFICE_CONF_FUNCTIONID_EDIT = "/configuracoes/funcoes/[role_id]",

	SERVER_ERROR_INTERNAL = "/500",
}

export default Targets;
