const enum Masks {
	CEP = "00000-000",
	CNAE = "00.00-0-00",
	CNPJ = "00.000.000/0000-00",
	CNPJ_OTHER = "00.000.000/0000-000",
	CPF = "000.000.000-00",
	CPF_CNPJ = "000.000.000-000",
	CODE = "000000",
	DATE = "00/00/0000",
	PHONE = "(00) 00000-0000",
	DATE_TIME = "00/00/0000 00:00:00",
	AGENCY = "00000",
	BRANCH = "000000000000",
	TYPEABLE_LINE = "00000.00000 00000.000000 00000.000000 0 000000000000000",
}

export default Masks;
