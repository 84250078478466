const ToolTip = (theme: any) => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          fontSize: '14px',
          fontWeight: 400,
        },
        arrow: {
          color: theme.palette.common.white,
        },
      },
    },
  };
};

export default ToolTip;
