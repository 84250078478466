import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { MaskedInput } from '@2ndmarket/components';
import {
  Masks,
  formatBRL,
  formatCpfCnpj,
} from '@2ndmarket/helpers';
import { usePrice } from '@2ndmarket/services';
import { ContentTitle } from '../../../../components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  transferAmount: any;
  transferTarget: any;
  setStep: any;
  targetName: any;
  favorite: boolean;
  setFavorite: React.Dispatch<React.SetStateAction<boolean>>;
  setTransferData: React.Dispatch<React.SetStateAction<object>>;
  cryptoName: string;
}

interface FormData {
  amount: number;
  cpf_cnpj: string;
  favorite: boolean;
  targetName: string;
  tax: number;
}

const ReviewStep: React.FC<Props> = ({
  transferAmount,
  transferTarget,
  setStep,
  targetName,
  favorite,
  setFavorite,
  setTransferData,
  cryptoName,
}) => {
  const steps = [
    'Início',
    cryptoName.toUpperCase(),
    'Valor',
    'Destinatário',
    'Revisão',
  ];
  const { data: coinPrice } = usePrice(cryptoName);

  const defaultValues = {
    cpf_cnpj: formatCpfCnpj(transferTarget),
    amount: transferAmount,
    targetName: targetName,
    favorite: favorite,
    tax: 0,
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const favoriteWatcher = useWatch({
    control,
    name: 'favorite',
  });

  useEffect(() => {
    if (favoriteWatcher != favorite) {
      setFavorite(favoriteWatcher);
    }
  }, [setFavorite, favorite, favoriteWatcher]);

  function onSubmit(data: FormData) {
    data.cpf_cnpj = data.cpf_cnpj.replace(/[^\d]/g, '');
    setTransferData(data);
    setStep('step4');
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <ContentTitle title="Transferir" steps={steps} />
      <Typography
        component="p"
        sx={{ fontWeight: '500', color: 'text.secondary' }}
      >
        Transferindo {cryptoName.toUpperCase()}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <Typography
          component="p"
          variant="subtitle1"
          color="primary.main"
        >
          {transferAmount + ' ' + cryptoName.toUpperCase()}
        </Typography>
        <Typography
          component="p"
          variant="body2"
          color="grey.300"
        >
          {`≈ ${formatBRL(
            Number(transferAmount) * coinPrice.value,
          )}`}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 3,
          mb: 1,
          display: 'grid',
          gap: 3,
          alignItems: 'start',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
          },
          '& .MuiTextField-root': {
            margin: 0,
          },
        }}
      >
        <TextField
          {...register('cpf_cnpj', { required: true })}
          sx={{ marginBottom: 5 }}
          disabled
          fullWidth
          label="CPF/CNPJ"
          error={Boolean(errors.cpf_cnpj)}
          helperText={errors.cpf_cnpj && errors.cpf_cnpj.message}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              unmask: true,
              mask:
                transferTarget.replace(/\D/g, '').length < 12
                  ? Masks.CPF
                  : Masks.CNPJ,
              inputMode: 'numeric',
            },
          }}
        />
        <TextField
          {...register('targetName', { required: true })}
          sx={{ marginBottom: 5 }}
          disabled
          fullWidth
          label="Destinatário"
          error={Boolean(errors.targetName)}
          helperText={
            errors.targetName && errors.targetName.message
          }
        />
      </Box>
      <FormControlLabel
        control={
          <Switch
            {...register('favorite')}
            checked={favoriteWatcher}
            color="primary"
          />
        }
        label="Contato favorito"
        labelPlacement="start"
        sx={{
          justifyContent: 'space-between',
          height: '56px',
          width: '100%',
          color: 'text.secondary',
          mx: 0,
        }}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mb: 4,
          py: 2,
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'grey.200',
        }}
      >
        <Typography
          component="p"
          sx={{ color: 'text.secondary' }}
        >
          Tarifa
        </Typography>
        <Typography component="p" sx={{ color: 'primary.main' }}>
          Gratuita
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => setStep('step2')}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
    </Box>
  );
};
export default ReviewStep;
