import NextLink from 'next/link';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Icon, themes } from '@2ndmarket/components';

interface ObjectProps {
  name: string;
  link: string;
}

interface Props {
  links: Array<ObjectProps>;
}

const Breadcrumb: React.FC<Props> = ({ links }) => {
  return (
    <Breadcrumbs
      separator={
        <Icon
          name="arrow"
          rotate="-90deg"
          size={8}
          color={themes.bankai.palette.text.disabled}
        />
      }
      sx={{
        mb: 6,
        '.MuiBreadcrumbs-li': {
          '.MuiLink-root': {
            color: 'text.disabled',
            textDecoration: 'none',
            fontSize: '14px',
            '&:hover': {
              color: 'primary.main',
              textDecoration: 'underline',
            },
          },
          '&:last-child': {
            '.MuiLink-root': {
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.dark',
              },
            },
          },
        },
      }}
    >
      {links.map((item, index) => {
        return (
          <NextLink href={item.link} passHref key={index}>
            <Link color="primary.main">{item.name}</Link>
          </NextLink>
        );
      })}
    </Breadcrumbs>
  );
};
export default Breadcrumb;
