const List = (theme: any) => {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiList-root': {
            display: 'flex',
          },
          '&.MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {},
      },
    },
  };
};

export default List;
