import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  position: absolute;
  top: -46px;
  left: 50px;
  background-color: #7d30e0;
  padding: 0 16px;
  height: 40px;
  text-align: center;
  border-radius: 2px;

  p {
    font-weight: 600;
    font-size: 12px;
    color: #fafafa !important;
  }

  div {
    width: 10px;
    height: 10px;
    background: #7d30e0;
    border-radius: 2px;
    transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    position: absolute;
    bottom: -5px;
    left: 20px;
  }
`;
