import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';

import {
  IServiceResponse,
  IBanks,
  BanksEmpty,
} from '@2ndmarket/types';

const useBanks = (
  filter: string = '',
  ordering: string = 'asc',
  page: number = 1,
  pageSize: number = 20,
): IServiceResponse<IBanks> => {
  const params = new URLSearchParams();
  params.append('filter', filter);
  params.append('order', ordering);
  params.append('page', page.toString());
  params.append('page_size', pageSize.toString());
  const key = {
    endpoint: `/v4/locale/banks?${params}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IBanks, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? BanksEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useBanks;
