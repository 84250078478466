import useSWR from "swr";
import authenticatedFetcher from "../authenticatedFetcher";
import { FavoritesEmpty, IFavorites, IServiceResponse } from "@2ndmarket/types";
import { AppConstants } from "@2ndmarket/helpers";

const useFavorites = (
  coin_type: string | null,
  operation: string | null,
  user_id: number | AppConstants.ME = AppConstants.ME,
  filter: string = "",
  order: string = "asc",
  page: number = 1,
  pageSize: number = 30,
): IServiceResponse<IFavorites> => {
  const urlParams = new URLSearchParams();
  urlParams.append('filter', filter);
  urlParams.append('ordering', order);
  urlParams.append('page', page.toString());
  urlParams.append('page_size', pageSize.toString());
  if(coin_type) {
    urlParams.append('coin_type', coin_type.toString().toUpperCase());
  }
  if(operation) {
    urlParams.append('operation', operation.toString().toUpperCase());
  }
  
  const key = {
    endpoint: `/v4/users/${user_id}/favorites?${urlParams.toString()}`,
  }

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IFavorites, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? FavoritesEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useFavorites;
