import Box from './Box';
import List from './List';
import Link from './Link';
import Radio from './Radio';
import Input from './Input';
import Avatar from './Avatar';
import Button from './Button';
import Stepper from './Stepper';
import ToolTip from './Tooltip';
import Checkbox from './Checkbox';
import Typography from './Typography';
import IconButton from './IconButton';

const ComponentsOverrides = (theme: any) => {
  return {
    ...Box(theme),
    ...List(theme),
    ...Link(theme),
    ...Radio(theme),
    ...Input(theme),
    ...Avatar(theme),
    ...Button(theme),
    ...Stepper(theme),
    ...ToolTip(theme),
    ...Checkbox(theme),
    ...Typography(theme),
    ...IconButton(theme),
  };
};

export default ComponentsOverrides;
