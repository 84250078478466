import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Icon, IconName, themes } from '@2ndmarket/components';
import { months } from '@2ndmarket/helpers';
import { useStatement } from '@2ndmarket/services';
import { IStatementRequest } from '@2ndmarket/types';
import StatementFilter from './StatementFilter';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface IResultsData {
  type: string;
  color: string;
  value: IconName;
}

interface IResults {
  data: IResultsData[];
  operation: string;
  id: string;
}

const DigitalWallet: React.FC = () => {
  const router = useRouter();
  const date = new Date();
  const currentYear = date.getFullYear().toString();
  const monthDate =
    date.getMonth() < 9
      ? '0' + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString();
  const initialFilters = {
    year: currentYear,
    month: monthDate,
    page: 1,
    page_size: 10,
  };
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(monthDate);
  const [filters, setFilters] =
    useState<IStatementRequest>(initialFilters);

  const { data: statement, loading: statementLoading } =
    useStatement(filters);

  useEffect(() => {
    if (
      selectedMonth !== filters.month ||
      selectedYear !== filters.year
    ) {
      setFilters({
        ...filters,
        month: selectedMonth ?? monthDate,
        year: selectedYear ?? currentYear,
      });
    }
  }, [
    setFilters,
    filters,
    selectedMonth,
    monthDate,
    selectedYear,
    currentYear,
  ]);

  const [filterOpen, setFilterOpen] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilters({
      ...filters,
      page: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilters({
      ...filters,
      page_size: +event.target.value,
    });
  };

  return (
    <>
      <Box mb={4}>
        <Typography
          variant="h4"
          component="h4"
          color="text.primary"
          mb={2}
        >
          Extrato
        </Typography>
        <Box
          sx={{
            bgcolor: 'common.white',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setFilterOpen(true)}
            variant="text"
            sx={{
              color: 'text.secondary',
              display: 'flex',
              gap: 1,
              p: 2,
              '&:hover': {
                bgcolor: 'common.white',
                color: 'primary.main',
              },
            }}
          >
            <Icon
              name="configure"
              color={themes.bankai.palette.grey[300]}
            />
            Filtro
          </Button>
          <List
            sx={{
              p: 0,
              gap: '6px',
              '.MuiListItem-root': {
                color: 'primary.main',
                fontSize: '14px',
                bgcolor: 'grey.400',
                borderRadius: '3px',
                py: '4px',
                px: '8px',
              },
            }}
          >
            <ListItem>{selectedYear}</ListItem>
            <ListItem>
              {months.map(item => {
                if (
                  item.alternativeIndex == Number(selectedMonth)
                ) {
                  return item.value;
                }
              })}
            </ListItem>
          </List>
        </Box>
        <TableContainer
          sx={{
            maxHeight: 440,
            bgcolor: 'common.white',
            paddingX: 2,
          }}
        >
          <Table aria-label="table">
            <TableBody
              sx={{
                '.MuiTableCell-root': {
                  borderBottom:
                    '1px solid ' +
                    themes.bankai.palette.grey[200],
                  borderTop:
                    '1px solid ' +
                    themes.bankai.palette.grey[200],
                },
              }}
            >
              {statementLoading ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: 'grey.300',
                      textAlign: 'center',
                      fontSize: '16px',
                    }}
                  >
                    Carregando extrato...
                  </TableCell>
                </TableRow>
              ) : statement.results.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: 'grey.300',
                      textAlign: 'center',
                      fontSize: '16px',
                    }}
                  >
                    Não foram encontradas movimentações no
                    período selecionado.
                  </TableCell>
                </TableRow>
              ) : (
                statement.results.map(
                  (row: IResults, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        onClick={() =>
                          router.push(
                            `/carteira-digital/comprovante/` +
                              row.operation +
                              '/' +
                              row.id,
                          )
                        }
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        {row.data.map(
                          (
                            column: IResultsData,
                            index: number,
                          ) => {
                            return (
                              <TableCell
                                key={index}
                                sx={{
                                  width:
                                    column.type === 'icon'
                                      ? '20px'
                                      : 'auto',
                                }}
                              >
                                {column.type === 'icon' ? (
                                  <Icon
                                    size={20}
                                    name={column.value}
                                    color={column.color}
                                  />
                                ) : (
                                  column.value
                                )}
                              </TableCell>
                            );
                          },
                        )}
                        <TableCell sx={{ width: '20px' }}>
                          <Icon
                            size={20}
                            name="list"
                            color={
                              themes.bankai.palette.grey[300]
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  },
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[0, 10, 25, 100]}
          component="div"
          count={statement.totalRecords}
          rowsPerPage={statement.pageSize}
          page={statement.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Exibir"
          sx={{ bgcolor: 'common.white' }}
        />
      </Box>
      <StatementFilter
        open={filterOpen}
        setOpen={setFilterOpen}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
    </>
  );
};

export default DigitalWallet;
