const enum Crypto {
	BKAI = "bkai",
	BTC = "btc",
	ETH = "eth",
	ADA = "ada",
	USDT = "usdt",
	BRL = "brl",
}

export default Crypto;
