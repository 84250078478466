import { useState } from 'react';
import { useRouter } from 'next/router';
import { Icon } from '@2ndmarket/components';
import {
  Crypto,
  ICryptoAsset,
  cryptoAssets,
  fiatFormat,
} from '@2ndmarket/helpers';
import {
  useCryptoBalance,
  useCryptoWallet,
  usePrice,
} from '@2ndmarket/services';
import OpenWallet from './OpenWallet';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

const CryptoWalletList: React.FC = () => {
  const router = useRouter();
  const { data: walletBitcoin } = useCryptoWallet(Crypto.BTC);
  const { data: walletBkai } = useCryptoWallet(Crypto.BKAI);
  const { data: bitcoinPrice } = usePrice(Crypto.BTC);
  const { data: bkaiPrice } = usePrice(Crypto.BKAI);
  const [openWalletShow, setOpenWalletShow] = useState(false);
  const [cryptoOpen, setCryptoOpen] = useState(cryptoAssets.BTC);

  function walletAction(
    walletData: Array<Object>,
    cryptoData: ICryptoAsset,
  ) {
    if (walletData.length > 0) {
      router.push(`/carteira-cripto/` + cryptoData.abbreviation);
    } else {
      setCryptoOpen(cryptoData);
      setOpenWalletShow(true);
    }
  }

  return (
    <>
      <Typography
        variant="h4"
        component="h4"
        color="text.primary"
        mb={2}
      >
        Minhas carteiras
      </Typography>
      <Box
        sx={{
          display: 'grid',
          mb: 4,
          gap: '12px',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
          },
          '.MuiButtonBase-root': {
            height: 'auto',
            bgcolor: 'common.white',
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            borderRadius: '3px',
            '&:hover': {
              transition:
                'box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              boxShadow:
                '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
            },
            '.leftIcons': {
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              '.cryptoName': {
                textAlign: 'left',
                color: 'text.primary',
                '& span': {
                  color: 'text.secondary',
                  fontSize: '14px',
                },
              },
            },
            '.rightText': {
              textAlign: 'right',
              '& p': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
              '& span': {
                color: 'text.secondary',
                fontSize: '14px',
              },
            },
          },
        }}
      >
        <Button
          variant="text"
          onClick={() =>
            walletAction(walletBitcoin, cryptoAssets.BTC)
          }
        >
          <Box className="leftIcons">
            <Avatar sx={{ bgcolor: 'crypto.bitcoin' }}>
              <Icon name="bitcoin" size={20} />
            </Avatar>
            <Box className="cryptoName">
              <Typography component="p" variant="body1">
                BTC
              </Typography>
              <Typography component="span" variant="body1">
                Bitcoin
              </Typography>
            </Box>
          </Box>
          <Box className="rightText">
            <Typography component="p" variant="body1">
              {walletBitcoin.length > 0
                ? 'Ver carteira'
                : 'Abrir carteira'}
            </Typography>
            <Typography component="span" variant="body1">
              1 ≈ R$ {fiatFormat(bitcoinPrice.value)}
            </Typography>
          </Box>
        </Button>
        <Button
          variant="text"
          onClick={() =>
            walletAction(walletBkai, cryptoAssets.BKAI)
          }
        >
          <Box className="leftIcons">
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <Icon name="bkai-coin" size={20} />
            </Avatar>
            <Box className="cryptoName">
              <Typography component="p" variant="body1">
                BKAI
              </Typography>
              <Typography component="span" variant="body1">
                Bkaicoin
              </Typography>
            </Box>
          </Box>
          <Box className="rightText">
            <Typography component="p" variant="body1">
              {walletBkai.length > 0
                ? 'Ver carteira'
                : 'Abrir carteira'}
            </Typography>
            <Typography component="span" variant="body1">
              1 = R$ {fiatFormat(bkaiPrice.value)}
            </Typography>
          </Box>
        </Button>
      </Box>
      {openWalletShow && (
        <OpenWallet
          open={openWalletShow}
          setOpen={setOpenWalletShow}
          crypto={cryptoOpen}
        />
      )}
    </>
  );
};

export default CryptoWalletList;
