import styled from 'styled-components';
import { theme } from 'styled-tools';

interface Props {
  subtitle?: boolean;
  soon?: boolean;
}

export const Container = styled.div<Props>`
  display: inline-block;
  text-align: center;
  padding: 15px;
  background-color: white;
  border-radius: 2px;
  width: 100%;
  border: 1px solid #eff1f6;
  position: relative;

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    text-align: left;

    div {
      display: flex;
      flex-direction: column;

      span {
        color: ${props => (props.soon ? '#bbc2cb' : '#8c96a0')};
        font-size: 14px;
        font-weight: 500;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #8c96a0;
      }
    }
  }

  input {
    height: 0;
    opacity: 0;
    width: 0;
    position: absolute;
  }

  label > span {
    border: 1px solid #eff1f6;
    border-radius: 3px;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 15px;
    transition: all 0.3s;
    width: 18px;
  }

  input:checked + label > span {
    animation: bounce 250ms;
    border: 8px solid ${theme('colors.primary.main')};
  }

  input:checked + label > span::before {
    animation: checked-box 125ms 250ms forwards;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    content: '';
    left: 5px;
    position: absolute;
    top: ${props => (props.subtitle ? '24px' : '12px')};
    transform-origin: 0% 100%;
    transform: rotate(45deg);
  }

  @keyframes checked-box {
    0% {
      border-color: #fbfbff;
      height: 0;
      transform: translate(0, 0) rotate(45deg);
      width: 0;
    }
    33% {
      border-color: #fbfbff;
      height: 0;
      transform: translate(0, 0) rotate(45deg);
      width: 4px;
    }
    100% {
      border-color: #fbfbff;
      height: 8px;
      transform: translate(0, -8px) rotate(45deg);
      width: 4px;
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    33% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const ComingSoon = styled.p`
  position: absolute;
  right: 0;
  background-color: ${theme('colors.secondary.6')};
  padding: 6px 15px;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #bbc2cb;
`;
