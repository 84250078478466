import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

interface Props {
  filled?: boolean;
  error?: boolean;
  disabled?: boolean;
  icon?: boolean;
  noPlaceholder?: boolean;
}

export const WrapCustomSelect = styled.div<Props>`
  position: relative;
  width: 100%;

  > i {
    top: 20px;
    right: 15px;
    line-height: 1;
    position: absolute;
    pointer-events: none;
    transform: rotate(-90deg);
    font-size: 12px !important;
    color: ${theme('colors.secondary.3')};
  }

  .selectIcon {
    top: 12px;
    left: 15px;
    max-width: 32px;
    position: absolute;
  }

  label {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #8c96a0;
    position: absolute;
    left: ${({ icon }) => (icon == undefined ? 15 : 55)}px;
    top: 16px;
    cursor: pointer;
    transition: all 0.25s ease;
  }

  .inputContent {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #5a6473;
    background-color: ${theme('colors.white')};
    border: 1px solid #f0f0f9;
    height: 56px;
    padding: 25px 15px 9px 15px;
    padding-left: ${({ icon }) =>
      icon == undefined ? 15 : 55}px;
    transition: border-color 0.25s ease;
    width: 100%;
    display: block;
    cursor: pointer;
    white-space: nowrap;

    &:disabled {
      color: ${theme('colors.secondary.3')};
    }
  }

  ${({ filled }) =>
    filled &&
    css`
      label {
        font-weight: 500;
        color: ${theme('colors.secondary.3')};
        font-size: 12px;
        line-height: 15px;
        top: 8px;
      }

      .inputContent {
        padding: 20px 15px 9px 15px;
      }
    `}

  ${({ icon }) =>
    icon &&
    css`
      .inputContent {
        padding: 20px 15px 9px 55px;
      }
    `}

  ${({ noPlaceholder }) =>
    noPlaceholder &&
    css`
      label {
        display: none;
      }

      .inputContent {
        padding-top: 15px;
      }
    `}

  &:focus .inputContent,
  &:focus-within .inputContent {
    border-color: ${theme('colors.primary.main')};
  }

  .errorMessage {
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme('colors.error.main')};
    margin: 0;
    transform: translateY(100%);

    height: 24px;
    width: 100%;
  }

  ${({ error }) =>
    error &&
    css`
      .inputContent {
        border: 1px solid ${theme('colors.error.main')};
      }
      input {
        border: 1px solid ${theme('colors.error.main')};

        &:focus {
          border: 1px solid ${theme('colors.error.main')};
        }

        &:focus + label {
          color: ${theme('colors.error.main')};
        }
      }

      .errorMessage {
        display: block;
        margin: 5px 0;
        height: 24px;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      label,
      .inputContent {
        color: ${theme('colors.secondary.3')} !important;
      }
    `}

  @media only screen and (max-width: 1200px) {
    height: 61px;
  }
`;

export const SearchBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const WrapSearchBox = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);

  @media only screen and (max-width: 1200px) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const SearchBox = styled.div`
  position: relative;
  background-color: ${theme('colors.white')};
  padding: 60px 40px 60px 60px;
  width: 100%;
  height: 90%;
  max-width: 634px;
  max-height: 566px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 25px rgba(26, 36, 137, 0.05);
  border-radius: 2px;

  @media only screen and (max-width: 1200px) {
    max-height: 100%;
    box-shadow: none;
    border-radius: 24px 24px 0px 0px;
    padding: 60px 28px 0 28px;
    margin-top: 100px;
  }

  .closeButton {
    position: absolute;
    right: 24px;
    top: 24px;
    background-color: transparent;
    border: none;
    color: ${theme('colors.secondary.3')};
    font-size: 12px;
    cursor: pointer;

    i:hover {
      color: ${theme('colors.primary.main')};
    }
  }

  h2 {
    font-size: 24px;
    color: #5a6473;
    font-weight: 600;
  }

  .wrapSearch {
    padding-right: 40px;
    position: relative;
    margin-bottom: 20px;

    @media only screen and (max-width: 1200px) {
      padding-right: 0;
    }

    > i {
      position: absolute;
      top: 20px;
      right: 55px;
      font-size: 18px;
      color: ${theme('colors.secondary.3')};

      @media only screen and (max-width: 1200px) {
        right: 20px;
      }
    }

    input {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      color: #5a6473;
      background-color: ${theme('colors.white')};
      border: 1px solid #f0f0f9;
      width: calc(100%);
      height: 56px;
      padding: 18px 15px;
      transition: border-color 0.25s ease;
      cursor: text;

      &:focus {
        border-color: ${theme('colors.primary.main')};
        outline: none;
      }
    }
  }

  .selectList {
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 40px;
    margin-top: 5px;

    @media only screen and (max-width: 1200px) {
      padding-right: 20px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #eff1f6;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background: ${theme('colors.secondary.3')};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      font-size: 16px;
      font-family: Montserrat, sans-serif;
      font-weight: 400;
      padding: 10px 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: 1px solid #eff1f6;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme('colors.white')};
        margin: 0 10px 0 0;
        pointer-events: none;

        i {
          line-height: 1;
          display: none;
          font-size: 10px;
          color: ${theme('colors.white')};
        }

        &.checked {
          border: 1px solid ${theme('colors.primary.main')};
          background-color: ${theme('colors.primary.main')};

          i {
            display: block;
            color: ${theme('colors.white')};
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #eff1f6;
      }

      &:hover {
        span {
          border: 1px solid ${theme('colors.primary.main')};
        }
      }
    }
  }

  .closeText {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: ${theme('colors.primary.main')};
    cursor: pointer;
    margin: 40px 0 0 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;
