import { EducationLevel, FederalRevenueSituation, LegalNature, Maritage, ReviewStep } from "@2ndmarket/helpers";

export interface IProfileUpdate {
  social_name: string,
  birthdate: string,
}

export interface IProfile {
  id: 0,
  user_id: 0,
  patrimony: 0,
  monthly_income: number,
  nationality_id: number,
  occupation_id: number,
  social_name: '',
  company_name: '',
  fantasy_name: '',
  legal_nature: '',
  primary_cnae: '',
  contact_name: string,
  contact_cpf: string,
  company_title: string,
  gender: string,
  maritage: Maritage,
  exposed_socially: string,
  exposed_socially_since: string,
  birthdate: string,
  education_level: EducationLevel,
  federalRevenueSituation: FederalRevenueSituation,
  status: ReviewStep,
}

export const ProfileEmpty: IProfile = {
  id: 0,
  user_id: 0,
  patrimony: 0,
  monthly_income: 0,
  nationality_id: 0,
  occupation_id: 0,
  social_name: '',
  company_name: '',
  fantasy_name: '',
  legal_nature: '',
  primary_cnae: '',
  contact_name: '',
  contact_cpf: '',
  company_title: '',
  gender: '',
  maritage: Maritage.NOT_MARRIED,
  exposed_socially: '',
  exposed_socially_since: '',
  birthdate: '',
  education_level: EducationLevel.FIRST_DEGREE,
  federalRevenueSituation: FederalRevenueSituation.REGULAR,
  status: ReviewStep.APPROVED,
};