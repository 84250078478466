import { ReviewStep } from '@2ndmarket/helpers';

export interface IDocument {
  type: string;
  mothers_name: string;
  file_front: string;
  file_back: string;
  expiration_date?: string;
  number?: string;
  issuer?: string;
  issued_at?: string;
  uploaded_at?: string;
  updated_at?: string;
  created_at?: string;
  status?: ReviewStep;
  user_id?: number;
  state_id?: number;
}

export const DocumentEmpty: IDocument = {
  file_front: '',
  file_back: '',
  type: '',
  number: '',
  issuer: '',
  issued_at: '',
  mothers_name: '',
  expiration_date: '',
  uploaded_at: '',
  updated_at: '',
  created_at: '',
  status: ReviewStep.APPROVED,
  user_id: 0,
  state_id: 0,
};
