import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Content = styled.footer`
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 58px;

  @media (max-width: ${theme('breakpoints.mobile')}) {
    display: none;
  }

  > div {
    display: flex;
    gap: 38px;

    a {
      color: ${theme('colors.secondary.2')};
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;

      :hover {
        color: ${theme('colors.primary.main')};
      }
    }
  }
`;
