export const civilStates = [
  {
    value: "MARRIED",
    label: "Casado",
  },
  {
    value: "DIVORCED",
    label: "Divorciado",
  },
  {
    value: "SEPARATE",
    label: "Separado",
  },
  {
    value: "NOT_MARRIED",
    label: "Solteiro",
  },
  {
    value: "STABLE_UNION",
    label: "União Estável",
  },
  {
    value: "WIDOWER",
    label: "Viúvo",
  },
];
