import styled, { keyframes } from 'styled-components';
import { theme } from 'styled-tools';

const animateSquareOne = keyframes`
  0%,
  100% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: calc(100% - 20px);
  }
  50% {
    left: calc(100% - 45px);
    top: calc(100% - 45px);
    height: 35px;
    width: 35px;
  }
  75% {
    left: 0;
    top: calc(100% - 20px);
  }
`;

const animateSquareTwo = keyframes`
  0%,
  100% {
    left: calc(100% - 45px);
    top: calc(100% - 45px);
    height: 35px;
    width: 35px;
  }
  25% {
    top: calc(100% - 20px);
    left: 0;
  }
  50% {
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
  }
  75% {
    left: calc(100% - 20px);
    top: 0;
  }
`;

export const Container = styled.div`
  align-items: center;
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  z-index: 1;
  width: 100%;
  height: 100%;

  p {
    margin-top: 20px;
  }
`;

export const Square = styled.div`
  height: 60px;
  position: relative;
  width: 60px;

  &::after,
  &::before {
    content: '';
    position: absolute;
  }

  &::before {
    animation: ${animateSquareOne} 3s linear infinite;
    border: 6px solid ${theme('colors.primary.main')};
    border-radius: 0;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    height: 20px;
    width: 20px;
  }

  &::after {
    animation: ${animateSquareTwo} 3s linear infinite;
    border: 6px solid ${theme('colors.primary.dark')};
    height: 35px;
    left: calc(100% - 45px);
    top: calc(100% - 45px);
    width: 35px;
  }
`;
