const IconButton = (theme: any) => {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            color: theme.palette.primary.main,
            border: 'none',
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
              border: 'none !important',
              i: {
                color: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  };
};

export default IconButton;
