import { ReviewStep } from '@2ndmarket/helpers';

export interface IAddressUpdate {
  postcode: string;
  district: string;
  street: string;
  number: string;
  complement: string;
  country_id?: number;
  state_id: number | string;
  city_id: number | string;
  user_id?: number;
  status?: string;
}

export interface IAddress {
  postcode: string;
  district: string;
  street: string;
  number: string;
  complement: string;
  country_id?: number;
  state_id: number;
  state: {
    id: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    country_id: number;
    name: string;
    initials: number;
  };
  city_id: number;
  city: {
    id: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    name: string;
    state_id: number;
  };
  user_id?: number;
  status: ReviewStep;
}

export const AddressEmpty: IAddress = {
  postcode: '',
  district: '',
  street: '',
  number: '',
  complement: '',
  country_id: 0,
  state_id: 0,
  state: {
    id: 0,
    updated_by: 0,
    created_at: '',
    updated_at: '',
    country_id: 0,
    name: '',
    initials: 0,
  },
  city_id: 0,
  city: {
    id: 0,
    updated_by: 0,
    created_at: '',
    updated_at: '',
    name: '',
    state_id: 0,
  },
  user_id: 0,
  status: ReviewStep.APPROVED,
};
