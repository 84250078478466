import Box from '@mui/material/Box';

const ContentCenterGrid: React.FC = ({ children }) => {
  return (
    <Box
      width="100%"
      paddingX={3}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
    >
      <Box width="100%" maxWidth="760px">
        {children}
      </Box>
    </Box>
  );
};
export default ContentCenterGrid;
