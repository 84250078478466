export interface IBusinessHours {
  id: number,
  payment_method: string,
  start: string,
  end: string
}

export const BusinessHoursEmpty: IBusinessHours[] = [
  {
    id: 0,
    payment_method: 'PIX',
    start: 'string',
    end: 'string'
  }
]
