import { Button, Span } from './style';
import Loader from '../Loader';
import Icon from '../Icon';
import { useState } from 'react';
import { getFileUrl } from '../../../services';
import { dayjsUtc } from '@2ndmarket/helpers';
import dayjs from 'dayjs';
import Modal from '../Modal';

type Props = {
  title: string;
  fileName: string;
  children?: React.ReactNode;
  userId?: number;
};

function openNewTab(url: string) {
  if (!window.open(url)) {
    window.location.href = url;
  }

  window.open(url, '_blank');
}

const LinkFile: React.FC<Props> = ({
  title,
  fileName,
  children,
  userId,
}) => {
  const [expiresAt, setExpiresAt] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();

    if (!title) {
      return;
    }

    const almostNow = dayjs().add(5, 'second');
    const isExpired =
      expiresAt.length == 0 ||
      dayjsUtc(expiresAt).local().isBefore(almostNow);

    if (isExpired) {
      setIsLoading(true);
      getFileUrl(fileName, userId)
        .then(data => {
          setExpiresAt(data.expires_at);
          setFileUrl(data.url);
          openNewTab(data.url);
        })
        .catch((error: any) => {
          if (error.status == 409) {
            setErrorMessage(error.error);
          } else {
            setErrorMessage(
              'Não foi possível gerar o link de acesso ao arquivo.',
            );
          }
          setShowModal(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      openNewTab(fileUrl);
    }
  };

  if (!fileName) {
    return <Span>Nenhum</Span>;
  }

  return (
    <>
      <Button onClick={onClick}>
        {title} <Icon name="external-link" size={10} />
      </Button>
      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        errorMessage={errorMessage}
        errorTitle="Erro ao acessar o arquivo"
      />
      <Loader loaderState={isLoading} />
    </>
  );
};

export default LinkFile;
