import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { IServiceResponse, IBusinessHours, BusinessHoursEmpty } from '@2ndmarket/types';

const useBusinessHours = () : IServiceResponse<IBusinessHours[]> => {
  const key = {
    endpoint: `/v4/locale/fiat-business-hours`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<Array<IBusinessHours>, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? BusinessHoursEmpty,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useBusinessHours;
