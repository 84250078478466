export interface ITransferData {
  account: string,
  account_type: string,
  branch: string,
  amount: number,
  bank: string,
  bank_code: string,
  cpf_cnpj: string,
  favorite: boolean,
  recipient: string,
  schedule_date: string | null,
}

export const TransferDataEmpty: ITransferData = {
  account: '',
  account_type: '',
  branch: '',
  amount: 0,
  bank: '',
  bank_code: '',
  cpf_cnpj: '',
  favorite: false,
  recipient: '',
  schedule_date: '',
};


export interface IReviewData {
  account?: string,
  account_type?: string,
  branch?: string,
  amount?: number,
  bank?: string,
  bank_code?: string,
  cpf_cnpj?: string,
  favorite?: boolean,
  recipient?: string,
  schedule_date?: string | null,
}

export const ReviewDataEmpty: IReviewData = {
  account: undefined,
  account_type: undefined,
  branch: undefined,
  amount: undefined,
  bank: undefined,
  bank_code: undefined,
  cpf_cnpj: undefined,
  favorite: undefined,
  recipient: undefined,
  schedule_date: undefined,
};
