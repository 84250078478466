import { ReviewStep } from "@2ndmarket/helpers"

export interface IUsers {
  totalRecords: number,
  pageSize: number,
  startRecord: number,
  lastPage: number,
  currentPage: number,
  results: [
    {
      id: number,
      cpf_cnpj: string,
      document: {
        id: number,
        updated_by: number,
        created_at: string,
        updated_at: string,
        user_id: number,
        expiration_date: string,
        file_back: string,
        file_front: string,
        issued_at: string,
        issuer: string,
        mothers_name: string,
        number: string,
        state_id: number,
        status: string,
        type: string,
        uploaded_at: string
      },
      email: string,
      name: string,
      person_type: string,
      status_message: string,
      status: ReviewStep,
      created_at: string,
      updated_by: number,
      updated_at: string
    }
  ]
}

export const UsersEmpty: IUsers = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [
    {
      id: 0,
      cpf_cnpj: '',
      document: {
        id: 0,
        updated_by: 0,
        created_at: '',
        updated_at: '',
        user_id: 0,
        expiration_date: '',
        file_back: '',
        file_front: '',
        issued_at: '',
        issuer: '',
        mothers_name: '',
        number: '',
        state_id: 0,
        status: '',
        type: '',
        uploaded_at: ''
      },
      email: '',
      name: '',
      person_type: '',
      status_message: '',
      status: ReviewStep.PENDING,
      created_at: '',
      updated_by: 0,
      updated_at: ''
    }
  ]
}