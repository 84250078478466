const Link = (theme: any) => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
  };
};

export default Link;
