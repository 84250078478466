const GREY = {
  0: '#FFFFFF',
  100: '#FDFDFD',
  200: '#E5E9EE',
  300: '#B4B9C7',
  400: '#F5F5F8',
  500: '#919EAB',
  600: '#535668',
  700: '#292D37',
  800: '#D12100',
  900: '#FDFDFF',
};

const PRIMARY = {
  main: '#313EE2',
  dark: '#0E0CA8',
  light: '#C9CBF8',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  main: '#EAEBFD',
  dark: '#C9CBF8',
  light: '#E5E9EE',
  contrastText: '#313EE2',
};

const SUCCESS = {
  main: '#4BD281',
  light: '#E9F7F3',
  contrastText: '#E9F7F3',
};

const ERROR = {
  main: '#DC3545',
  light: '#F8ECEE',
  contrastText: '#F8ECEE',
};

const WARNING = {
  main: '#F7931A',
  light: '#F9F2EA',
  contrastText: '#F9F2EA',
};

const INFO = {
  main: '#0E0CA8',
  light: '#F5F5F8',
  contrastText: '#E9F7F3',
};

const DISABLED = {
  main: '#B4B9C7',
  contrastText: '#FDFDFF',
};

const palette = {
  mode: 'light',
  common: {
    black: '#000',
    white: '#FFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  error: ERROR,
  warning: WARNING,
  success: SUCCESS,
  info: INFO,
  disabled: DISABLED,
  grey: GREY,
  text: {
    primary: '#292D37',
    secondary: '#535668',
    disabled: '#B4B9C7',
  },
  background: {
    paper: GREY[0],
    default: GREY[400],
    neutral: GREY[100],
  },
};

export default palette;
