import { Icon, themes } from '@2ndmarket/components';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  address: string;
}

const AccountData: React.FC<Props> = ({ address }) => {
  function copyAllData() {
    navigator.clipboard.writeText(address);
  }

  return (
    <Button
      onClick={() => copyAllData()}
      variant="text"
      sx={{
        bgcolor: 'common.white',
        width: '100%',
        maxWidth: '956px',
        mt: '4px',
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        '&:hover': {
          bgcolor: 'common.white',
          boxShadow: '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
          i: {
            color: 'primary.main',
          },
        },
      }}
    >
      <Box
        display="flex"
        gap="12px"
        sx={{
          '.MuiTypography-root': {
            fontSize: '14px',
            color: 'text.primary',
          },
          'span.MuiTypography-root': {
            color: 'grey.300',
          },
        }}
      >
        <Typography component="p" variant="body1">
          {address.slice(0, 10) + '...' + address.slice(25)}
        </Typography>
      </Box>
      <Icon
        size={20}
        name="copy"
        color={themes.bankai.palette.grey[300]}
      />
    </Button>
  );
};

export default AccountData;
