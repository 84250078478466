import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const sendBillCashIn = function (
  amount: number,
  user_id: number | AppConstants.ME = AppConstants.ME
  ) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/users/${user_id}/slip-cashin?amount=${amount}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};

export default sendBillCashIn;
