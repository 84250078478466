import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon, MaskedInput } from '@2ndmarket/components';
import {
  Crypto,
  HttpError,
  IntentStatus,
  Masks,
  fiatFormat,
  formatBRL,
  toBitcoin,
  yupPt,
} from '@2ndmarket/helpers';
import {
  sendCryptoTrade,
  useCryptoBalance,
  useCryptoConversion,
  useCryptoWallet,
} from '@2ndmarket/services';
import { ITransferData, TradeType } from './Types';
import {
  TransactionModal,
  TransferAwait,
} from '../../../components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  cryptoName: Crypto;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  transferData: ITransferData;
}

interface TransferData {
  id: number | any;
  type: string | any;
  register: boolean | any;
}

const PasswordStep: React.FC<Props> = ({
  cryptoName,
  setStep,
  transferData,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const recipePath = `/carteira-cripto/comprovante/${cryptoName}/${
    cryptoName.toUpperCase() +
    transferData.tradeType.toUpperCase()
  }/`;
  const [modal, setModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [transferDataPromise, setTransferDataPromise] =
    useState<TransferData>();
  const [intentStatus, setIntentStatus] = useState(
    IntentStatus.NONE,
  );
  const modalTitle =
    transferData.tradeType == TradeType.BUY
      ? `Compra ${cryptoName.toUpperCase()}`
      : `Venda ${cryptoName.toUpperCase()}`;
  const [modalText, setModalText] = useState<string | null>(
    'Processando...',
  );
  const { data: wallet } = useCryptoWallet(cryptoName);
  const walletId = wallet[0]?.id;
  const { mutate: mutateCryptoBalance } = useCryptoBalance(
    cryptoName,
    walletId,
  );

  const defaultValues = { password: '' };
  const schema = yupPt.object().shape({
    password: yupPt.string().min(6).required(),
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: cryptoConversion } = useCryptoConversion(
    cryptoName,
    transferData.amountCoin,
    cryptoName,
    transferData.tradeType,
  );

  useEffect(() => {
    if (intentStatus == IntentStatus.DONE) {
      mutateCryptoBalance();
    }
  }, [intentStatus, mutateCryptoBalance]);

  function closeModal() {
    setModal(false);
    setStep('step1');
  }

  function onSubmit(data: { password: string }) {
    if (!transferData.amount || !data.password) {
      return;
    }
    setIntentStatus(IntentStatus.NONE);
    setModal(true);
    setModalText('');
    sendCryptoTrade(
      walletId,
      cryptoName,
      transferData.tradeType,
      transferData.amountCoin,
      data.password,
    )
      .then(() => {
        const responseP2P = localStorage.getItem(
          '@locationHeader',
        );

        const response = responseP2P
          ?.split('?')[1]
          .split('&')
          .reduce(
            (accumulator: any, item: any) => {
              const [key, value] = item.split('=');
              accumulator[key] = value;
              return accumulator;
            },
            {
              amount: transferData.amountCoin,
            },
          );
        setTransferDataPromise({
          id: parseInt(response.intent_id),
          type: response.type,
          register: Boolean(response.is_register),
        });
      })
      .catch((error: HttpError) => {
        setValue('password', '');
        setModalText(error.error);
        setIntentStatus(IntentStatus.FAILURE);
      });
  }

  return (
    <Box
      mb={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography
        variant="h4"
        component="h4"
        color="text.primary"
        mb={2}
      >
        Comprar e vender
      </Typography>

      <Box
        sx={{
          mb: 4,
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'grey.200',
          py: 2,
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '.MuiListItem-root': {
              p: 0,
              '.MuiListItemText-root': {
                display: 'flex',
                justifyContent: 'space-between',
                '.MuiListItemText-secondary': {
                  fontSize: '16px',
                },
                '& span.MuiListItemText-secondary': {
                  color: 'primary.main',
                },
              },
            },
          }}
        >
          <ListItem>
            <ListItemText
              primary="Valor"
              secondary={
                transferData.valueType == Crypto.BRL
                  ? transferData.amount
                  : transferData.amount +
                    ' ' +
                    transferData.valueType.toUpperCase()
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Valor de mercado"
              secondary={formatBRL(
                cryptoConversion.exchange_value,
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Taxas"
              secondary={`≈ ${
                cryptoConversion.fees.fee_coin.toLowerCase() ==
                Crypto.BRL
                  ? fiatFormat(cryptoConversion.fees.total_fees)
                  : toBitcoin(cryptoConversion.fees.total_fees)
              } ${cryptoConversion.fees.fee_coin}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                transferData.tradeType == TradeType.BUY
                  ? 'Total da compra'
                  : 'Total da venda'
              }
              secondary={`≈ ${
                cryptoConversion.fees.final_amount_coin.toLowerCase() ==
                Crypto.BRL
                  ? fiatFormat(
                      cryptoConversion.fees.final_amount,
                    )
                  : toBitcoin(cryptoConversion.fees.final_amount)
              } ${cryptoConversion.fees.final_amount_coin}`}
              secondaryTypographyProps={{ component: 'span' }}
            />
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '10px',
          mb: 4,
        }}
      >
        <TextField
          fullWidth
          id="password"
          label="Senha"
          margin="dense"
          autoComplete="off"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              inputMode: 'numeric',
              mask: Masks.CODE,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(
                    e: React.MouseEvent<HTMLButtonElement>,
                  ) => e.preventDefault()}
                  edge="end"
                  sx={{
                    p: 1.5,
                    mr: -1,
                    '& i': { color: 'grey.300' },
                  }}
                >
                  {showPassword ? (
                    <Icon size={20} name="view" />
                  ) : (
                    <Icon size={20} name="hide" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', { required: true })}
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password.message}
          sx={{ mb: 4 }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={() => setStep('step1')}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          {transferData.tradeType == TradeType.BUY
            ? 'Concluir compra'
            : 'Concluir venda'}
        </Button>
      </Box>
      {transferDataPromise?.id && (
        <TransferAwait
          data={transferDataPromise}
          setIntentStatus={setIntentStatus}
          setIntentDescription={setModalText}
          setTransactionId={setTransactionId}
        />
      )}
      {modal && (
        <TransactionModal
          open={modal}
          title={modalTitle}
          text={modalText}
          status={intentStatus}
          onClose={() => closeModal()}
          target={recipePath + transactionId}
        />
      )}
    </Box>
  );
};

export default PasswordStep;
