import React from 'react';
import { SvgTag, IconTag } from './style';
import SVGIconFragments from './SVGIcon';

export type IconName =
  | '2nd'
  | 'arrow'
  | 'arrow-alternative'
  | 'bag'
  | 'bank'
  | 'bitcoin'
  | 'bitcoin-cash'
  | 'bkai-coin'
  | 'block'
  | 'boleto'
  | 'calendar'
  | 'card'
  | 'cardano'
  | 'change'
  | 'check'
  | 'clock'
  | 'close'
  | 'coin'
  | 'copy'
  | 'disable'
  | 'download'
  | 'edit'
  | 'export'
  | 'ethereum'
  | 'expand'
  | 'expanded'
  | 'external-link'
  | 'fixed'
  | 'github'
  | 'graphic'
  | 'half'
  | 'help'
  | 'hide-bar'
  | 'hide'
  | 'home'
  | 'image'
  | 'info'
  | 'key'
  | 'linkedin'
  | 'list'
  | 'litecoin'
  | 'logout'
  | 'mail'
  | 'menu'
  | 'message'
  | 'more'
  | 'notification'
  | 'ordenation'
  | 'origin'
  | 'phone'
  | 'pin'
  | 'pix'
  | 'qrcode'
  | 'receive'
  | 'receipt-error'
  | 'receipt-sucess'
  | 'receipt-pending'
  | 'ripple'
  | 'scan'
  | 'search'
  | 'send'
  | 'settings'
  | 'share'
  | 'show-bar'
  | 'store'
  | 'tether'
  | 'time'
  | 'token'
  | 'trash'
  | 'unblock'
  | 'upload'
  | 'user'
  | 'validation'
  | 'variation-down'
  | 'variation-up'
  | 'view'
  | 'wallet'
  | 'work'
  | 'world'
  | 'half'
  | 'braex-2nd'
  | 'flag-trans'
  | 'document-front'
  | 'document-back'
  | 'validating-document'
  | 'not-approved'
  | 'party-emoji'
  | 'logo-2nd'
  | 'wallet'
  | 'code-phone';

export enum IconFormat {
  CSS = 'css',
  SVG = 'svg',
}

export type IconProps = {
  name: IconName | string;
  color?: string;
  size?: number;
  title?: string;
  rotate?: string;
  format?: IconFormat;
  viewBox?: string;
  className?: string;
};

const Icon: React.FC<IconProps> = ({
  name,
  color,
  size = 20,
  title,
  rotate,
  format = IconFormat.CSS,
  viewBox,
  className,
}) => {
  const transform = rotate ? `rotate(${rotate})` : '';

  if (format == IconFormat.SVG) {
    return (
      <SvgTag
        fill="none"
        width={size}
        height={size}
        color={color}
        data-testid="icon"
        transform={transform}
        className={`icon-${name}`}
        viewBox={viewBox ? viewBox : `0 0 ${size} ${size}`}
      >
        {SVGIconFragments[name]}
      </SvgTag>
    );
  }

  return (
    <IconTag
      color={color}
      title={title}
      data-testid="icon"
      transform={transform}
      className={`icon-${name} ${className}`}
      style={{ fontSize: size }}
    />
  );
};

export default Icon;
