import { useState } from 'react';

import AmountStep from './AmountStep';
import ReceiverStep from './ReceiverStep';
import ReviewStep from './ReviewStep';
import PasswordStep from './PasswordStep';

interface Props {
  cryptoName: string;
}

const CryptoTransferP2p: React.FC<Props> = ({ cryptoName }) => {
  const [currentStep, setCurrentStep] = useState('step1');
  const [transferAmount, setTransferAmount] =
    useState<string>('');
  const [destinationTarget, setDestinationTarget] =
    useState(null);
  const [targetName, setTargetName] = useState('');
  const [favorite, setFavorite] = useState(false);
  const [transferData, setTransferData] = useState({});

  return (
    <>
      {'step1' == currentStep && (
        <AmountStep
          setStep={setCurrentStep}
          setTransferAmount={setTransferAmount}
          cryptoName={cryptoName}
        />
      )}

      {'step2' == currentStep && (
        <ReceiverStep
          setStep={setCurrentStep}
          setTarget={setDestinationTarget}
          setName={setTargetName}
          setFavorite={setFavorite}
          cryptoName={cryptoName}
        />
      )}
      {'step3' == currentStep && (
        <ReviewStep
          transferAmount={transferAmount}
          setStep={setCurrentStep}
          transferTarget={destinationTarget}
          targetName={targetName}
          favorite={favorite}
          setFavorite={setFavorite}
          setTransferData={setTransferData}
          cryptoName={cryptoName}
        />
      )}
      {'step4' == currentStep && (
        <PasswordStep
          setStep={setCurrentStep}
          transferData={transferData}
          cryptoName={cryptoName}
        />
      )}
    </>
  );
};
export default CryptoTransferP2p;
