import Targets from "../constants/Targets";

export const SubMenu = [
	{
		label: "Perfil",
		value: "perfil",
		path: Targets.BACKOFFICE_USERS_PROFILE,
	},
	{
		label: "Endereço",
		value: "endereco",
		path: Targets.BACKOFFICE_USERS_ADDRESS,
	},
	{
		label: "Documento",
		value: "documento",
		path: Targets.BACKOFFICE_USERS_DOCUMENT,
	},
	{
		label: "Telefone",
		value: "telefone",
		path: Targets.BACKOFFICE_USERS_PHONE,
	},
	{
		label: "Senha",
		value: "senha",
		path: Targets.BACKOFFICE_USERS_PASSWORD,
	},
	{
		label: "Carteira Fiat",
		value: "carteira-fiat",
		path: Targets.BACKOFFICE_USERS_WALLET_FIAT,
	},
	{
		label: "Carteira Bitcoin",
		value: "btc",
		path: Targets.BACKOFFICE_USERS_CRIPTO_BTC,
	},
	{
		label: "Carteira Bkai",
		value: "bkai",
		path: Targets.BACKOFFICE_USERS_CRIPTO_BKAI,
	},
];
