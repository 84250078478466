const enum LegalNature {
	LTDA = "LTDA",
	EIRELI = "EIRELI",
	EI = "EI",
	MEI = "MEI",
	ME = "ME",
	SS = "SS",
	SA = "SA",
	EPP = "EPP",
	COPERATIVA = "COPERATIVA",
	CONSORCIO = "CONSORCIO",
}

export default LegalNature;