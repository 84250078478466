import dayjs from 'dayjs';
import { dayjsUtc } from '@2ndmarket/helpers';
import { Icon, themes } from '@2ndmarket/components';
import { useTransactionDateInfo } from '@2ndmarket/services';

import { DrawerHeader } from './styles';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface Props {
  dateOpen: boolean;
  setDateOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  defaultDateText?: string;
}

const DateDrawer: React.FC<Props> = ({
  dateOpen,
  setDateOpen,
  selectedDate,
  setSelectedDate,
  defaultDateText,
}) => {
  const todayDay = dayjsUtc().toDate();
  const maxDate = dayjsUtc().add(90, 'day').toDate();
  const { data: holidays, loading } = useTransactionDateInfo();

  function convertSelectedDate(selectedDate: string) {
    const convertedDate = dayjsUtc(selectedDate, {
      format: 'DD/MM/YYYY',
    });
    const formatedDate = convertedDate.format('YYYY-MM-DD');
    return dayjsUtc(formatedDate);
  }

  function selectedDateVerify() {
    const convertedSelectedDate = dayjsUtc(
      selectedDate,
      'DD/MM/YYYY',
    )
      .format('YYYY-MM-DD')
      .toString();
    if (selectedDate != defaultDateText) {
      return dayjsUtc(convertedSelectedDate);
    } else {
      return null;
    }
  }

  function dateSelect(selectedDate: Date | null) {
    if (!selectedDate) {
      return null;
    }

    setSelectedDate(dayjsUtc(selectedDate).format('DD/MM/YYYY'));
    setDateOpen(false);
  }

  const shouldDisableDate = (date: Date) => {
    const thisDate = dayjsUtc(date).format('YYYY-MM-DD');
    const isHoliday = holidays.banking_info.holidays.some(
      day => day === thisDate,
    );

    const dayOfWeek = new Date(date).getDay();
    return isHoliday || dayOfWeek === 0 || dayOfWeek === 6;
  };

  return (
    <Drawer
      open={dateOpen}
      anchor="right"
      role="presentation"
      onClose={() => setDateOpen(false)}
      sx={{
        '.MuiPaper-root': {
          backgroundColor: 'grey.900',
        },
      }}
    >
      <DrawerHeader sx={{ pl: 3 }}>
        <Stack
          gap={6}
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
        >
          <IconButton onClick={() => setDateOpen(false)}>
            <Icon
              size={16}
              name="close"
              color={themes.bankai.palette.grey[300]}
            />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Agendamento
          </Typography>
        </Stack>
      </DrawerHeader>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        pb={4}
        sx={{ px: 4 }}
      >
        <Typography
          variant="h4"
          component="h4"
          color="text.primary"
          fontSize="16px"
        >
          {defaultDateText == selectedDate
            ? dayjsUtc().format('YYYY')
            : dayjsUtc(selectedDate, 'DD/MM/YYYY').format(
                'YYYY',
              )}
        </Typography>
        <Typography
          variant="h3"
          component="h3"
          color="primary.main"
          fontSize="24px"
          sx={{ textTransform: 'capitalize' }}
        >
          {defaultDateText == selectedDate
            ? dayjsUtc().format('D, MMMM')
            : dayjsUtc(selectedDate, 'DD/MM/YYYY').format(
                'D, MMMM',
              )}
        </Typography>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              disablePast
              //@ts-ignore
              value={selectedDateVerify()}
              shouldDisableDate={shouldDisableDate}
              onChange={selectedDate => dateSelect(selectedDate)}
              sx={{
                width: '100%',
                maxHeight: '344px',
                height: '344px',
                '.MuiPickersCalendarHeader-root': {
                  px: 0,
                },
                '.MuiPickersSlideTransition-root': {
                  minHeight: '250px',
                },
                '.MuiTypography-root': {
                  width: '46px',
                  height: '46px',
                },
                '.MuiPickersDay-root': {
                  width: '46px',
                  height: '46px',
                  '&.MuiPickersDay-today': {
                    borderColor: 'grey.300',
                  },
                  '&:disabled': {
                    color: 'text.disabled',
                  },
                },
              }}
            />
          </LocalizationProvider>
        )}
      </Box>
    </Drawer>
  );
};
export default DateDrawer;
