import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
import { typography } from '../themes';

interface BtnProps {
  selected: boolean;
}

interface BoxIconProps {
  bg: string;
}

interface BoxProps {
  active: boolean;
}

export const Content = styled.div`
  align-items: center;
  background-color: ${theme('colors.primary.main')};
  display: flex;
  gap: 30px;
  height: 80px;
  justify-content: center;
  min-height: 80px;
  padding: 0 30px;
  width: 100%;
`;

export const BtnChange = styled.button`
  align-items: center;
  background-color: ${theme('colors.primary.dark')};
  border-radius: 50%;
  color: ${theme('colors.white')};
  display: flex;
  height: 30px;
  justify-content: center;
  padding-top: 4px;
  width: 30px;
  ${typography.body1}
`;

export const Btn = styled.button<BtnProps>`
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? theme('colors.secondary.4') : 'transparent'};
  color: ${theme('colors.secondary.1')};
  display: flex;
  gap: 10px;
  height: 70px;
  justify-content: space-between;
  padding: 10px 30px;
  width: 100%;
  ${typography.body2};

  & > div {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  & > i {
    color: ${theme('colors.primary.main')};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;

    & > div {
      div {
        background-color: ${theme('colors.secondary.3')};
      }
    }
  }
`;

export const BoxIcon = styled.div<BoxIconProps>`
  align-items: center;
  background-color: ${({ bg }) => bg};
  border-radius: 50%;
  color: ${theme('colors.white')};
  display: flex;
  font-size: 20px;
  height: 50px;
  justify-content: center;
  width: 50px;
`;

export const ContentMobile = styled.div`
  align-items: center;
  background-color: ${theme('colors.primary.main')};
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 0 30px;
  width: 100%;

  button {
    background-color: transparent;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 26px;
  }
`;

export const Opened = styled.div`
  background-color: ${theme('colors.white')};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

export const Head = styled.header`
  background-color: ${theme('colors.primary.main')};
  border-radius: 0px 0px 24px 24px;
  padding: 25px 30px 40px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  button:first-of-type {
    transform: rotate(180deg);
  }

  button {
    background-color: transparent;
    color: ${theme('colors.white')};
  }
`;

export const Infos = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    color: ${theme('colors.white')};

    & + p {
      color: ${theme('colors.primary.light')};
    }
  }
`;

export const ContentModal = styled.div`
  max-width: 405px;

  & > h2 {
    color: ${theme('colors.secondary.1')};
  }

  & > p {
    color: ${theme('colors.secondary.2')};
  }
`;

export const BoxPanel = styled.div`
  flex: 1;
  padding: 25px 30px;
`;

export const Footer = styled.div`
  padding: 25px 30px;

  p {
    color: ${theme('colors.secondary.3')};
  }

  a {
    text-decoration: underline;
    ${typography.caption}
  }

  & > div + div {
    display: flex;
    gap: 40px;
    margin-top: 25px;

    a {
      color: ${theme('colors.secondary.2')};
    }
  }
`;

export const MenuMobile = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: ${theme('colors.modalBorder')};
  z-index: 3;

  .headerMenu {
    width: 100%;
    background: ${theme('colors.white')};;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
    padding: 22px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: transparent;
      transform: rotate(180deg);
    }

    h1 {
      font-weight: 600;
      font-size: 16px;
      color: ${theme('colors.secondary.1')};
    }
  }

  .contentLinks {
    display: flex;
    flex-direction: column;
    margin: 50px 30px;
  }
`;

export const Box = styled.div<BoxProps>`
  border-bottom: 1px solid ${theme('colors.secondary.4')};
  max-height: 55px;
  overflow: hidden;
  border-right: 1px solid transparent;
  & + & {
    margin-top: -1px;
    @media only screen and (max-width: ${theme('breakpoints.mobileBackoffice')}) {
      border-top: 1px solid ${theme('colors.secondary.4')};
    }
  }

  ${({ active }) =>
    active &&
    css`
      border-right: 1px solid ${theme('colors.primary.main')};
      max-height: 1000px;
      @media only screen and (max-width: ${theme(
          'breakpoints.mobileBackoffice',
        )}) {
        border: none;
      }
    `}
`;

const linkMobile = css<BoxProps>`
  background-color: transparent;
  color: ${theme('colors.secondary.1')};
  font-weight: 500;
  font-size: 14px;

  ${({ active }) =>
    active &&
    css`
      i,
      span {
        color: ${theme('colors.primary.main')};
      }
    `}
`;

export const BtnMobile = styled.a`
  ${linkMobile}
  padding: 18px 0;
  justify-content: space-between;
  display: flex;

  div {
    display: flex;
    gap: 28px;
  }
`;