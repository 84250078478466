const default2nd = {
  name: '2nd',
  nextjs: true,
  breakpoints: {
    mobile: '1200px',
    tablet: '1640px',
    mobileAuthentication: '750px',
    mobileBackoffice: '1149px',
    desktopBackoffice: '1150px',

    values: {
      xs: '599px',
      sm: '904px',
      md: '1439px',
      lg: '1440px',
      xl: '1920px',
    },
  },
  fontFamily: ['Montserrat, Arial, Helvetica, sans-serif'],
  space: [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
    80, 85, 90, 95, 100,
  ],
  colors: {
    primary: {
      main: '#313EE2',
      dark: '#0E0CA8',
      medium: '#C9CBF8',
      light: '#EAEBFD',
      white: '#FDFDFF',
    },
    secondary: {
      1: '#292D37',
      2: '#535668',
      3: '#B4B9C7',
      4: '#E5E9EE',
      5: '#5A6473',
      6: '#BBC2CB',
      7: '#EFF1F7',
      8: '#8C96A0',
      9: '#BBC2CB',
    },
    white: '#ffffff',
    black: '#000000',
    success: {
      main: '#4BD281',
      bg: '#E9F7F3',
    },
    error: {
      main: '#DC3545',
      bg: '#F8ECEE',
    },
    alert: {
      main: '#F7931A',
      bg: '#F9F2EA',
    },
    background: '#FDFDFF',
    blur: '0px 10px 25px rgba(65, 80, 230, 0.2)',
    opacity: {
      1: 'rgb(255, 255, 255, 0.1)',
      2: 'rgb(255, 255, 255, 0.7)',
    },
    modalBorder: '#FAFAFA',
    status: {
      active: '#4150E6',
      nokyc: '#DC3545',
      analising: '#F7AC1A',
      locked: '#979CC8',
      deactivated: '#C9D1DA',
    },
    products: {
      bankai: '#E71346',
      braex: '#7E3FF2',
      custon: '#FC9C44',
      idNetwork: '#ED710F',
      tokenaim: '#46DCAF',
      dashboard: '#313EE2',
    },
    modal: {
      warnings: {
        title: '#292D37',
        paragraph: '#535668',
        span: '#313EE2',
        check: '#B4B9C7',
        button: '#EFF1F6',
      },
    },
  },
};

export default default2nd;
