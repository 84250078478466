import { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountType,
  AccountTypes,
  HttpError,
  Masks,
  OperationType,
  dayjsUtc,
  formatBRL,
  yupPt,
} from '@2ndmarket/helpers';
import {
  BankDrawer,
  DateDrawer,
  Icon,
  MaskedInput,
} from '@2ndmarket/components';
import {
  BanksEmpty,
  IBanksResult,
  IBusinessHours,
} from '@2ndmarket/types';
import { ContentTitle } from '../../../../components';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { useBusinessHours } from '@2ndmarket/services';
import { IReviewData, ITransferData } from './Types';

interface Props {
  transferAmount: any;
  transferTarget: any;
  setStep: any;
  targetName: any;
  favorite: boolean;
  setTransferData: React.Dispatch<
    React.SetStateAction<ITransferData>
  >;
  reviewData: IReviewData;
  setReviewData: React.Dispatch<
    React.SetStateAction<IReviewData>
  >;
  fieldsErrors: HttpError | undefined;
  setFieldsErrors: React.Dispatch<
    React.SetStateAction<HttpError | undefined>
  >;
}

const steps = [
  'Início',
  'TED',
  'Valor',
  'Destinatário',
  'Revisão',
];

const ReviewStep: React.FC<Props> = ({
  transferAmount,
  transferTarget,
  setStep,
  targetName,
  favorite,
  setTransferData,
  reviewData,
  setReviewData,
  fieldsErrors,
  setFieldsErrors,
}) => {
  const defaultDateText = 'Agora';
  const [dateOpen, setDateOpen] = useState(false);
  const [selectedDate, setSelectedDate] =
    useState(defaultDateText);
  const [bankOpen, setBankOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState<IBanksResult>(
    BanksEmpty.results[0],
  );
  const {
    data: businessHours,
    loading: businessHoursLoading,
    loading: businessHoursError,
  } = useBusinessHours();

  const schema = yupPt.object().shape({
    recipient: yupPt.string().required(),
    bank_code: yupPt.string().required('Selecione um banco.'),
    branch: yupPt.string().required(),
    account: yupPt.string().required(),
  });

  const defaultValues = {
    cpf_cnpj: transferTarget,
    recipient: reviewData.recipient ?? targetName,
    bank: reviewData.bank ?? '',
    bank_code: reviewData.bank_code ?? '',
    account_type: reviewData.account_type ?? 'CHECKING_ACCOUNT',
    branch: reviewData.branch ?? '',
    account: reviewData.account ?? '',
    schedule_date: reviewData.schedule_date ?? defaultDateText,
    favorite: reviewData.favorite ?? favorite,
    amount: transferAmount,
  };

  const {
    handleSubmit,
    register,
    setValue,
    control,
    setError,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    setValue('schedule_date', selectedDate);
  }, [selectedDate, setValue]);

  const favoriteWatcher = useWatch({
    control,
    name: 'favorite',
  });

  const bankWatcher = useWatch({ control, name: 'bank' });

  useEffect(() => {
    if (selectedBank.id != 0) {
      setValue('bank', selectedBank.name);
      setValue('bank_code', selectedBank.code);
    }
  }, [selectedBank, setValue]);

  useEffect(() => {
    if (fieldsErrors) {
      for (let fieldError of fieldsErrors.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    }
  }, [fieldsErrors, setError]);

  function onSubmit(data: ITransferData) {
    setReviewData({});
    if (defaultDateText == data.schedule_date) {
      data.schedule_date = null;
    }
    data.cpf_cnpj = data.cpf_cnpj.replace(/[^\d]/g, '');
    setTransferData(data);
    setFieldsErrors(undefined);
    setStep('step4');
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <ContentTitle title="Transferir" steps={steps} />
      {!businessHoursLoading &&
        !businessHoursError &&
        businessHours.map(
          (item: IBusinessHours, key: number) => {
            if (item.payment_method == OperationType.TED) {
              return (
                <Alert
                  key={key}
                  variant="outlined"
                  severity="warning"
                  icon={<Icon name="clock" size={18} />}
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'warning.contrastText',
                    display: 'inline-flex',
                    color: 'warning.main',
                    py: 0,
                    mt: -1,
                    mb: 2,
                  }}
                >
                  Disponível das{' '}
                  {dayjsUtc(item.start, 'HH:mm:ss').format(
                    'H[h]m',
                  )}{' '}
                  às{' '}
                  {dayjsUtc(item.end, 'HH:mm:ss').format(
                    'H[h]m',
                  )}
                </Alert>
              );
            }
          },
        )}
      <Typography
        component="p"
        sx={{ fontWeight: '500', color: 'text.secondary' }}
      >
        Transferindo
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        color="primary.main"
      >
        {formatBRL(transferAmount)}
      </Typography>
      <Box
        sx={{
          mt: 3,
          mb: 5,
          display: 'grid',
          gap: 3,
          alignItems: 'start',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
          },
          '& .MuiTextField-root': {
            margin: 0,
          },
        }}
      >
        <TextField
          {...register('cpf_cnpj', { required: true })}
          sx={{ marginBottom: 5 }}
          disabled={true}
          fullWidth
          label="CPF/CNPJ"
          error={Boolean(errors.cpf_cnpj)}
          helperText={errors.cpf_cnpj && errors.cpf_cnpj.message}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              unmask: true,
              mask:
                transferTarget.replace(/\D/g, '').length < 12
                  ? Masks.CPF
                  : Masks.CNPJ,
              inputMode: 'numeric',
            },
          }}
        />
        <TextField
          {...register('recipient', { required: true })}
          sx={{ marginBottom: 5 }}
          disabled={targetName}
          fullWidth
          label="Destinatário"
          error={Boolean(errors.recipient)}
          helperText={
            errors.recipient && errors.recipient.message
          }
          inputProps={{ maxLength: 120 }}
        />
        <TextField
          label="Banco"
          fullWidth
          margin="dense"
          variant="outlined"
          {...register('bank')}
          onClick={() => setBankOpen(true)}
          error={Boolean(errors.bank_code)}
          helperText={
            errors.bank_code && errors.bank_code.message
          }
          InputProps={{
            inputProps: { readOnly: true },
            endAdornment: (
              <InputAdornment position="end">
                <Icon name="arrow" size={12} rotate="-90deg" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: bankWatcher ? true : false,
          }}
          sx={{ input: { cursor: 'pointer' } }}
        />
        <TextField
          select
          fullWidth
          margin="dense"
          variant="outlined"
          label="Tipo de cadastro"
          defaultValue={AccountType.CHECKING_ACCOUNT}
          {...register('account_type', { required: true })}
        >
          {AccountTypes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          {...register('branch', { required: true })}
          sx={{ marginBottom: 5 }}
          fullWidth
          label="Agência (sem dígito)"
          error={Boolean(errors.branch)}
          helperText={errors.branch && errors.branch.message}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              unmask: true,
              mask: Masks.AGENCY,
              inputMode: 'numeric',
            },
          }}
        />
        <TextField
          {...register('account', { required: true })}
          sx={{ marginBottom: 5 }}
          fullWidth
          label="Conta (com dígito)"
          error={Boolean(errors.account)}
          helperText={errors.account && errors.account.message}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              unmask: true,
              mask: Masks.BRANCH,
              inputMode: 'numeric',
            },
          }}
        />
        <TextField
          {...register('schedule_date', { required: true })}
          onClick={() => setDateOpen(true)}
          sx={{ marginBottom: 5, input: { cursor: 'pointer' } }}
          fullWidth
          label="Quando"
          error={Boolean(errors.schedule_date)}
          helperText={
            errors.schedule_date && errors.schedule_date.message
          }
          InputProps={{
            inputProps: { readOnly: true },
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ '& i': { color: 'text.disabled' } }}
              >
                <Icon name="calendar" size={16} />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={favoriteWatcher}
              {...register('favorite')}
              color="primary"
            />
          }
          label="Contato favorito"
          labelPlacement="start"
          sx={{
            justifyContent: 'space-between',
            height: '56px',
            color: 'text.secondary',
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mb: 4,
          py: 2,
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'grey.200',
        }}
      >
        <Typography
          component="p"
          sx={{ color: 'text.secondary' }}
        >
          Tarifa
        </Typography>
        <Typography component="p" sx={{ color: 'primary.main' }}>
          Gratuita
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => setStep('step2')}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
      <DateDrawer
        dateOpen={dateOpen}
        setDateOpen={setDateOpen}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        defaultDateText={defaultDateText}
      />
      <BankDrawer
        bankOpen={bankOpen}
        setBankOpen={setBankOpen}
        selectedBank={selectedBank}
        setSelectedBank={setSelectedBank}
      />
    </Box>
  );
};
export default ReviewStep;
