import { useState } from 'react';
import { useMe, useWallet } from '@2ndmarket/services';
import { Icon, themes } from '@2ndmarket/components';
import {
  OnboardingProgress,
  formatCpfCnpj,
} from '@2ndmarket/helpers';
import { DrawerHeader } from './styles';
import ListProducts from '../ListProducts';
import Products from '../../helpers/products';
import CustomerData from './CustomerData';

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const DrawerProfile: React.FC = () => {
  const { data: me } = useMe();
  const {
    data: wallet,
    loading: walletLoading,
    error: walletError,
  } = useWallet();
  const [open, setOpen] = useState(false);
  const [openAccountData, setOpenAccountData] = useState(false);

  function copyAllData() {
    navigator.clipboard.writeText(
      'Esta conta só recebe depósitos do CPF: ' +
        formatCpfCnpj(me.cpf_cnpj) +
        '\nAgência: ' +
        wallet.bank_branch +
        '\nConta: ' +
        wallet.bank_account_number +
        '\nCPF: ' +
        me.cpf_cnpj +
        '\nInstituição: ' +
        wallet.bank_name +
        ' ' +
        wallet.bank_code,
    );
  }

  const toogleDrawerProfile = (open: boolean) => () => {
    setOpen(open);
  };

  const getInitials = (name: any): string => {
    let initials: any = '';
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    initials = [...name.matchAll(rgx)] || [];
    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials;
  };

  const logout = () => {
    return (window.location.href =
      process.env.NEXT_PUBLIC_LOGIN_URL + '/logout');
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        onClick={toogleDrawerProfile(true)}
        sx={{
          cursor: 'pointer',
          '&:hover .MuiAvatar-root': {
            bgcolor: 'common.white',
            color: 'primary.main',
            transition:
              'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        }}
      >
        <Avatar
          sx={{
            width: 42,
            height: 42,
            cursor: 'pointer',
            marginLeft: '0',
            bgcolor: themes.bankai.palette.primary.dark,
          }}
        >
          <Typography variant="body1">
            {getInitials(me.name)}
          </Typography>
        </Avatar>
        <Typography
          component="p"
          variant="body1"
          sx={{
            marginLeft: 1,
            color: themes.bankai.palette.common.white,
          }}
          onClick={toogleDrawerProfile(true)}
        >
          Olá, {me.name}
        </Typography>
      </Box>
      <Drawer
        open={open}
        anchor="right"
        role="presentation"
        onClose={toogleDrawerProfile(false)}
        sx={{
          '.MuiDrawer-paper': {
            backgroundColor: themes.bankai.palette.grey[400],
          },
        }}
      >
        <DrawerHeader
          sx={{
            pl: 3,
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 490,
            },
          }}
        >
          <Stack
            gap={6}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="close"
                color={themes.bankai.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={{ width: '100%', textAlign: 'center' }}
            >
              Perfil
            </Typography>
          </Stack>
        </DrawerHeader>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
          paddingX={4}
          mb={4}
          sx={{
            width: {
              xs: 315,
              sm: 415,
              md: 415,
              lg: 490,
            },
          }}
        >
          <Box
            onClick={() => setOpenAccountData(true)}
            bgcolor="common.white"
            width="100%"
            mb={1}
            padding={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="12px"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                transition:
                  'box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                boxShadow:
                  '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
              },
            }}
          >
            <Box display="flex" alignItems="center" gap="12px">
              <Avatar
                sx={{
                  bgcolor: themes.bankai.palette.primary.main,
                  width: 48,
                  height: 48,
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="commom.white"
                >
                  {getInitials(me.name)}
                </Typography>
              </Avatar>
              <Box>
                <Typography component="p" variant="subtitle1">
                  {me.name}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  color={themes.bankai.palette.grey[300]}
                >
                  {formatCpfCnpj(me.cpf_cnpj)}
                </Typography>
              </Box>
            </Box>
            <Icon
              size={12}
              name="arrow"
              rotate="-90deg"
              color={themes.bankai.palette.primary.main}
            />
          </Box>
          <Button
            onClick={() => copyAllData()}
            variant="text"
            sx={{
              bgcolor: 'common.white',
              width: '100%',
              padding: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '12px',
              '&:hover': {
                bgcolor: 'common.white',
                boxShadow:
                  '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
                i: {
                  color: 'primary.main',
                },
              },
            }}
          >
            {!walletLoading && !walletError && (
              <Box
                display="flex"
                gap="12px"
                sx={{
                  '.MuiTypography-root': {
                    fontSize: '14px',
                    color: 'text.primary',
                  },
                  'span.MuiTypography-root': {
                    color: 'grey.300',
                  },
                }}
              >
                <Typography component="p" variant="body1">
                  <Typography component="span">Banco</Typography>{' '}
                  {wallet.bank_code}
                </Typography>
                <Typography component="p" variant="body1">
                  <Typography component="span">Ag.</Typography>
                  {wallet.bank_branch}
                </Typography>
                <Typography component="p" variant="body1">
                  <Typography component="span">Conta</Typography>{' '}
                  {wallet.bank_account_number}
                </Typography>
              </Box>
            )}
            <Icon
              size={20}
              name="copy"
              color={themes.bankai.palette.grey[300]}
            />
          </Button>
          {me.onboarding_progress ==
            OnboardingProgress.DIGITAL && (
            <Alert
              variant="outlined"
              severity="warning"
              icon={<Icon name="info" size={18} />}
              sx={{
                alignItems: 'center',
                bgcolor: 'warning.contrastText',
                mt: 1,
              }}
            >
              No momento, você só pode receber depósitos de mesma
              titularidade.
            </Alert>
          )}
        </Box>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          sx={{
            borderRadius: '6px 6px 0 0',
            backgroundColor: 'common.white',
          }}
        >
          <Typography
            component="p"
            variant="body1"
            sx={{ paddingX: 4, pt: 4, pb: 2 }}
            color={themes.bankai.palette.grey[300]}
          >
            Painel
          </Typography>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <ListProducts
              products={Products}
              selectedColor={themes.bankai.palette.grey[400]}
            />
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={() => logout()}
              sx={{
                marginX: 3,
                marginBottom: 4,
                boxShadow: 'none',
                width: 'calc(100% - 48px)',
              }}
            >
              Sair
            </Button>
          </Box>
        </Box>
      </Drawer>
      <CustomerData
        open={openAccountData}
        setOpen={setOpenAccountData}
      />
    </>
  );
};

export default DrawerProfile;
