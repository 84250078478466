import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AppConstants, IntentStatus } from '@2ndmarket/helpers';
import { useIntent, useBalance } from '@2ndmarket/services';

interface TransferData {
  id: number | any;
  type: string | any;
  register: boolean | any;
}

interface TransferAwaitProps {
  data: TransferData | null;
  redirect?: any;
  stepRedirect?: React.Dispatch<React.SetStateAction<string>>;
  setIntentStatus: React.Dispatch<
    React.SetStateAction<IntentStatus>
  >;
  setIntentDescription: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setTransactionId?: React.Dispatch<
    React.SetStateAction<string>
  >;
  setBillId?: React.Dispatch<React.SetStateAction<number>>;
  setCashinId?: React.Dispatch<React.SetStateAction<number>>;
}

const TransferAwait: React.FC<TransferAwaitProps> = ({
  data,
  redirect,
  stepRedirect,
  setIntentStatus,
  setIntentDescription,
  setTransactionId,
  setBillId,
  setCashinId,
}) => {
  const router = useRouter();
  const { mutate: mutateBalance } = useBalance();
  const [refreshInterval, setRefreshInterval] = useState(1000);
  const { intent } = useIntent(
    AppConstants.ME,
    data?.id,
    data?.type,
    data?.register,
    refreshInterval,
  );

  const [counterStart, setCounterStart] = useState(false);
  const [attempsCounter, setAttempsCounter] = useState(0);
  const attempsLimit = 6;

  useEffect(() => {
    if (counterStart) {
      const intervalId = setInterval(() => {
        setAttempsCounter(prevCounter => prevCounter + 1);

        if (attempsCounter >= attempsLimit) {
          clearInterval(intervalId);
          setIntentStatus(IntentStatus.PENDING);
          setRefreshInterval(0);
          setAttempsCounter(0);
          setCounterStart(false);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [counterStart, setIntentStatus, attempsCounter]);

  useEffect(() => {
    if (
      !counterStart &&
      intent?.intent_status === IntentStatus.NONE
    ) {
      setCounterStart(true);
    }
    if (intent?.intent_status === IntentStatus.FAILURE) {
      setRefreshInterval(0);
      setIntentDescription(intent?.description);
      setIntentStatus(IntentStatus.FAILURE);
    }

    if (intent?.intent_status === IntentStatus.DONE) {
      setRefreshInterval(0);
      mutateBalance();

      if (setBillId) {
        if (data?.type === 'FIATBILLCASHIN' && setCashinId) {
          setCashinId(intent?.cashin_register_id);
        }

        if (data?.type === 'BILL') {
          setBillId(intent?.register_id);
        }

        setBillId(intent?.register_id);
        if (stepRedirect) {
          stepRedirect(redirect);
        }
      } else {
        if (setTransactionId) {
          setTransactionId(intent.transaction_uuid);
        }
        setIntentStatus(IntentStatus.DONE);
      }
    }

    if (localStorage.getItem('@locationHeader')) {
      localStorage.removeItem('@locationHeader');
    }
  }, [
    intent,
    setBillId,
    router,
    redirect,
    data,
    mutateBalance,
    setCashinId,
    setIntentStatus,
    setTransactionId,
    stepRedirect,
    setIntentDescription,
    counterStart,
  ]);

  return <></>;
};

export default TransferAwait;
