import { useState } from 'react';

import AmountStep from './AmountStep';
import ReceiverStep from './ReceiverStep';
import ReviewStep from './ReviewStep';
import PasswordStep from './PasswordStep';
import { ReviewDataEmpty, TransferDataEmpty } from './Types';
import { HttpError } from '@2ndmarket/helpers';

const TransferBankai: React.FC = () => {
  const [currentStep, setCurrentStep] = useState('step1');
  const [transferAmount, setTransferAmount] = useState(0);
  const [destinationTarget, setDestinationTarget] =
    useState(null);
  const [targetName, setTargetName] = useState('');
  const [favorite, setFavorite] = useState(false);
  const [transferData, setTransferData] = useState(
    TransferDataEmpty,
  );
  const [reviewData, setReviewData] = useState(ReviewDataEmpty);
  const [fieldsErrors, setFieldsErrors] = useState<
    HttpError | undefined
  >();

  return (
    <>
      {'step1' == currentStep && (
        <AmountStep
          setStep={setCurrentStep}
          setTransferAmount={setTransferAmount}
          setReviewData={setReviewData}
        />
      )}

      {'step2' == currentStep && (
        <ReceiverStep
          setStep={setCurrentStep}
          setTarget={setDestinationTarget}
          setName={setTargetName}
          setFavorite={setFavorite}
        />
      )}
      {'step3' == currentStep && (
        <ReviewStep
          transferAmount={transferAmount}
          setStep={setCurrentStep}
          transferTarget={destinationTarget}
          targetName={targetName}
          favorite={favorite}
          setTransferData={setTransferData}
          reviewData={reviewData}
          setReviewData={setReviewData}
          fieldsErrors={fieldsErrors}
          setFieldsErrors={setFieldsErrors}
        />
      )}
      {'step4' == currentStep && (
        <PasswordStep
          setStep={setCurrentStep}
          transferData={transferData}
          setReviewData={setReviewData}
          setFieldsErrors={setFieldsErrors}
        />
      )}
    </>
  );
};
export default TransferBankai;
