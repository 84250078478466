import {
  OnboardingProgress,
  ReviewStep,
} from '@2ndmarket/helpers';

export interface IOnboardingBasic {
  status: ReviewStep;
  status_message: ReviewStep;
  profile_step: ReviewStep;
  address_step: ReviewStep;
  document_step: ReviewStep;
  pin_step: ReviewStep;
  phone_step: ReviewStep;
}

export interface OnboardingBasicStep {
  status_message: string;
}

export interface IUserUpdate {
  cpf_cnpj: string;
  email: string;
  name: string;
  person_type: string;
  status_message: string;
  status: ReviewStep;
}

export interface IUser {
  id: number;
  name: string;
  cpf_cnpj: string;
  email: string;
  person_type: string;
  status: string;
  onboarding_progress: OnboardingProgress;
  onboarding_basic: IOnboardingBasic;
  onboarding_digital_account: {
    status: string;
    updated_at: string;
    status_message: string;
  };
  onboarding_bank_account: {
    status: string;
    status_message: string;
    profile_step: string;
    selfie_step: string;
    document_step: string;
  };
  updated_at: string;
  created_at: string;
  status_message: string;
  banking_plan: {
    name: string,
    description: string,
    day_start: string | Date,
    night_start: string | Date,
    bill_cashout_limit: number,
    ted_cashout_limit: number,
    btc_cashout_limit_day: number,
    btc_cashout_limit_night: number,
    btc_sell_limit: number,
    btc_buy_limit: number,
    bkai_sell_limit: number,
    bkai_buy_limit: number,
    btc_bill_cashout_absolute_fee: number,
    btc_bill_cashout_relative_fee: number,
    btc_cashout_absolute_fee: number,
    btc_cashout_relative_fee: number,
    bkai_cashout_absolute_fee: number,
    bkai_cashout_relative_fee: number,
    btc_sell_absolute_fee: number,
    btc_sell_relative_fee: number,
    btc_buy_absolute_fee: number,
    btc_buy_relative_fee: number,
    bkai_sell_absolute_fee: number,
    bkai_sell_relative_fee: number,
    bkai_buy_relative_fee: number,
    bkai_buy_absolute_fee: number,
    relative_fee_scale: number,
  }
}

export interface UserIdProps {
  user_id: number;
}

export const UserEmpty: IUser = {
  id: 0,
  name: '',
  cpf_cnpj: '',
  email: '',
  person_type: '',
  status: '',
  onboarding_progress: OnboardingProgress.NEWUSER,
  onboarding_basic: {
    status: ReviewStep.NONE,
    status_message: ReviewStep.NONE,
    profile_step: ReviewStep.NONE,
    address_step: ReviewStep.NONE,
    document_step: ReviewStep.NONE,
    pin_step: ReviewStep.NONE,
    phone_step: ReviewStep.NONE,
  },
  onboarding_digital_account: {
    status: '',
    updated_at: '',
    status_message: '',
  },
  onboarding_bank_account: {
    status: '',
    status_message: '',
    profile_step: '',
    selfie_step: '',
    document_step: '',
  },
  updated_at: '',
  created_at: '',
  status_message: '',
  banking_plan: {
    name: '',
    description: '',
    day_start: "",
    night_start: "",
    bill_cashout_limit: 0,
    ted_cashout_limit: 0,
    btc_cashout_limit_day: 0,
    btc_cashout_limit_night: 0,
    btc_sell_limit: 0,
    btc_buy_limit: 0,
    bkai_sell_limit: 0,
    bkai_buy_limit: 0,
    btc_bill_cashout_absolute_fee: 0,
    btc_bill_cashout_relative_fee: 0,
    btc_cashout_absolute_fee: 0,
    btc_cashout_relative_fee: 0,
    bkai_cashout_absolute_fee: 0,
    bkai_cashout_relative_fee: 0,
    btc_sell_absolute_fee: 0,
    btc_sell_relative_fee: 0,
    btc_buy_absolute_fee: 0,
    btc_buy_relative_fee: 0,
    bkai_sell_absolute_fee: 0,
    bkai_sell_relative_fee: 0,
    bkai_buy_relative_fee: 0,
    bkai_buy_absolute_fee: 0,
    relative_fee_scale: 0,
  },
};
