import { Icon, themes } from '@2ndmarket/components';
import { useEffect, useState } from 'react';
import { DrawerHeader } from './styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MonthCalendar } from '@mui/x-date-pickers/MonthCalendar';
import { dayjsUtc, months } from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedYear: string;
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
  selectedMonth: string;
  setSelectedMonth: React.Dispatch<React.SetStateAction<string>>;
}

const StatementFilter: React.FC<Props> = ({
  open,
  setOpen,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
}) => {
  const startDate = '02-01-2021';
  const today = new Date();
  const startYear = 2021;
  const currentYear = new Date().getFullYear();
  const tomorrow = today.setDate(today.getDate() + 1);
  const [yearList, setYearList] = useState<number[]>([]);

  function monthSelect(data: any) {
    const month = dayjsUtc(data).get('month') + 1;
    setSelectedMonth(month.toString());
    setOpen(false);
  }

  useEffect(() => {
    const limit = 3;
    let counter = 0;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      counter++;
      if (counter <= limit || year <= Number(selectedYear)) {
        years.push(year);
      }
    }

    setYearList(years.slice(-limit));
  }, [setYearList, selectedYear, startYear, currentYear]);

  return (
    <Drawer
      open={open}
      anchor="right"
      role="presentation"
      onClose={() => setOpen(false)}
      sx={{
        '.MuiPaper-root': {
          backgroundColor: themes.bankai.palette.grey[900],
        },
      }}
    >
      <DrawerHeader
        sx={{
          pl: 3,
          width: {
            xs: 315,
            sm: 415,
            md: 415,
            lg: 415,
          },
        }}
      >
        <Stack
          gap={6}
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
        >
          <IconButton onClick={() => setOpen(false)}>
            <Icon
              size={15}
              name="arrow-alternative"
              rotate="180deg"
              color={themes.bankai.palette.grey[300]}
            />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            Filtrar
          </Typography>
        </Stack>
      </DrawerHeader>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        pb={4}
        sx={{
          px: 4,
          width: {
            xs: 315,
            sm: 415,
            md: 415,
            lg: 415,
          },
        }}
      >
        <Typography
          variant="h4"
          component="h4"
          color="text.primary"
          fontSize="16px"
        >
          {selectedYear}
        </Typography>
        <Typography
          variant="h3"
          component="h3"
          color="primary.main"
          fontSize="24px"
        >
          {months.map(item => {
            if (item.alternativeIndex == Number(selectedMonth)) {
              return item.value;
            }
          })}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            borderTop: 1,
            borderBottom: 1,
            borderColor: 'grey.200',
            mt: 3,
            mb: 4,
          }}
        >
          <List
            sx={{
              p: 0,
              '.MuiButtonBase-root': {
                py: '13px',
                px: 2,
                color: 'text.disabled',
                position: 'relative',
                '&.Mui-selected': {
                  bgcolor: 'common.white',
                  color: 'primary.main',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    bottom: '-1px',
                    width: '100%',
                    height: '1px',
                    bgcolor: 'primary.main',
                  },
                },
              },
            }}
          >
            {yearList.map(year => (
              <ListItem disablePadding key={year}>
                <ListItemButton
                  selected={year == Number(selectedYear)}
                  onClick={() =>
                    setSelectedYear(year.toString())
                  }
                >
                  {year}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              '.MuiButtonBase-root': {
                py: '18px',
                px: '10px',
                borderRadius: 0,
                '& i': {
                  color: 'text.primary',
                },
                '&.Mui-disabled': {
                  '& i': {
                    color: 'text.disabled',
                  },
                },
              },
            }}
          >
            <IconButton
              disabled={Number(selectedYear) == startYear}
              onClick={() =>
                setSelectedYear(
                  (Number(selectedYear) - 1).toString(),
                )
              }
            >
              <Icon name="arrow" rotate="90deg" size={12} />
            </IconButton>
            <IconButton
              disabled={Number(selectedYear) == currentYear}
              onClick={() =>
                setSelectedYear(
                  (Number(selectedYear) + 1).toString(),
                )
              }
            >
              <Icon name="arrow" rotate="-90deg" size={12} />
            </IconButton>
          </Box>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MonthCalendar
            disableFuture
            minDate={dayjsUtc(startDate)}
            maxDate={dayjsUtc(tomorrow)}
            autoFocus={false}
            value={dayjsUtc(today)
              .set('month', Number(selectedMonth) - 1)
              .set('year', Number(selectedYear))}
            onChange={selectedMonth =>
              monthSelect(selectedMonth)
            }
            monthsPerRow={4}
            sx={{
              width: '100%',
              p: 0,
              '.MuiPickersMonth-root button': {
                width: '72px',
                height: '72px',
                bgcolor: 'grey.400',
                borderRadius: '50%',
                fontSize: '16px',
                transition: 'background-color .25s',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: 'primary.main',
                  color: 'common.white',
                },
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                },
                '&.Mui-disabled': {
                  color: 'grey.200',
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </Drawer>
  );
};
export default StatementFilter;
