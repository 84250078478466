import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const validateIssueDate = function (issueDate: string) {
  if (!issueDate) {
    return false;
  }

  const date = dayjs(issueDate, "DD/MM/YYYY", true);

  if (!date.isValid()) {
    return false;
  }

  const today = dayjs();
  const dayBeforeYesterday = today.subtract(2, "day");

  if (date.isAfter(dayBeforeYesterday, "day")) {
    return false;
  }

  const fiftyYears = today.subtract(50, "year");
  if (date.isBefore(fiftyYears, "day")) {
    return false;
  }

  return true;
};
