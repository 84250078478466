const TextField = (theme: any) => {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[200],
            borderWidth: 1,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
          '&:active .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
          '&:focus .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[200],
          },
        },
        input: {
          color: theme.palette.grey[700],
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '21px',
          borderWidth: 1,
          '-webkit-text-fill': {
            '&:active': {
              WebkitBoxShadow: '0 0 0 1000px transparent inset',
              borderWidth: 1,
            },
          },
          '&:focus': {
            borderWidth: 1,
          },
          '&:active': {
            borderWidth: 1,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
        },
        input: {
          borderWidth: 1,
          '&:active': {
            borderWidth: 1,
          },
        },
        '&.Mui-disabled': {
          borderColor: theme.palette.grey[200],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[300],
          borderWidth: 1,
        },
        '&.Mui-focused': {
          borderWidth: 1,
          color: theme.palette.grey[700],
        },
        '&.MuiInputLabel-shrink': {
          borderWidth: 1,
          color: theme.palette.grey[700],
        },
        '&:hover .MuiInputLabel-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '3px 0 0 0',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
  };
};

export default TextField;
