import useSWR from 'swr';

const asyncFunc = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(''), 5000);
  });
};

const mockOptions = async () => {
  await asyncFunc();

  return [
    {
      value: '1',
      label: 'LTDA',
    },
    {
      value: '2',
      label: 'EIRELI',
    },
    {
      value: '3',
      label: 'EI',
    },
    {
      value: '4',
      label: 'MEI',
    },
    {
      value: '5',
      label: 'ME',
    },
    {
      value: '6',
      label: 'EPP',
    },
    {
      value: '7',
      label: 'SA',
    },
    {
      value: '8',
      label: 'SS',
    },
    {
      value: '9',
      label: 'COPERATIVA',
    },
    {
      value: '10',
      label: 'CONSÓRCIO',
    },
  ];
};

const useLegalNature = () => {
  const key = {
    endpoint: `/v1/legal-nature`,
  };

  const options = {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  };

  const { data, error } = useSWR(key, mockOptions, options);

  return {
    data,
    loading: !error && !data,
    error,
  };
};

export default useLegalNature;
