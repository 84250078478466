import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Icon, themes } from '@2ndmarket/components';
import { AppContext, LocalStorage } from '@2ndmarket/helpers';

import DrawerProfile from '../DrawerProfile';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

interface Props {
  title?: string;
}

const HeaderNav: React.FC<Props> = ({ children, title }) => {
  const context = useContext(AppContext);
  const router = useRouter();

  const toggleEye = () => {
    context?.setShowBalance(!context.showBalance);
    localStorage.setItem(
      LocalStorage.BANKAI_BALANCE_VIEW,
      (!context?.showBalance).toString(),
    );
  };

  return (
    <Box
      width="100%"
      height="75px"
      maxWidth="956px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {children ? (
        children
      ) : title ? (
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <IconButton onClick={() => router.back()}>
              <Icon
                name="arrow-alternative"
                rotate="180deg"
                size={20}
                color={themes.bankai.palette.common.white}
              />
            </IconButton>
          </Box>
          <Typography
            component="p"
            variant="body1"
            sx={{
              color: 'common.white',
              fontSize: '18px',
              textAlign: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexShrink={0}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <DrawerProfile />
          <IconButton
            onClick={() => toggleEye()}
            sx={{
              width: 42,
              height: 42,
              bgcolor: 'grey.800',
            }}
          >
            <Icon
              name={context?.showBalance ? 'view' : 'hide'}
              size={20}
              color={themes.bankai.palette.common.white}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default HeaderNav;
