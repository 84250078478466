import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

interface BitcoinParams {
  is_managed: boolean;
  is_main: boolean;
}

interface Params {
  coin: string;
  name: string;
  params?: BitcoinParams;
}

const createCryptoWallet = (
  params: Params,
  user_id: number | AppConstants.ME = AppConstants.ME
) => {
    const coin_type = params.coin.toUpperCase();

    return authenticatedFetcher({
      endpoint: `/v4/crypto/users/${user_id}/coin/${coin_type}/wallet`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(params),
    });
  }

export default createCryptoWallet;
