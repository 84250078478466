import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface Props {
  application: string;
}
const RedirectPage: React.FC<Props> = ({ application }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const auth = params.get('auth');
    const token = params.get('token');
    const user = params.get('user');

    const loginUrl = process.env.NEXT_PUBLIC_LOGIN_URL
      ? process.env.NEXT_PUBLIC_LOGIN_URL +
        `/acesso/${application}`
      : '/';

    if (typeof window === 'undefined') {
      return;
    }

    if (auth == 'none') {
      (window as Window).location.href = loginUrl;
      return;
    }

    if (typeof token !== 'string' || typeof user !== 'string') {
      (window as Window).location.href = loginUrl;
      return;
    }

    if (!token || !user) {
      (window as Window).location.href = loginUrl;
      return;
    }

    localStorage.setItem('@2ndMarket-token', token);
    localStorage.setItem('@2ndMarket-user', user);

    (window as Window).location.href = '/';
  }, [application]);

  return null;
};

export default RedirectPage;
