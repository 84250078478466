const resolvedOptions = new Intl.NumberFormat("pt-BR", {
	style: "decimal",
	currency: "BRL",
	minimumFractionDigits: 2,
	maximumFractionDigits: 8,
}).resolvedOptions();

export const toBitcoin = (amountSatoshis: number): string => {
	const amountBitcoin = amountSatoshis / 100000000; // convert satoshis to bitcoins
	const formattedAmount = new Intl.NumberFormat(
		"pt-BR",
		resolvedOptions
	).format(amountBitcoin);
	return formattedAmount;
};

export const toBitcoinNumber = (
	amountBitcoin: string
): number => {
	return Number(
		amountBitcoin.replace(/\./g, "").replace(",", ".")
	);
};
