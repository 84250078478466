import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants, Crypto } from '@2ndmarket/helpers';
import {
  IServiceResponse,
  IWalletCrypto,
  WalletCryptoEmpty,
} from '@2ndmarket/types';

const useCryptoWallet = (
  coin: string,
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IWalletCrypto[]> => {
  let coin_type = coin;
  if (coin_type) {
    coin_type = coin_type.toUpperCase();
  }
  let key;

  switch (coin_type) {
    case Crypto.BKAI.toUpperCase():
    case Crypto.BTC.toUpperCase():
      key = {
        endpoint: `/v4/crypto/users/${user_id}/coin/${coin_type}/wallet`,
      };
      break;
  }

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<
    Array<IWalletCrypto>,
    Error
  >(key, authenticatedFetcher, options);

  return {
    data: data ?? WalletCryptoEmpty,
    loading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export default useCryptoWallet;
