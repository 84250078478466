import authenticatedFetcher from './authenticatedFetcher';

interface Props {
  endpoint: string;
  headers?: any;
  method: string;
  body?: any;
}

const braexAuthenticatedFetcher: (params: Props) => Promise<any> =
  async ({
    endpoint = '',
    headers = {},
    method = 'GET',
    body = '',
  }) => {

    return authenticatedFetcher({
      api: process.env.NEXT_PUBLIC_API_BRAEX,
      endpoint: endpoint,
      headers: headers,
      method: method,
      body: body,
    });
  };

export default braexAuthenticatedFetcher;