const List = (theme: any) => {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiList-root': {
            display: 'flex',
          },
          '&.MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
          },
          '&.one-search': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
          },
          '&.two-search': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
          },
          '&.three-search': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
          },
          '&.four-search': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {},
      },
    },
  };
};

export default List;
