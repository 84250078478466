export interface ICryptoConversion {
  coin_type: string,
  exchange_value: number,
  coin_precision: number,
  fiat: string,
  crypto_value: number,
  fiat_value: number,
  fees: {
    relative_fee: number,
    absolute_fee: number,
    minimum_fee: number,
    total_fees: number,
    fee_coin: string,
    final_amount: number,
    final_amount_coin: string
  }
}

export const CryptoConversionEmpty: ICryptoConversion = {
  coin_type: "",
  exchange_value: 0,
  coin_precision: 0,
  fiat: "",
  crypto_value: 0,
  fiat_value: 0,
  fees: {
    relative_fee: 0,
    absolute_fee: 0,
    minimum_fee: 0,
    total_fees: 0,
    fee_coin: "",
    final_amount: 0,
    final_amount_coin: ""
  }
};
