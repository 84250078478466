const Radio = (theme: any) => {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
      },
    },
  };
};

export default Radio;
