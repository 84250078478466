const enum OperationType {
  BILL = "BILL",
  BKAIBUY = "BKAIBUY",
  BKAIP2P = "BKAIP2P",
  BKAISELL = "BKAISELL",
  BLOCK = "BLOCK",
  BTCBUY = "BTCBUY",
  BTCP2P = "BTCP2P",
  BTCSELL = "BTCSELL",
  FIATBILLCASHIN = "FIATBILLCASHIN",
  FIATCARDDEBIT = "FIATCARDDEBIT",
  FIATCARDWHITDRAW = "FIATCARDWHITDRAW",
  FIATPIXCASHIN = "FIATPIXCASHIN",
  P2P = "P2P",
  PIX = "PIX",
  REFUND = "REFUND",
  REVERTFIATCARDDEBIT = "REVERTFIATCARDDEBIT",
  REVERTFIATCARDWHITDRAW = "REVERTFIATCARDWHITDRAW",
  TED = "TED",
}

export default OperationType;
