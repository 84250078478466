import { createTheme } from '@mui/material/styles';

import componentsOverrides from './overrides';

import shadows from './shadows';
import typography from './typography';
import customShadows from './customShadows';
import palette from './paletteBitcoin';

const themeOptions = {
  name: 'bitcoin',
  palette: palette,
  typography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shadows: shadows(),
  customShadows: customShadows(),
};

const bitcoin = createTheme(themeOptions as object);
bitcoin.components = componentsOverrides(bitcoin);

export default bitcoin;
