import styled from 'styled-components';
import { theme } from 'styled-tools';

interface Props {
  error?: boolean;
}

export const Container = styled.div<Props>`
  height: 56px;
  border: 1px dashed;
  border-color: ${props =>
    props.error ? '#DC3545' : '#bbc2cb'};
  border-radius: 2px;
  padding: 0 19px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .add {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${theme('colors.secondary.2')};
  }

  input {
    visibility: hidden;
    padding: 0;
    width: 1px;
  }

  label {
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }

  button {
    background-color: transparent;
    z-index: 9;
    max-width: fit-content;

    i {
      padding-top: 2px;
      line-height: 1;
      font-size: 12px;
      display: block;
    }
  }

  #file_back,
  #file_front {
    padding: 0;
    width: 88%;
    height: 56px;
    position: absolute;
  }
`;

export const Infos = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  white-space: nowrap;

  span {
    font-weight: 400;
    font-size: 14px;
    color: ${props => (props.error ? '#DC3545' : '#8c96a0')};
  }

  div {
    display: flex;
    flex-direction: column;
    max-width: 90%;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: ${props => (props.error ? '#DC3545' : '#bbc2cb')};

      &.filled {
        color: ${theme('colors.primary.main')};
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: ${props => (props.error ? '#DC3545' : '#5a6473')};
      overflow: hidden;
      text-overflow: ellipsis;
      width: 300px;

      @media only screen and (max-width: ${theme(
          'breakpoints.mobile',
        )}) {
        width: 200px;
      }
    }
  }

  i {
    line-height: 1;
    display: block;
    padding-top: 3px;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #dc3545;
`;
