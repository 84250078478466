import { Icon } from '@2ndmarket/components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Tokens: React.FC = () => {
  return (
    <>
      <Typography
        variant="h4"
        component="h4"
        color="text.primary"
        mb={2}
      >
        Tokens
      </Typography>
      <Box
        display="flex"
        gap="12px"
        position="relative"
        bgcolor="common.white"
        borderRadius="3px"
        p={3}
        sx={{
          '& i': {
            marginTop: '2px',
            color: 'primary.main',
          },
          '& span': {
            fontSize: '14px',
            color: 'grey.300',
            position: 'absolute',
            right: '16px',
            bottom: '8px',
          },
        }}
      >
        <Icon name="qrcode" size={20} />
        <Typography component="p" variant="body1">
          Transfira seus tokens, NFT`s e muito mais!
        </Typography>
        <Typography component="span" variant="body1">
          Em breve
        </Typography>
      </Box>
    </>
  );
};

export default Tokens;
