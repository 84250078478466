import { Icon } from '@2ndmarket/components';
import { cryptoList } from './cryptoList';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const ComingCryptos: React.FC = () => {
  return (
    <>
      <Typography
        variant="h4"
        component="h4"
        color="text.primary"
        mb={2}
      >
        Disponíveis em breve
      </Typography>
      <Box
        sx={{
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: 'grey.400',
            height: '4px',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb':
            {
              borderRadius: '2px',
              backgroundColor: 'primary.main',
            },
        }}
      >
        <Box
          width="100%"
          whiteSpace="nowrap"
          mb={4}
          pb={1}
          sx={{
            overflowX: 'auto',
            '.cryptoItem': {
              bgcolor: 'common.white',
              borderRadius: '3px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '170px',
              marginRight: '12px',
              p: 2,
              '.MuiAvatar-root': {
                mb: 1,
                '& i': {
                  color: 'grey.300',
                },
              },
              '& span': {
                fontSize: '14px',
                color: 'text.secondary',
              },
            },
          }}
        >
          {cryptoList.map((item, index) => {
            return (
              <Box className="cryptoItem" key={index}>
                <Avatar>
                  <Icon name={item.icon} size={20} />
                </Avatar>
                <Typography component="p" variant="body1">
                  {item.code}
                </Typography>
                <Typography component="span" variant="body1">
                  {item.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default ComingCryptos;
