import { IconName } from '@2ndmarket/components';

export interface IReceiptData {
  type: string;
  label: string;
  value: string;
  icon: IconName;
}

export interface IReceipt {
  title: string;
  date: string;
  color: string;
  icon: IconName;
  data: Array<IReceiptData>;
  company: string;
  cnpj: string;
  status: string;
}

export interface PropsReceipt {
  infos: IReceipt;
  type?: string;
}

export const ReceiptEmpty: IReceipt = {
  title: '',
  date: '',
  color: '',
  icon: '2nd' as IconName,
  data: [],
  company: '',
  cnpj: '',
  status: '',
};
