const enum FiatOperation {
	PIX = "PIX",
	P2P = "P2P",
	TED = "TED",
	BILL = "BILL",
	BTCOTC = "BTCOTC",
	BKAIOTC = "BKAIOTC",
	FIATBILLCASHIN = "FIATBILLCASHIN",
	FIATPIXCASHIN = "FIATPIXCASHIN",
	FIATCARDDEBIT = "FIATCARDDEBIT",
	FIATCARDWHITDRAW = "FIATCARDWHITDRAW",
	REVERTFIATCARDDEBIT = "REVERTFIATCARDDEBIT",
	REVERTFIATCARDWHITDRAW = "REVERTFIATCARDWHITDRAW",
	BLOCK = "BLOCK",
	REFUND = "REFUND",
}

export default FiatOperation;
