import { FiatOperation } from "@2ndmarket/helpers"

export interface ITransactionDatetime {
  server_datetime: string | Date,
  datetime_offset: number,
  banking_info: {
    holidays: [
      string
    ],
    schedule_for_today: boolean,
    transaction_info: [
      {
        id: number,
        payment_method: FiatOperation,
        start: string,
        end: string
      }
    ]
  }
}

export const TransactionDatetimeEmpty: ITransactionDatetime = {
  server_datetime: "2023-10-25T20:04:40.174Z",
  datetime_offset: 0,
  banking_info: {
    holidays: [
      "2023-10-25"
    ],
    schedule_for_today: true,
    transaction_info: [
      {
        id: 0,
        payment_method: FiatOperation.P2P,
        start: "string",
        end: "string"
      }
    ]
  }
}