import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

interface BtnProps {
  active: boolean;
  status?: string;
}

export const Container = styled.div`
  @media only screen and (min-width: ${theme(
      'breakpoints.desktopBackoffice',
    )}) {
    display: flex;
  }

  & > .tabHeader {
    border: 1px solid ${theme('colors.secondary.4')};
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 18vw;

    @media (max-width: ${theme(
        'breakpoints.mobileBackoffice',
      )}) {
      align-items: center;
      background-color: ${theme('colors.secondary.4')};
      justify-content: space-between;
    }
  }

  .desktopOnly {
    @media (max-width: ${theme(
        'breakpoints.mobileBackoffice',
      )}) {
      display: none !important;
    }

    @media (max-width: ${theme('breakpoints.mobileBraex')}) {
      display: none !important;
    }
  }

  .mobileOnly {
    @media (min-width: ${theme(
        'breakpoints.desktopBackoffice',
      )}) {
      display: none !important;
    }

    @media (min-width: ${theme('breakpoints.desktopBraex')}) {
      display: none !important;
    }
  }

  .contentBody {
    width: 100%;
  }
`;

export const Content = styled.div`
  h1 {
    font-weight: 600;
    font-size: 18px;
    color: #5a6473;
    margin-bottom: 21px;
  }

  .cancel {
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    color: #4150e6;
    margin: 36px 0 0 19px;
  }
`;

export const Btn = styled.button<BtnProps>`
  align-items: center;
  background-color: #ffffff;
  border-radius: 2px;
  color: ${theme('colors.secondary.2')};
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 15px;
  padding: 16px 25px;
  transition: color 0.25s ease;
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${theme('colors.secondary.4')};

  :last-child {
    border: none;
  }

  &:hover {
    color: ${theme('colors.primary.main')};
  }

  @media (max-width: ${theme('breakpoints.mobileBackoffice')}) {
    width: 100%;
    padding: 16px 19px;
    display: flex;
    justify-content: space-between;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${theme('colors.primary.main')};

      @media (max-width: ${theme(
          'breakpoints.mobileBackoffice',
        )}) {
        background-color: ${theme('colors.secondary.4')};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ status }) =>
    status &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${status};
        right: 15px;
      }
    `}

  & > span {
    flex: 1;
    text-align: left;
  }

  & > i:last-of-type {
    color: ${theme('colors.secondary.3')};
    padding: 0 20px;
    transform: rotate(270deg);

    @media (max-width: ${theme('breakpoints.mobileBackoffice')}) {
      padding: 0;
      transform: none;
    }
  }
`;

export const HeaderMobile = styled.div`
  margin: 0 -30px 31px -30px;
  border: 1px solid ${theme('colors.secondary.6')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-weight: 600;
    font-size: 18px;
    color: #5a6473;
    margin-left: 30px;
  }

  button {
    height: 56px;
    width: 56px;
    background-color: #4150e6;
  }
`;
