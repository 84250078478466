import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import {
  BanksEmpty,
  IBank,
  IServiceResponse,
} from '@2ndmarket/types';

const useBank = (
  bank_id: number | null,
): IServiceResponse<IBank> => {
  const key = bank_id
    ? {
        endpoint: `/v4/locale/banks/${bank_id}`,
      }
    : null;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IBank, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? BanksEmpty.results[0],
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useBank;
