import styled from 'styled-components';
import { theme } from 'styled-tools';

interface Props {
  thema?: string;
}

export const LoaderBox = styled.div<Props>`
  position: fixed;
  left: 0;
  top: 0;
  background: ${props =>
    props.thema
      ? theme('colors.loader')
      : 'rgb(255, 255, 255, 0.9)'
    };
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.medium {
    background: transparent;
    position: absolute;
    width: 27.3vw;
    height: 42.5vh;
    left: 1.2vw;

    p {
      color: ${theme('colors.dark.gray.2')};
    }
  }

  &.small-braex {
    background: rgb(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(10px);

    p {
      color: ${theme('colors.offWhite')};
      font-weight: 400;
      font-size: 16px;
    }
  }

  h2,
  h3,
  p {
    max-width: 300px;
    text-align: center;
  }

  h2 {
    font-weight: 400;
    font-size: 16px;
    color: #8c96a0;
    margin: 0;
  }

  h3 {
    color: #8c96a0;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
  }

  p {
    color: #c9d1da;
    font-size: 14px;
    max-width: 300px;
  }
`;

export const LoaderContainer = styled.div`
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxLoader = styled.div`
  height: 120px;
  width: 120px;
  position: relative;

  @keyframes anim_one {
    0%,
    100% {
      top: 0;
      left: 0;
    }
    25% {
      top: 0;
      left: calc(100% - 30px);
    }
    50% {
      left: calc(100% - 100px);
      top: calc(100% - 100px);
      height: 70px;
      width: 70px;
      background: #fff;
    }
    75% {
      left: 0;
      top: calc(100% - 30px);
    }
  }

  @keyframes anim_two {
    0%,
    100% {
      left: calc(100% - 100px);
      top: calc(100% - 100px);
      height: 70px;
      width: 70px;
    }
    25% {
      top: calc(100% - 30px);
      left: 0;
    }
    50% {
      left: 0;
      top: 0;
      height: 30px;
      width: 30px;
      background: #fff;
    }
    75% {
      left: calc(100% - 30px);
      top: 0;
    }
  }

  .square {
    position: absolute;
    border-radius: 0%;

    &.first {
      height: 30px;
      width: 30px;
      animation: anim_one 3s linear infinite;
      border: 8px solid ${theme('colors.primary.main')};
    }

    &.second {
      left: calc(100% - 100px);
      top: calc(100% - 100px);
      height: 70px;
      width: 70px;
      animation: anim_two 3s linear infinite;
      border: 8px solid ${theme('colors.primary.light')};
    }
  }
`;
