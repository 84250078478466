import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)

export const validateBirthDate = function(birthDate:string):boolean {

    if(!birthDate) {

        return false;
    }

    const date = dayjs(birthDate, 'DD/MM/YYYY', true)

    if(!date.isValid()) {
        return false;
    }

    const eighteenYearsAgo = dayjs().subtract(18, 'year')
    if(date.isAfter(eighteenYearsAgo)) {
        return false;
    }

    const hundredTwentyYearsAgo = dayjs().subtract(120, 'year')
    if(date.isBefore(hundredTwentyYearsAgo)) {
        return false;
    }

    return true;
}