import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';
import { BalanceEmpty, IBalance, IServiceResponse } from '@2ndmarket/types';

const useBalance = (
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IBalance> => {
  const key = {
    endpoint: `/v4/fiat/users/${user_id}/balance`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IBalance, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? BalanceEmpty,
    loading: !error && !data,
    mutate: mutate,
    error,
  };
};

export default useBalance;
