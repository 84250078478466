import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.header`
  align-items: center;
  color: ${theme('colors.secondary.1')};
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 2vw;
  width: 100%;

  @media (max-width: ${theme('breakpoints.mobileBackoffice')}) {
    width: 0;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 145px;

  > div {
    align-items: center;
    background-color: blue;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: -135px;
    width: 40px;
  }

  & > p {
    color: ${theme('colors.secondary.1')};
  }

  i {
    color: ${theme('colors.secondary.3')};
    font-size: 14px;
  }

  & > button {
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 10px;

    p {
      color: ${theme('colors.secondary.1')};
    }

    :hover {
      p,
      i {
        color: ${theme('colors.primary.main')};
      }
    }
  }
`;
