import { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Masks,
  formatBRL,
  formatCpfCnpj,
} from '@2ndmarket/helpers';
import { ContentTitle } from '../../../../components';

import {
  DateDrawer,
  Icon,
  MaskedInput,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  transferAmount: any;
  transferTarget: any;
  setStep: any;
  targetName: any;
  favorite: boolean;
  setTransferData: React.Dispatch<React.SetStateAction<object>>;
}

interface FormData {
  amount: number;
  cpf_cnpj: string;
  favorite: boolean;
  schedule_date: string | null;
  targetName: string;
  tax: number;
}

const steps = [
  'Início',
  'P2P',
  'Valor',
  'Destinatário',
  'Revisão',
];

const ReviewStep: React.FC<Props> = ({
  transferAmount,
  transferTarget,
  setStep,
  targetName,
  favorite,
  setTransferData,
}) => {
  const defaultDateText = 'Agora';
  const [dateOpen, setDateOpen] = useState(false);
  const [selectedDate, setSelectedDate] =
    useState(defaultDateText);

  const defaultValues = {
    cpf_cnpj: formatCpfCnpj(transferTarget),
    amount: transferAmount,
    targetName: targetName,
    schedule_date: defaultDateText,
    favorite: favorite,
    tax: 0,
  };

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    setValue('schedule_date', selectedDate);
  }, [selectedDate, setValue]);

  const favoriteWatcher = useWatch({
    control,
    name: 'favorite',
  });

  function onSubmit(data: FormData) {
    if (defaultDateText == data.schedule_date) {
      data.schedule_date = null;
    }
    data.cpf_cnpj = data.cpf_cnpj.replace(/[^\d]/g, '');
    setTransferData(data);
    setStep('step4');
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <ContentTitle title="Transferir" steps={steps} />
      <Typography
        component="p"
        sx={{ fontWeight: '500', color: 'text.secondary' }}
      >
        Transferindo
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        color="primary.main"
      >
        {formatBRL(transferAmount)}
      </Typography>
      <Box
        sx={{
          mt: 3,
          mb: 5,
          display: 'grid',
          gap: 3,
          alignItems: 'start',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
          },
          '& .MuiTextField-root': {
            margin: 0,
          },
        }}
      >
        <TextField
          {...register('cpf_cnpj', { required: true })}
          sx={{ marginBottom: 5 }}
          disabled
          fullWidth
          label="CPF/CNPJ"
          error={Boolean(errors.cpf_cnpj)}
          helperText={errors.cpf_cnpj && errors.cpf_cnpj.message}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {
              unmask: true,
              mask:
                transferTarget.replace(/\D/g, '').length < 12
                  ? Masks.CPF
                  : Masks.CNPJ,
              inputMode: 'numeric',
            },
          }}
        />
        <TextField
          {...register('targetName', { required: true })}
          sx={{ marginBottom: 5 }}
          disabled
          fullWidth
          label="Destinatário"
          error={Boolean(errors.targetName)}
          helperText={
            errors.targetName && errors.targetName.message
          }
        />
        <TextField
          {...register('schedule_date', { required: true })}
          onClick={() => setDateOpen(true)}
          sx={{
            marginBottom: 5,
            input: { cursor: 'pointer' },
            'input.Mui-disabled:hover': { cursor: 'default' },
          }}
          fullWidth
          disabled
          label="Quando"
          error={Boolean(errors.schedule_date)}
          helperText={
            errors.schedule_date && errors.schedule_date.message
          }
          InputProps={{
            inputProps: { readOnly: true },
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ '& i': { color: 'text.disabled' } }}
              >
                <Icon name="calendar" size={16} />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={favoriteWatcher}
              {...register('favorite')}
              color="primary"
            />
          }
          label="Contato favorito"
          labelPlacement="start"
          sx={{
            justifyContent: 'space-between',
            height: '56px',
            color: 'text.secondary',
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mb: 4,
          py: 2,
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'grey.200',
        }}
      >
        <Typography
          component="p"
          sx={{ color: 'text.secondary' }}
        >
          Tarifa
        </Typography>
        <Typography component="p" sx={{ color: 'primary.main' }}>
          Gratuita
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => setStep('step2')}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
      <DateDrawer
        dateOpen={dateOpen}
        setDateOpen={setDateOpen}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        defaultDateText={defaultDateText}
      />
    </Box>
  );
};
export default ReviewStep;
