import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Content = styled.aside`
  border-right: 1px solid ${theme('colors.secondary.4')};
  display: flex;
  flex-direction: column;

  & > div:last-of-type {
    flex: 1;
  }

  footer {
    color: ${theme('colors.secondary.3')};
    padding: 0 30px 45px 30px;

    a {
      color: ${theme('colors.primary.main')};
      text-decoration: underline;
    }
  }
`;
