import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const sendBillPay = function (
  slip_id: number,
  amount: string,
  schedule_date: string | null,
  pin: string,
  user_id: number | AppConstants.ME = AppConstants.ME,
) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/users/${user_id}/bill-slip/${slip_id}/pay-up`,
    headers: {
      'Content-Type': 'application/json',
      'user-pin': pin,
    },
    method: 'POST',
    body: JSON.stringify({
      value: amount,
      schedule_date: schedule_date,
    }),
  });
};

export default sendBillPay;
