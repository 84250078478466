import useSWR from 'swr';

const asyncFunc = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(''), 2000);
  });
};

const mockNotifications = async () => {
  await asyncFunc();

  return {
    messages: [
      {
        message:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit seddo eiusmod tempora labore momentun saporium',
        read: true,
        date: '10 JAN 2022 - 10h30',
      },
      {
        message:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit seddo eiusmod tempora labore momentun saporium',
        read: true,
        date: '11 MAR 2022 - 12h02',
      },
      {
        message:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit seddo eiusmod tempora labore momentun saporium',
        read: false,
        date: '12 AGO 2022 - 18h54',
      },
    ],

    allRead: false,
  };
};

const useNotifications = () => {
  return false;
};

export default useNotifications;
