import { UserStatus } from '@2ndmarket/helpers';

export interface IStaffUpdate {
  cpf: string;
  email: string;
  name: string;
  phone: string;
  role_id: number;
  password: string;
}

export interface IStaffCreate {
  cpf: string;
  email: string;
  name: string;
  phone: string;
  role: string;
  password: string;
}

export interface IStaff {
  id: number;
  cpf: string;
  email: string;
  name: string;
  phone: string;
  role_id: string;
  password: string;
  role: {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    type: string;
    description: string;
  };
  status_message: string;
  status: UserStatus;
  updated_by: number;
  created_at: string;
  updated_at: string;
  two_fa_hash: string;
}

export interface IStaffPin {
  code: string;
  status?: string;
}

export interface StaffIdProps {
  team_id: number;
}

export const StaffEmpty: IStaff = {
  id: 0,
  cpf: '',
  email: '',
  name: '',
  phone: '',
  role_id: '',
  password: '',
  role: {
    id: 0,
    created_at: '',
    updated_at: '',
    name: '',
    type: '',
    description: '',
  },
  status_message: '',
  status: UserStatus.APPROVED,
  updated_by: 0,
  created_at: '',
  updated_at: '',
  two_fa_hash: '',
};

export const StaffPinEmpty: IStaffPin = {
  code: '',
  status: '',
};
