import styled from "styled-components";

interface TagProps {
  color?: string;
  transform?: string
}

export const SvgTag = styled.svg<TagProps>`
  display: inline-block;
  transform: ${props => props.transform};
  transition: 'color 0.25s';

  & path {
    fill: ${props => props.color};
    transition: 'color 0.25s';
  }
`;

export const IconTag = styled.i<TagProps>`
  display: grid;
  color: ${props => props.color};
  transition: color 0.25s;
  &:before {
    transform: ${props => props.transform ? props.transform : ''};
  }
`;