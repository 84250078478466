import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HttpError,
  IntentStatus,
  Masks,
  dayjsUtc,
  yupPt,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {
  ContentTitle,
  TransactionModal,
  TransferAwait,
} from '../../../../components';
import { Icon, MaskedInput } from '@2ndmarket/components';
import { sendBillPay } from '@2ndmarket/services';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  billData: any;
}

const PasswordStep: React.FC<Props> = ({
  setStep,
  billData,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [transferDataPromise, setTransferDataPromise] =
    useState(billData);
  const [transactionId, setTransactionId] = useState('');
  const recipePath = '/carteira-digital/comprovante/bill/';
  const [modal, setModal] = useState(false);
  const [intentStatus, setIntentStatus] = useState(
    IntentStatus.NONE,
  );
  const modalTitle = 'Pagamento de Boleto';
  const [modalText, setModalText] = useState<string | null>('');

  const schema = yupPt.object().shape({
    password: yupPt.string().min(6).required(),
  });
  const defaultValues = { password: '' };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: { password: string }) {
    setIntentStatus(IntentStatus.NONE);
    setModal(true);
    setModalText('');
    sendBillPay(
      billData.bill_id,
      billData.amount,
      billData.schedule_date
        ? dayjsUtc(billData.schedule_date, 'YY-MM-DDDD').format(
            'DD-MM-YYYY',
          )
        : billData.schedule_date,
      data.password,
    )
      .then(() => {
        const responseBillPay = localStorage.getItem(
          '@locationHeader',
        );

        const response = responseBillPay
          ?.split('?')[1]
          .split('&')
          .reduce(
            (accumulator: any, item: any) => {
              const [key, value] = item.split('=');
              accumulator[key] = value;
              return accumulator;
            },
            {
              amount: billData.amount,
            },
          );

        setTransferDataPromise({
          id: parseInt(response.intent_id),
          type: response.type,
          register: false,
        });
      })
      .catch((error: HttpError) => {
        setValue('password', '');
        setModalText(error.error);
        setIntentStatus(IntentStatus.FAILURE);
      });
  }

  const steps = [
    'Início',
    'Linha digitável',
    'Revisão',
    'Senha',
  ];

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <ContentTitle title="Boleto" steps={steps} />
      <TextField
        fullWidth
        id="password"
        label="Senha"
        margin="dense"
        autoComplete="off"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          inputComponent: MaskedInput,
          inputProps: {
            inputMode: 'numeric',
            mask: Masks.CODE,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(
                  e: React.MouseEvent<HTMLButtonElement>,
                ) => e.preventDefault()}
                edge="end"
                sx={{
                  p: 1.5,
                  mr: -1,
                  '& i': { color: 'grey.300' },
                }}
              >
                {showPassword ? (
                  <Icon size={20} name="view" />
                ) : (
                  <Icon size={20} name="hide" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...register('password', { required: true })}
        error={Boolean(errors.password)}
        helperText={errors.password && errors.password.message}
        sx={{ mb: 4 }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => setStep('step2')}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Confirmar
        </Button>
      </Box>
      {transferDataPromise?.id && (
        <TransferAwait
          data={transferDataPromise}
          setIntentStatus={setIntentStatus}
          setIntentDescription={setModalText}
          redirect="/carteira-digital/comprovante/p2p/"
          setTransactionId={setTransactionId}
        />
      )}
      {modal && (
        <TransactionModal
          open={modal}
          title={modalTitle}
          text={modalText}
          status={intentStatus}
          onClose={() => setModal(false)}
          target={recipePath + transactionId}
        />
      )}
    </Box>
  );
};
export default PasswordStep;
