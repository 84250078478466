import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const validateExpirationDate = function(expirationDate:string) {

    if(!expirationDate) {

        return false;
    }

    const date = dayjs(expirationDate, 'DD/MM/YYYY', true)

    if(!date.isValid()) {
        return false;
    }

    const tomorrow = dayjs().add(1, 'day')
    if(date.isBefore(tomorrow)) {

        return false;
    }

    const fiftyYearsInFuture = dayjs().add(50, 'year')
    if(date.isAfter(fiftyYearsInFuture)) {

        return false;
    }

    return true;
}