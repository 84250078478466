import Grid from '@mui/material/Grid';

import Loader from '../Loader';

interface LoadingProps {
  loading: boolean;
}

const LoadingSection: React.FC<LoadingProps> = ({ loading }) => {
  return (
    <>
      {loading && (
        <Grid
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          position="absolute"
          top={0}
          sx={{
            zIndex: '9',
            backgroundColor: 'background.neutral',
          }}
        >
          <Loader loaderState={loading} text="Carregando..." />
        </Grid>
      )}
    </>
  );
};

export default LoadingSection;
