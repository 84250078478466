import { useState } from 'react';
import TypeableLine from './TypeableLineStep';
import ReviewStep from './ReviewStep';
import PasswordStep from './PasswordStep';

const BillPay: React.FC = () => {
  const [currentStep, setCurrentStep] = useState('step1');
  const [billCode, setBillCode] = useState('');
  const [billId, setBillId] = useState(0);
  const [billData, setBillData] = useState({});

  return (
    <>
      {'step1' == currentStep && (
        <TypeableLine
          setStep={setCurrentStep}
          setBillCode={setBillCode}
          setBillId={setBillId}
        />
      )}

      {'step2' == currentStep && (
        <ReviewStep
          setStep={setCurrentStep}
          billCode={billCode}
          billId={billId}
          setBillData={setBillData}
        />
      )}
      {'step3' == currentStep && (
        <PasswordStep
          setStep={setCurrentStep}
          billData={billData}
        />
      )}
    </>
  );
};
export default BillPay;
