import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { theme } from 'styled-tools';

interface InputStyleProps {
  error?: boolean;
  filled?: boolean;
  textArea?: boolean;
}

const labelFloat = css`
  color: ${theme('colors.secondary.3')};
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 30px);
`;

export const Parent = styled.div<SpaceProps & InputStyleProps>`
  display: flex;
  position: relative;
  flex-direction: column;

  ${space}

  input[type="email"] {
    text-transform: lowercase;
  }

  input {
    border: 1px solid
      ${({ error }) =>
        theme(
          error ? 'colors.error.main' : 'colors.secondary.4',
        )};
    background-color: ${theme('colors.white')};
    color: ${theme('colors.secondary.1')};
    transition: all 0.25s ease;
    padding: 12px 15px 0;
    border-radius: 2px;
    line-height: 22px;
    font-size: 16px;
    height: ${props => (props.textArea ? '140' : '56')}px;
    width: 100%;

    &:disabled {
      background-color: ${theme('colors.white')};
      color: ${theme('colors.secondary.3')};

      & + label {
        color: ${theme('colors.secondary.3')};
        cursor: default;
      }
    }

    &:focus {
      border-color: ${({ error }) =>
        theme(
          error ? 'colors.error.main' : 'colors.primary.main',
        )};
      outline: none;

      & + label {
        color: ${({ error }) =>
          theme(
            error ? 'colors.error.main' : 'colors.primary.main',
          )} !important;
        ${labelFloat}
      }
    }
  }

  #phone {
    width: 60%;
    border: none;
    background-color: transparent;
  }

  button {
    top: 18px;
    right: 15px;
    position: absolute;
    background-color: transparent;
  }
`;

export const Input = styled.input<InputStyleProps>`
  width: 100%;
  height: 56px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 2px;
  padding: 12px 15px 0;
  transition: all 0.25s ease;
  color: ${theme('colors.secondary.1')};
  background-color: ${theme('colors.white')};
  border: 1px solid
    ${({ error }) =>
      theme(error ? 'colors.error.main' : 'colors.secondary.4')};

  &:focus {
    border-color: ${({ error }) =>
      theme(
        error ? 'colors.error.main' : 'colors.primary.main',
      )};
    outline: none;

    & + label {
      color: ${({ error }) =>
        theme(
          error ? 'colors.error.main' : 'colors.primary.main',
        )} !important;
      ${labelFloat}
    }
  }

  &:disabled {
    background-color: ${theme('colors.white')};
    color: ${theme('colors.secondary.3')};

    & + label {
      color: ${theme('colors.secondary.3')};
      cursor: default;
    }
  }

  &.code-phone {
    letter-spacing: 20px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
`;

export const Placeholder = styled.label<InputStyleProps>`
  color: ${theme('colors.secondary.2')};
  cursor: text;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  left: 15px;
  position: absolute;
  top: 20px;
  transition: all 0.25s ease;
  pointer-events: none;

  &.label-height {
    top: 15px;
  }

  &.code-phone {
    width: 95%;
    display: flex;
    justify-content: space-evenly;

    letter-spacing: 15px;
  }

  ${({ error }) =>
    error &&
    css`
      color: ${theme('colors.error.main')} !important;
      ${labelFloat}
    `}

  ${({ filled }) =>
    filled &&
    css`
      ${labelFloat}
    `}
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  transform: translateY(100%);
  color: ${theme('colors.error.main')};
`;
