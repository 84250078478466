import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../../authenticatedFetcher';
interface Params {
  destination: string | number;
  amount: number;
  description?: string;
  add_favorite?: boolean;
}

const emptyableFields = ['description', 'add_favorite'];

const sendBtcP2P = (
  params: Params,
  userPin: string | number,
  crypto: string,
  wallet_id: number,
  user_id: number | AppConstants.ME = AppConstants.ME,
) => {
  for (const key in params) {
    // @ts-ignore
    const value = params[key];
    if (value === '' && !emptyableFields.includes(key)) {
      // @ts-ignore
      delete params[key];
    }
  }

  const coin_type = crypto.toUpperCase();

  return authenticatedFetcher({
    endpoint: `/v4/crypto/users/${user_id}/coin/${coin_type}/wallet/${wallet_id}/p2p`,
    headers: {
      'Content-Type': 'application/json',
      'user-pin': `${userPin}`,
    },
    method: 'POST',
    body: JSON.stringify(params),
  });
};

export default sendBtcP2P;
