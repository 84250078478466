import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  border: none;

  @media (max-width: ${theme('breakpoints.mobile')}) {
    margin-bottom: 20px;
    border: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 27px;

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${theme('colors.modal.warnings.title')};
  }
`;

export const BoxFilters = styled.button`
  display: flex;
  gap: 28px;
  padding: 20px 27px;
  background-color: transparent;
  border: none;

  i {
    color: ${theme('colors.primary.main')};
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: ${theme('colors.modal.warnings.paragraph')};
  }

  :hover {
    background-color: rgba(229, 233, 238, 0.6);
  }
`;

export const Table = styled.table`
  border-top: 1px solid ${theme('colors.secondary.4')};
  border-spacing: 0;
  cursor: pointer;
  width: 100%;

  @media (max-width: ${theme('breakpoints.mobile')}) {
  }

  th {
    background-color: ${theme('colors.background')};
    /* border-bottom: 1px solid ${theme(
      'colors.secondary.4',
    )}; */
    color: ${theme('colors.secondary.1')};
    padding: 12px 4px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &:first-of-type {
      padding-left: 20px;
    }

    &:last-of-type {
      padding-right: 20px;
    }

    @media (max-width: ${theme('breakpoints.mobile')}) {
      border-bottom: none;
      &.not-mobile {
        display: none;
      }
    }
  }

  td {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 18px 4px;
    color: ${theme('colors.secondary.2')};
    border-bottom: 1px solid ${theme('colors.secondary.4')};

    &:first-of-type {
      padding-left: 20px;
    }

    &:last-of-type {
      padding-right: 20px;
    }

    @media (max-width: ${theme('breakpoints.mobile')}) {
      text-align: center;
      &:first-of-type {
        padding-left: 4px;
      }
    }
  }

  tbody {
    tr {
      transition: background-color 0.25s ease-in-out;

      .openColumn {
        width: 60px;

        button {
          background-color: transparent;
          border: none;
          transition: color 0.25s;
          padding: 0 20px;
          cursor: pointer;

          i {
            color: ${theme('colors.secondary.3')};
          }

          &:hover {
            i {
              color: ${theme('colors.primary.main')};
            }
          }
        }

        @media (max-width: ${theme('breakpoints.mobile')}) {
          display: none;
        }
      }
    }

    tr:hover {
      background-color: ${theme('colors.modal.warnings.button')};
    }
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  @media (max-width: ${theme('breakpoints.mobile')}) {
    display: none;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    width: 40px;
    height: 40px;

    &.wrapSelect {
      width: 70px;

      > div {
        width: 70px;
      }
    }

    i {
      right: 12px;
    }
  }

  input {
    width: 40px;
    height: 40px;
    padding-top: 2px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #535668;
  }
`;

export const ButtonNav = styled.button`
  width: 40px;
  height: 40px;
  background: #e5e9ee;
  border-radius: 2px;
  transition: color 0.25s;
  border: none;

  i {
    color: #535668;
  }

  :hover:enabled {
    border: 1px solid ${theme('colors.primary.main')};
    transition: color 0.25s;

    i {
      color: ${theme('colors.primary.main')};
    }
  }

  :disabled {
    cursor: default;
    i {
      color: ${theme('colors.white')};
    }
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${theme('colors.primary.main')};
  }

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: ${theme('colors.modal.warnings.title')};
  }
`;

export const BoxYears = styled.div`
  margin: 20px 0 30px 0;
  border: 1px solid #e5e9ee;
  padding: 15px 0;
  border-left: none;
  border-right: none;
  display: flex;
  justify-content: space-between;

  button {
    background-color: transparent;

    i {
      color: ${theme('colors.modal.warnings.check')};
    }

    :disabled {
      cursor: default;
      i {
        color: ${theme('colors.white')};
      }
    }
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: ${theme('colors.modal.warnings.title')};
  }
`;

export const BoxMonths = styled.div`
  display: flex;
  line-break: auto;
  max-width: 315px;
  gap: 25px;
  flex-wrap: wrap;
  margin-bottom: 36px;

  @media (max-width: ${theme('breakpoints.mobile')}) {
    gap: 15px;
    min-width: 100%;
    justify-content: center;
  }
`;

export const ButtonMonth = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(229, 233, 238, 0.6);
  border-radius: 2px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #535668;
  }

  &.select {
    background: ${theme('colors.primary.main')};

    span {
      color: ${theme('colors.white')};
    }
  }

  :hover:enabled {
    border: 1px solid ${theme('colors.primary.main')};
  }

  :disabled {
    cursor: default;

    span {
      color: ${theme('colors.white')};
    }
  }
`;

export const NoResults = styled.div`
  width: 100%;
  border-bottom: 1px solid #e5e9ee;
  border-top: 1px solid #e5e9ee;
  padding: 18px 0;
  text-align: center;

  p {
    color: #535668;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;
