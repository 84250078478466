import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  position: relative;
  width: min-content;

  button {
    &.active {
      i {
        color: ${theme('colors.primary.main')} !important;
      }
    }
    border: none;
    background-color: transparent;
    padding: 0;
  }
`;

export const TooltipBox = styled.div`
  bottom: 30px;
  position: absolute;
  right: -10px;

  &::before {
    background-color: ${theme('colors.primary.main')};
    border-radius: 2px;
    bottom: -7px;
    content: '';
    height: 10px;
    position: absolute;
    right: 9px;
    transform: rotate(45deg) translateX(-50%);
    width: 10px;
  }
`;
export const TooltipInner = styled.div`
  background-color: ${theme('colors.primary.main')};
  border-radius: 2px;
  padding: 10px 20px;
  width: 215px;
  word-break: break-word;

  p {
    color: #fafafa;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
  }
`;
