import useSWR from 'swr';
import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';
import { BillEmpty, IBill, IServiceResponse } from '@2ndmarket/types';

const useBill = (
  id: number,
  user_id: number | AppConstants.ME = AppConstants.ME,
): IServiceResponse<IBill> => {
  const key = {
    endpoint: `/v4/fiat/users/${user_id}/bill-slip/${id}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IBill, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? BillEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useBill;
