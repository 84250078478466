import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
import { space, SpaceProps } from 'styled-system';

interface RowProps extends SpaceProps {
  mb?: number;
  justifyEnd?: boolean;
}

export const Container = styled.div<RowProps>`
  width: 100%;
  margin-bottom: 18px;
  ${space}

  ${({ justifyEnd }) =>
    justifyEnd &&
    css`
      display: flex;
      justify-content: flex-end;
    `}

  @media (max-width: ${theme('breakpoints.mobile')}) {
    margin-bottom: 18px;
  }

  > .row {
    > div {
      margin: 0;

      &:not(:last-child) {
        @media (max-width: ${theme('breakpoints.mobile')}) {
          margin-bottom: 16px;
        }
      }
    }
  }
`;