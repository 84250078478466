import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: nowrap;

  @media (max-width: ${theme('breakpoints.mobile')}) {
    flex-wrap: wrap;
    gap: 0;
  }
`;
