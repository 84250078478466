const enum UserStatus {
	APPROVED = "approved",
	BLOCKED = "blocked",
	DISABLED = "disabled",
	NONE = "none",
	PENDING = "pending",
	REFUSED = "refused",
}

export default UserStatus;
