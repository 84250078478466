import styled from 'styled-components';
import { theme } from 'styled-tools';

interface ContentProps {
  variant: 'success' | 'error' | 'alert';
}

export const Content = styled.div<ContentProps>`
  align-items: center;
  background-color: ${({ variant }) => theme(`colors.${variant}.bg`)};
  border: 1px solid transparent;
  border-radius: 2px;
  display: flex;
  height: 60px;
  max-width: 100%;
  padding: 0 22px;
  transition: all 0.25s ease;
  width: 100%;

  &.hiden {
    background-color: transparent;
    border: none;
    height: 28px;

    p,
    button {
      display: none;
    }
  }

  & > p {
    flex: 1;

    span {
      color: ${({ variant }) => theme(`colors.${variant}.main`)};
    }
  }

  button {
    background-color: transparent;
  }

  &:hover {
    border-color: ${({ variant }) => theme(`colors.${variant}.main`)};
  }
`;
