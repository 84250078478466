const enum Product {
	BRAEX = "braex",
	BANKAI = "bankai",
	BACKOFFICE = "backoffice",
	DASHBOARD = "dashboard",
	TOKENAIM = "tokenaim",
	CUSTOM = "custom",
}

export default Product;
