import { Icon } from '@2ndmarket/components';
import { formatCpfCnpj } from '@2ndmarket/helpers';
import { useMe, useWallet } from '@2ndmarket/services';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useCashinInfo from '@2ndmarket/services/src/fiat/useCashinInfo';

interface Props {
  openTED: boolean;
  setOpenTED: React.Dispatch<React.SetStateAction<boolean>>;
}

const TEDDrawer: React.FC<Props> = ({ openTED, setOpenTED }) => {
  const { data: me } = useMe();
  const {
    data: wallet,
    loading: walletLoading,
    error: walletError,
  } = useCashinInfo();

  if (typeof navigator !== 'undefined') {
    var sharePromise = navigator.share;
  }

  async function share() {
    const shareData = {
      title: 'Dados da conta',
      text:
        'Esta conta só recebe depósitos do CPF: ' +
        formatCpfCnpj(me.cpf_cnpj) +
        '\nAgência: ' +
        wallet.operation_list.TED.bank_branch +
        '\nConta: ' +
        wallet.operation_list.TED.bank_account_number +
        '\nCPF: ' +
        wallet.operation_list.FIATPIXCASHIN.pix_key +
        '\nInstituição: ' +
        wallet.operation_list.TED.bank_name +
        ' ' +
        wallet.operation_list.TED.bank_code,
    };
    navigator.share(shareData);
  }

  function copyData(data: string) {
    navigator.clipboard.writeText(data);
  }

  return (
    <Drawer
      open={openTED}
      anchor="right"
      role="presentation"
      onClose={() => setOpenTED(false)}
    >
      <Box p={4}>
        <Stack
          gap={16}
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          pr={6}
          mb={3}
        >
          <IconButton
            onClick={() => setOpenTED(false)}
            sx={{ '& i': { color: 'grey.300' } }}
          >
            <Icon size={15} name="close" />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
          >
            TED
          </Typography>
        </Stack>
        <Box
          sx={{
            width: '360px',
            height: 'calc(100svh - 120px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: 'calc(100vw - 64px)',
          }}
        >
          <Box>
            <Typography
              variant="body1"
              component="p"
              color="text.primary"
              sx={{ mb: 2 }}
            >
              Copie os dados da sua conta abaixo e cole nos
              respectivos campos de transfência da outra
              instituição:
            </Typography>
            {!walletLoading && !walletError && wallet.warning && (
              <Alert
                severity="warning"
                icon={<Icon name="info" size={18} />}
                sx={{
                  border: 1,
                  borderRadius: '3px',
                  borderColor: 'warning.main',
                  mb: 2,
                  alignItems: 'center',
                }}
              >
                {wallet.warning}
              </Alert>
            )}
            {!walletLoading &&
              !walletError &&
              wallet.information && (
                <Alert
                  severity="warning"
                  icon={<Icon name="info" size={18} />}
                  sx={{
                    border: 1,
                    borderRadius: '3px',
                    borderColor: 'warning.main',
                    mb: 2,
                    alignItems: 'center',
                  }}
                >
                  {wallet.information}
                </Alert>
              )}
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 0,
                mb: 4,
                '& li': {
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                  borderColor: 'grey.200',
                  '&:last-child': {
                    borderBottomWidth: '0',
                  },
                  '.MuiButtonBase-root': {
                    p: 0,
                    pr: '6px',
                  },
                  '.MuiListItemText-root': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    m: 0,
                    '& span': {
                      color: 'grey.300',
                    },
                    '& p': {
                      color: 'text.primary',
                      fontSize: '16px',
                    },
                  },
                  '.MuiListItemIcon-root': {
                    minWidth: 0,
                    ml: 2,
                  },
                },
              }}
            >
              {walletLoading || walletError ? (
                <ListItem disablePadding>
                  <ListItemText primary="Carregando dados..."></ListItemText>
                </ListItem>
              ) : (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        copyData(
                          formatCpfCnpj(
                            wallet.operation_list.FIATPIXCASHIN
                              .pix_key,
                          ),
                        )
                      }
                      dense
                    >
                      <ListItemText
                        primary="CNPJ"
                        secondary={formatCpfCnpj(
                          wallet.operation_list.FIATPIXCASHIN
                            .pix_key,
                        )}
                      />
                      <ListItemIcon>
                        <Icon name="copy" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        copyData(wallet.operation_list.TED.code)
                      }
                      dense
                    >
                      <ListItemText
                        primary={
                          wallet.operation_list.TED.bank_name
                        }
                        secondary={
                          wallet.operation_list.TED.code
                        }
                      />
                      <ListItemIcon>
                        <Icon name="copy" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        copyData(
                          wallet.operation_list.TED.branch,
                        )
                      }
                      dense
                    >
                      <ListItemText
                        primary="Agência"
                        secondary={
                          wallet.operation_list.TED.branch
                        }
                      />
                      <ListItemIcon>
                        <Icon name="copy" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        copyData(
                          wallet.operation_list.TED.account,
                        )
                      }
                      dense
                    >
                      <ListItemText
                        primary="Conta"
                        secondary={
                          wallet.operation_list.TED.account
                        }
                      />
                      <ListItemIcon>
                        <Icon name="copy" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => copyData('Bank.ai')}
                      dense
                    >
                      <ListItemText
                        primary="Favorecido"
                        secondary="Bank.ai"
                      />
                      <ListItemIcon>
                        <Icon name="copy" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </>
              )}
            </List>
          </Box>

          {
            //@ts-ignore
            sharePromise && (
              <Button
                type="button"
                color="secondary"
                variant="contained"
                fullWidth
                onClick={share}
              >
                Compartilhar
              </Button>
            )
          }
        </Box>
      </Box>
    </Drawer>
  );
};
export default TEDDrawer;
