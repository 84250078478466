import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';
import { 
  CryptoConversionEmpty,
  ICryptoConversion,
  IServiceResponse
} from '@2ndmarket/types';

const useCryptoConversion = (
  coin_type: string,
  value: number,
  value_type: string,
  trade_type?: string,
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<ICryptoConversion> => {
  const upperCoin = coin_type.toUpperCase();
  const urlParams = new URLSearchParams();
  urlParams.append('value', value.toString());
  urlParams.append('value_type', value_type.toUpperCase());
  if(trade_type){
    urlParams.append('trade_type', trade_type.toUpperCase());
  }
  const key = {
    endpoint: `/v4/crypto/users/${user_id}/coin/${upperCoin}/crypto-conversion/brl?${urlParams.toString()}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<ICryptoConversion, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? CryptoConversionEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useCryptoConversion;
