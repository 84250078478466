import { ReviewStep } from "@2ndmarket/helpers";

export interface ISelfie {
  user_id: number,
  status: ReviewStep,
  file: File | string | null,
  updated_at: string,
  created_at: string,
  expires_at: string,
}

export const SelfieEmpty: ISelfie = {
  user_id: 0,
  status: ReviewStep.NONE,
  file: '',
  updated_at: '',
  created_at: '',
  expires_at: '',
};