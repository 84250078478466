import AccountType from "../constants/AccountType";

export const AccountTypes = [
  {
    value: AccountType.SAVINGS_ACCOUNT,
    label: 'Conta poupança',
  },
  {
    value: AccountType.CHECKING_ACCOUNT,
    label: 'Conta Corrente',
  },
];