export const months = [
  {
    value: "Janeiro",
    initials: "jan",
    code: "01",
    defaultIndex: 0,
    alternativeIndex: 1,
  },
  {
    value: "Fevereiro",
    initials: "fev",
    code: "02",
    defaultIndex: 1,
    alternativeIndex: 2,
  },
  {
    value: "Março",
    initials: "mar",
    code: "03",
    defaultIndex: 2,
    alternativeIndex: 3,
  },
  {
    value: "Abril",
    initials: "abr",
    code: "04",
    defaultIndex: 3,
    alternativeIndex: 4,
  },
  {
    value: "Maio",
    initials: "mai",
    code: "05",
    defaultIndex: 4,
    alternativeIndex: 5,
  },
  {
    value: "Junho",
    initials: "jun",
    code: "06",
    defaultIndex: 5,
    alternativeIndex: 6,
  },
  {
    value: "Julho",
    initials: "jul",
    code: "07",
    defaultIndex: 6,
    alternativeIndex: 7,
  },
  {
    value: "Agosto",
    initials: "ago",
    code: "08",
    defaultIndex: 7,
    alternativeIndex: 8,
  },
  {
    value: "Setembro",
    initials: "set",
    code: "09",
    defaultIndex: 8,
    alternativeIndex: 9,
  },
  {
    value: "Outubro",
    initials: "out",
    code: "10",
    defaultIndex: 9,
    alternativeIndex: 10,
  },
  {
    value: "Novembro",
    initials: "nov",
    code: "11",
    defaultIndex: 10,
    alternativeIndex: 11,
  },
  {
    value: "Dezembro",
    initials: "dez",
    code: "12",
    defaultIndex: 11,
    alternativeIndex: 12,
  },
]