export interface IBanksResult {
  id?: number;
  name: string;
  code: string;
  mask_branch?: string;
  mask_account?: string;
}

export interface IBanks {
  totalRecords: number;
  pageSize: number;
  startRecord: number;
  lastPage: number;
  currentPage: number;
  results: [IBanksResult];
}

export const BanksEmpty: IBanks = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [
    {
      id: 0,
      name: '',
      code: '',
      mask_branch: '',
      mask_account: '',
    },
  ],
};
