import { ReviewStep } from '@2ndmarket/helpers';

export interface IPin {
  status: ReviewStep;
}

export const PinEmpty: IPin = {
  status: ReviewStep.PENDING,
};

export interface IPasswordInput {
  password: string;
  password_confirm: string;
}

export const PasswordInputEmpty: IPasswordInput = {
  password: '',
  password_confirm: '',
};
