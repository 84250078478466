const enum TypesReceipt {
  P2P = 'p2p',
  BTC = 'btc',
  BILL = 'bill',
  TED = 'ted',
  PIX = 'pix',
  OTC = 'otc',
  CARD = 'bankai_card',
  BTCOTC = 'btcotc',
  BTCBILL = 'btcbill',
};

export default TypesReceipt;