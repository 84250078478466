const enum Operation {
  Activate = "activate",
  Any = 'any',
  Create = "create",
  Delete = "delete",
  List = "list",
  Read = "read",
  Update = "update",
}

export default Operation;
