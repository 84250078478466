import styled from 'styled-components';
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography as typographyST,
  TypographyProps,
} from 'styled-system';
import { prop, switchProp } from 'styled-tools';
import { typography } from '../themes';

export type TextProps = ColorProps &
  SpaceProps &
  TypographyProps & {
    variant?:
      | 'heading1'
      | 'heading2'
      | 'heading3'
      | 'heading4'
      | 'body1'
      | 'body2'
      | 'caption';
  };

const Text = styled.p<TextProps>`
  ${switchProp(prop('variant', 'body1'), {
    heading1: typography['heading1'],
    heading2: typography['heading2'],
    heading3: typography['heading3'],
    heading4: typography['heading4'],
    body1: typography['body1'],
    body2: typography['body2'],
    caption: typography['caption'],
  })}

  ${color}
  ${space}
  ${typographyST}
`;

Text.displayName = 'Text';

export default Text;
