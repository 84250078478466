import authenticatedFetcher from '../../authenticatedFetcher';

const sendBtcFind = function (
  destination: string | number,
  coin: string,
) {
  const upperCoin = coin.toUpperCase();
  return authenticatedFetcher({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    endpoint: `/v4/crypto/coin/${upperCoin}/find/${destination}`,
  });
};

export default sendBtcFind;
