import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Icon, themes } from '@2ndmarket/components';
import {
  AppContext,
  LocalStorage,
  fiatFormat,
  toBitcoin,
} from '@2ndmarket/helpers';
import {
  useBalance,
  useCryptoBalance,
  useCryptoWallet,
} from '@2ndmarket/services';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountData from './AccountData';

const boxStyle = {
  height: '80px',
  cursor: 'pointer',
  transition:
    'box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    boxShadow: '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
  },
};

interface Props {
  toggleBalance?: boolean;
  accountData?: boolean;
  crypto?: any;
}

const HeaderBalance: React.FC<Props> = ({
  children,
  toggleBalance,
  accountData,
  crypto,
}) => {
  const { data: walletCrypto } = useCryptoWallet(crypto);
  const { data: cryptoBalance } = useCryptoBalance(
    crypto,
    walletCrypto[0]?.id,
  );

  const context = useContext(AppContext);
  const router = useRouter();

  const toggleEye = () => {
    context?.setShowBalance(!context.showBalance);
    localStorage.setItem(
      LocalStorage.BANKAI_BALANCE_VIEW,
      (!context?.showBalance).toString(),
    );
  };

  return (
    <>
      <Box
        width="100%"
        height="80px"
        maxWidth="956px"
        display="flex"
        flexShrink={0}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderRadius: '3px',
          backgroundColor: 'common.white',
          '.innerContent': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: 2,
            width: '100%',
          },
        }}
      >
        {children ? (
          children
        ) : toggleBalance ? (
          <Box className="innerContent">
            <Box display="grid" gridTemplateColumns="1fr">
              <Typography
                component="p"
                variant="body2"
                color="grey.300"
              >
                Saldo
              </Typography>
              <Typography
                variant="h3"
                component="h3"
                align="center"
                color="text.primary"
                textAlign="left"
                whiteSpace="nowrap"
              >
                {context?.showBalance
                  ? toBitcoin(cryptoBalance.free_balance) +
                    ' ' +
                    crypto
                  : '*'}
              </Typography>
            </Box>
            <IconButton
              onClick={() => toggleEye()}
              sx={{
                width: 42,
                height: 42,
                backgroundColor: 'common.white',
              }}
            >
              <Icon
                name={context?.showBalance ? 'view' : 'hide'}
                size={20}
                color={themes.bankai.palette.grey[300]}
              />
            </IconButton>
          </Box>
        ) : (
          <Box
            className="innerContent"
            onClick={() => router.push('/carteira-digital')}
            sx={boxStyle}
          >
            <Box display="grid" gridTemplateColumns="1fr">
              <Typography
                component="p"
                variant="body2"
                color="grey.300"
              >
                Saldo
              </Typography>
              <Typography
                variant="h3"
                component="h3"
                align="center"
                color="text.primary"
                textAlign="left"
                whiteSpace="nowrap"
              >
                {context?.showBalance
                  ? toBitcoin(cryptoBalance.free_balance) +
                    ' ' +
                    crypto
                  : '*'}
              </Typography>
            </Box>
            <Icon
              name="arrow"
              rotate="-90deg"
              size={16}
              color={themes.bankai.palette.primary.main}
            />
          </Box>
        )}
      </Box>
      {accountData && (
        <AccountData address={walletCrypto[0]?.address} />
      )}
    </>
  );
};

export default HeaderBalance;
