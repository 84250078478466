import { useState } from 'react';

import AmountStep from './AmountStep';
import InfoStep from './InfoStep';

const TransferBankai: React.FC = () => {
  const [currentStep, setCurrentStep] = useState('step1');
  const [transferAmount, setTransferAmount] =
    useState<number>(0);
  const [billData, setBillData] = useState({});
  const [billId, setBillId] = useState(0);
  const [cashinId, setCashinId] = useState(0);

  return (
    <>
      {'step1' == currentStep && (
        <AmountStep
          setStep={setCurrentStep}
          setTransferAmount={setTransferAmount}
          setBillData={setBillData}
          setBillId={setBillId}
          setCashinId={setCashinId}
        />
      )}

      {'step2' == currentStep && (
        <InfoStep
          setStep={setCurrentStep}
          billData={billData}
          cashinId={cashinId}
        />
      )}
    </>
  );
};
export default TransferBankai;
