import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
import { typography } from '../themes';

interface BoxProps {
  active: boolean;
}

const linkMobile = css<BoxProps>`
  background-color: transparent;
  color: ${theme('colors.secondary.1')};
  font-weight: 500;
  font-size: 14px;

  ${({ active }) =>
    active &&
    css`
      i,
      span {
        color: ${theme('colors.primary.main')};
      }
    `}
`;

const buttonStyle = css<BoxProps>`
  align-items: center;
  background-color: transparent;
  color: ${theme('colors.secondary.1')};
  cursor: pointer;
  display: flex;
  height: 56px;
  padding: 0 30px;
  gap: 25px;
  width: 100%;

  &:hover {
    color: ${theme('colors.primary.main')};
  }

  ${typography.body2}

  & > div {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 25px;
  }

  & > span {
    flex: 1;
    text-align: left;
  }

  ${({ active }) =>
    active &&
    css`
      span {
        color: ${theme('colors.primary.main')};
      }

      & > i {
        transform: rotate(180deg);
      }
    `}
`;

export const DesktopContent = styled.div`
  @media (max-width: ${theme('breakpoints.mobileBackoffice')}) {
    display: none;
  }
`;

export const MobileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  .footerMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 9;
    border-top: 1px solid ${theme('colors.secondary.4')};
  }

  @media (min-width: ${theme('breakpoints.mobileBackoffice')}) {
    display: none;
  }
`;

export const Content = styled.div`
  display: block;

  i {
    color: ${theme('colors.secondary.3')};
    font-size: 18px;
  }

  @media (max-width: ${theme('breakpoints.mobileBackoffice')}) {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-around;
  }
`;

export const Box = styled.div<BoxProps>`
  border-bottom: 1px solid ${theme('colors.secondary.4')};
  max-height: 55px;
  overflow: hidden;
  border-right: 1px solid transparent;
  & + & {
    margin-top: -1px;
    @media only screen and (max-width: ${theme('breakpoints.mobileBackoffice')}) {
      border-top: 1px solid ${theme('colors.secondary.4')};
    }
  }

  ${({ active }) =>
    active &&
    css`
      border-right: 1px solid ${theme('colors.primary.main')};
      max-height: 1000px;
      @media only screen and (max-width: ${theme(
          'breakpoints.mobileBackoffice',
        )}) {
        border: none;
      }
    `}
`;

export const Button = styled.button`
  ${buttonStyle}
`;

export const ButtonChildren = styled.a<BoxProps>`
  align-items: center;
  color: ${theme('colors.secondary.2')};
  display: flex;
  height: 30px;
  padding: 0 70px;
  font-size: 14px;
  transition: color 0.25s ease;

  @media only screen and (max-width: ${theme('breakpoints.mobileBackoffice')}) {
    padding: 0;
    padding-left: 45px;
  }

  &:hover {
    color: ${theme('colors.primary.main')};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${theme('colors.secondary.1')};
    `}
`;

export const BtnMobile = styled.a`
  ${linkMobile}
  padding: 18px 0;
  justify-content: space-between;
  display: flex;

  div {
    display: flex;
    gap: 28px;
  }
`;

export const MenuMobile = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #fafafa;

  .headerMenu {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
    padding: 22px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: transparent;
      transform: rotate(180deg);
    }

    h1 {
      font-weight: 600;
      font-size: 16px;
      color: ${theme('colors.secondary.1')};
    }
  }

  .contentLinks {
    display: flex;
    flex-direction: column;
    margin: 50px 30px;
  }
`;
