import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import {
  fiatRequest,
  fiatVerify,
  useMe,
} from '@2ndmarket/services';
import {
  HttpError,
  HttpStatus,
  Masks,
  OnboardingProgress,
  ReviewStep,
} from '@2ndmarket/helpers';
import {
  MaskedInput,
  ModalDialog,
  MuiLoading,
} from '@2ndmarket/components';
import { boxLeftStyle, wrapperStyle } from './style';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const OpenAccount: React.FC = () => {
  const {
    data: me,
    loading: loadingMe,
    error: errorMe,
    mutate,
  } = useMe();

  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [codeSent, setCodeSent] = useState(false);
  const [countDown, setCountDown] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const timerRef = useRef<HTMLButtonElement>(null);

  const resquestFiat = () => {
    setCountDown(true);
    setCodeSent(true);
    setErrorCode('');

    fiatRequest()
      .then(() => {
        mutate();
      })
      .catch((error: HttpError) => {
        setErrorMessage(error.error);
        setErrorModal(true);
      });
  };

  const verifyCode = () => {
    if (code == '') {
      setErrorCode('Código é obrigatório');
      return;
    }

    fiatVerify(code)
      .then(() => {
        mutate();
      })
      .catch((error: HttpError) => {
        if (
          error.status ===
          HttpStatus.ClientErrorUnprocessableEntity
        ) {
          for (let fieldError of error.errors) {
            setErrorCode(fieldError.message);
          }
        } else {
          setErrorMessage(error.error);
          setErrorModal(true);
        }
      })
      .finally(() => {});
  };

  const handleCountDown = () => {
    let timer = 120;
    const intervalId = window.setInterval(() => {
      if (timerRef.current) {
        if (timer <= 0) {
          clearInterval(intervalId);
          timerRef.current.textContent = '';
          setCodeSent(false);
          setCountDown(false);
          return;
        }

        let initialMinutes = String(timer / 60);
        let initialSeconds = String(timer % 60);
        let minutes = parseInt(initialMinutes, 10);
        let seconds = parseInt(initialSeconds, 10);

        timerRef.current.textContent =
          ('0' + minutes).slice(-2) +
          ':' +
          ('0' + seconds).slice(-2);
        timer--;
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  };

  const toggleValue = (value: string) => {
    const newCode = parseInt(value.replace(/\D/g, ''));
    if (isNaN(newCode)) {
      setCode('');
    } else {
      setCode(value);
    }
  };

  useEffect(() => {
    if (!countDown) return;
    handleCountDown();
  }, [countDown]);

  useEffect(() => {
    if (loadingMe || errorMe) {
      return;
    }
    if (OnboardingProgress.NEWUSER == me.onboarding_progress) {
      window.location.href = `${process.env.NEXT_PUBLIC_DASHBOARD_URL}`;
    }
  }, [me, loadingMe, errorMe]);

  return OnboardingProgress.NEWUSER == me.onboarding_progress ? (
    <MuiLoading loading />
  ) : OnboardingProgress.BASIC == me.onboarding_progress ? (
    <>
      <Modal open>
        <Box sx={wrapperStyle}>
          <Box sx={boxLeftStyle}>
            {codeSent ||
            ReviewStep.PENDING ==
              me.onboarding_digital_account.status ? (
              <>
                <Image
                  alt=""
                  src="/phone-code.svg"
                  width="39px"
                  height="39px"
                />
                <Typography
                  component="p"
                  variant="body1"
                  color="grey.300"
                  fontSize={14}
                >
                  2/2
                </Typography>
              </>
            ) : (
              <>
                {' '}
                <Image
                  alt=""
                  src="/open-account.svg"
                  width="39px"
                  height="39px"
                />
                <Typography
                  component="p"
                  variant="body1"
                  color="grey.300"
                  fontSize={14}
                >
                  1/2
                </Typography>
              </>
            )}
          </Box>
          <Box sx={{ p: 4 }}>
            <Typography
              variant="h3"
              component="h3"
              color="text.primary"
              mb={1}
            >
              Abra sua carteira digital
            </Typography>
            <Typography component="p" variant="body1">
              Você ainda não possui carteira digital! Abra a sua
              carteira para aproveitar os recursos de Pix, TED e
              Boleto!
            </Typography>
            {codeSent ||
            ReviewStep.PENDING ==
              me.onboarding_digital_account.status ? (
              <>
                <TextField
                  fullWidth
                  name="code"
                  margin="dense"
                  label="Código"
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      inputMode: 'numeric',
                      mask: Masks.CODE,
                    },
                  }}
                  error={errorCode != ''}
                  helperText={errorCode}
                  onChange={event =>
                    toggleValue(event.target.value)
                  }
                  sx={{ mt: 2, mb: 1 }}
                />
                {!countDown ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={resquestFiat}
                    sx={{ mb: 1 }}
                  >
                    Reenviar
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled
                    ref={timerRef}
                    sx={{ mb: 1 }}
                  />
                )}
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={verifyCode}
                >
                  Validar
                </Button>
              </>
            ) : (
              <Button
                color="primary"
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={() => resquestFiat()}
              >
                Abrir carteira
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      {errorModal && (
        <ModalDialog
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorMessage}
          onClose={() => setErrorModal(false)}
          errorTitle="Problema ao enviar código"
        />
      )}
    </>
  ) : null;
};

export default OpenAccount;
