import { SEO, themes } from '@2ndmarket/components';

interface Props {
  title: string;
  description: string;
}

const BankaiSEO: React.FC<Props> = ({ title, description }) => {
  const url = 'https://app.bank-ai.io';

  const params = {
    appName: 'Bank.ai',
    title: title,
    description: description,
    image: url + '/banner.jpg',
    imageWidth: '1080',
    imageHeight: '1080',
    themeColor: themes.bankai.palette.primary.main,
    url: url,
  };

  return <SEO {...params}></SEO>;
};

export default BankaiSEO;
