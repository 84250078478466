export interface IBill {
  id: number,
  bill_register: {
    id: number,
    updated_by: number,
    created_at: Date | string,
    updated_at: Date | string,
    uuid: any,
    provider_tx_id: any,
    register_id: number
  },
  bank_id: number,
  barcode: string,
  beneficiary_name: string,
  beneficiary_number: string,
  beneficiary_record: string,
  beneficiary_type: string,
  cancellation_datetime: string,
  confirmation_date: string,
  creation_datetime: Date | string,
  custom_amount: string,
  due_date: string,
  fee: string,
  maximum_amount: number,
  minimum_amount: number,
  next_workday: string,
  nominal_amount: number,
  paid_amount: string,
  payer_name: string,
  payer_number: string,
  payer_record: string,
  payer_type: string,
  payment_datetime: string,
  provider_tx_id: string,
  rejection_code: string,
  rejection_date: string,
  typeable_line: string,
  updated_amount: number,
  wallet_id: number,
  created_at: Date | string,
  updated_by: number,
  updated_at: Date | string
}

export const BillEmpty: IBill = {
  id: 0,
  bill_register: {
    id: 0,
    updated_by: 0,
    created_at: "-",
    updated_at: "-",
    uuid: '-',
    provider_tx_id: '-',
    register_id: 0
  },
  bank_id: 0,
  barcode: '-',
  beneficiary_name: '-',
  beneficiary_number: '-',
  beneficiary_record: '-',
  beneficiary_type: '-',
  cancellation_datetime: '-',
  confirmation_date: '-',
  creation_datetime: "-",
  custom_amount: '-',
  due_date: '-',
  fee: '-',
  maximum_amount: 0,
  minimum_amount: 0,
  next_workday: '-',
  nominal_amount: 0,
  paid_amount: '-',
  payer_name: '-',
  payer_number: '-',
  payer_record: '-',
  payer_type: '-',
  payment_datetime: '-',
  provider_tx_id: '-',
  rejection_code: '-',
  rejection_date: '-',
  typeable_line: '-',
  updated_amount: 0,
  wallet_id: 0,
  created_at: "-",
  updated_by: 0,
  updated_at: "-"    
}