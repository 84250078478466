import React from 'react';
import Image from 'next/image';

import { Page500Props } from '@2ndmarket/types';
import { themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Page500 = ({
  imageWidth,
  imageHeight,
  image,
  title,
  mailto,
  description,
}: Page500Props) => {
  return (
    <Grid
      component="main"
      sx={{
        width: '100%',
        height: '100svh',
        backgroundColor: themes.bankai.palette.grey[900],
      }}
    >
      <Container maxWidth="sm">
        <Grid
          paddingTop={20}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Box>
            <Image
              alt=""
              src={image}
              width={imageWidth}
              height={imageHeight}
            />
          </Box>
        </Grid>
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <Typography
              mt={3}
              variant="h2"
              component="h2"
              textAlign="center"
              color="text.primary"
            >
              {title}
            </Typography>
            <Typography
              mt={2}
              component="p"
              variant="body1"
              textAlign="center"
              color="text.secondary"
            >
              {description}
            </Typography>
          </Box>
        </Grid>
        <Grid display="flex" justifyContent="center" mb={3}>
          <Button
            component="a"
            color="primary"
            variant="contained"
            sx={{ marginTop: 3 }}
            href="/"
          >
            Voltar para o início
          </Button>
        </Grid>
        <Grid display="flex" justifyContent="center">
          <Link href={mailto}>
            <Typography
              component="p"
              variant="body1"
              color="primary.main"
            >
              Preciso de ajuda
            </Typography>
          </Link>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Page500;
