import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalDialog, MuiLoader } from '@2ndmarket/components';
import {
  HttpError,
  HttpStatus,
  formatCpfCnpj,
  getInitials,
  yupPt,
} from '@2ndmarket/helpers';
import { sendBtcFind, useFavorites } from '@2ndmarket/services';
import {
  FiatFavoritesEmpty,
  IFiatFavorites,
} from '@2ndmarket/types';
import { ContentTitle } from '../../../../components';
import FavoriteDrawer from './FavoriteDrawer';

import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  setStep: any;
  setTarget: any;
  setName: any;
  setFavorite: any;
  cryptoName: string;
}

const ReceiverStep: React.FC<Props> = ({
  setStep,
  setTarget,
  setName,
  setFavorite,
  cryptoName,
}) => {
  const steps = [
    'Início',
    cryptoName.toUpperCase(),
    'Valor',
    'Destinatário',
  ];
  const [favoriteOpen, setFavoriteOpen] = useState(false);
  const [selectedFavorite, setSelectedFavorite] =
    useState<IFiatFavorites>(FiatFavoritesEmpty[0]);
  const {
    data: favorites,
    loading: favoritesLoading,
    error: favoritesError,
  } = useFavorites(cryptoName, null);

  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  useEffect(() => {
    if (selectedFavorite != FiatFavoritesEmpty[0]) {
      setTarget(selectedFavorite.cpf_cnpj);
      setName(selectedFavorite.name);
      setFavorite(true);
      setStep('step3');
    }
  }, [
    setSelectedFavorite,
    selectedFavorite,
    setTarget,
    setName,
    setFavorite,
    setStep,
  ]);

  const schema = yupPt.object().shape({
    destination: yupPt.string().required(),
  });

  const defaultValues = { destination: '' };

  const {
    handleSubmit,
    setError,
    formState: { errors },
    register,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowErrorModal(true);
      setErrorModalMessage(error.error);
    }
  };

  const onSubmit = (data: any) => {
    const destinationReplaced = data.destination.replace(
      /[^\d]/g,
      '',
    );
    setLoading(true);
    sendBtcFind(destinationReplaced, cryptoName)
      .then((data: any) => {
        setTarget(data.destination);
        setName(data.name);
        setFavorite(data.is_favorite);
        setStep('step3');
      })
      .catch(error => {
        handleFieldError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <ContentTitle title="Transferir" steps={steps} />
      <TextField
        {...register('destination', { required: true })}
        sx={{ marginBottom: 5 }}
        fullWidth
        label="CPF, CNPJ ou Endereço da carteira"
        error={Boolean(errors.destination)}
        helperText={
          errors.destination && errors.destination.message
        }
      />
      <Typography variant="body1" sx={{ color: 'grey.300' }}>
        Favoritos
      </Typography>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mx: '-16px',
        }}
      >
        {favoritesLoading ? (
          <ListItem sx={{ textAlign: 'center' }}>
            Carregando favoritos...
          </ListItem>
        ) : favorites.results.length > 0 && !favoritesError ? (
          <>
            {favorites.results
              .slice(0, 5)
              .map((data: any, key: number) => (
                <ListItem key={key} sx={{ p: 0 }}>
                  <ListItemButton
                    onClick={() => (
                      setTarget(data.cpf_cnpj),
                      setName(data.name),
                      setFavorite(true),
                      setStep('step3')
                    )}
                    sx={{ gap: '10px' }}
                  >
                    <Avatar
                      sx={{
                        width: 56,
                        height: 56,
                        cursor: 'pointer',
                        marginLeft: '0',
                        bgcolor: 'common.white',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: 'primary.main' }}
                      >
                        {getInitials(data.name)}
                      </Typography>
                    </Avatar>
                    <ListItemText>
                      <Typography
                        component="p"
                        variant="subtitle1"
                      >
                        {data.name}
                      </Typography>
                      <Typography
                        component="p"
                        variant="body2"
                        color="grey.300"
                      >
                        CPF {formatCpfCnpj(data.cpf_cnpj)}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
          </>
        ) : (
          <ListItem sx={{ textAlign: 'center' }}>
            Você ainda não possui favoritos.
          </ListItem>
        )}
      </List>
      <Button
        type="button"
        onClick={() => setFavoriteOpen(true)}
        variant="text"
        fullWidth
        sx={{
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        Ver todos
      </Button>
      <Box
        component="hr"
        sx={{
          border: 'none',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'grey.200',
          my: 3,
        }}
      ></Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => setStep('step1')}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Continuar
        </Button>
      </Box>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: 'common.white',
          }}
        >
          <MuiLoader loaderState={loading} />
        </Backdrop>
      )}
      {showErrorModal && (
        <ModalDialog
          error
          errorTitle="Problema ao selecionar destinatário"
          maxWidth="sm"
          open={showErrorModal}
          errorMessage={errorModalMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
      <FavoriteDrawer
        favoriteOpen={favoriteOpen}
        setFavoriteOpen={setFavoriteOpen}
        setSelectedFavorite={setSelectedFavorite}
        cryptoName={cryptoName}
      />
    </Box>
  );
};
export default ReceiverStep;
