import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const sendFiatTed = function (
  pin: string,
  bank: string,
  branch: string,
  date: string | null,
  agency_digit: any,
  account: string,
  account_type: string,
  amount: number,
  description: string,
  recipient: string,
  person_type: string,
  cpf_cnpj: string,
  user_id: number | AppConstants.ME = AppConstants.ME,
) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/users/${user_id}/ted`,
    headers: {
      'Content-Type': 'application/json',
      'user-pin': pin,
    },
    method: 'POST',
    body: JSON.stringify({
      bank_code: bank,
      branch: branch,
      schedule_date: date,
      branch_digit: agency_digit,
      account: account,
      account_type: account_type,
      amount: amount,
      description: description,
      beneficiary: {
        name: recipient,
        document: {
          person_type: person_type,
          cpf_cnpj: cpf_cnpj,
        },
      },
    }),
  });
};

export default sendFiatTed;
