import { FC } from 'react';
import { ThemeProvider as ThemeProviderProducts } from 'styled-components';
import { ThemeProvider as ThemeProviderStyled } from '@mui/material/styles';

import { default2nd } from '.';
import GlobalStyle from './2nd/globalStyles';

type ThemeProviderProps = {
  children: any;
  theme?: object | any;
};

const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
  theme = default2nd,
}) => {
  switch (theme.name) {
    case 'authentication':
      return (
        <ThemeProviderStyled theme={theme}>
          <link
            rel="stylesheet"
            href="https://2nd-cdn-bucket.s3.amazonaws.com/2nd-market-icons/v12/styles.css"
          />
          <GlobalStyle />
          {children}
        </ThemeProviderStyled>
      );
    case 'bankai':
      return (
        <ThemeProviderStyled theme={theme}>
          <link
            rel="stylesheet"
            href="https://2nd-cdn-bucket.s3.amazonaws.com/2nd-market-icons/v10/styles.css"
          />
          <GlobalStyle />
          {children}
        </ThemeProviderStyled>
      );
    case 'bitcoin':
      return (
        <ThemeProviderStyled theme={theme}>
          <link
            rel="stylesheet"
            href="https://2nd-cdn-bucket.s3.amazonaws.com/2nd-market-icons/v10/styles.css"
          />
          <GlobalStyle />
          {children}
        </ThemeProviderStyled>
      );
    default:
      return (
        <ThemeProviderProducts theme={theme}>
          <link
            rel="stylesheet"
            href="https://2nd-cdn-bucket.s3.amazonaws.com/2nd-market-icons/v10/styles.css"
          />
          <GlobalStyle />
          {children}
        </ThemeProviderProducts>
      );
  }
};

export default ThemeProvider;
