export interface IFiatFavorites {
  name: string,
  cpf_cnpj: string,
  operation: string,
  institution: string,
  agency: string,
  account: string,
  pix_key: string,
  pix_type: string,
  user_id: number
}

export const FiatFavoritesEmpty: IFiatFavorites[] = [
  {
    "name": "",
    "cpf_cnpj": "",
    "operation": "",
    "institution": "",
    "agency": "",
    "account": "",
    "pix_key": "",
    "pix_type": "",
    "user_id": 0
  }
];