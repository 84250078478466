import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon, MaskedInput } from '@2ndmarket/components';
import {
  HttpError,
  HttpStatus,
  IntentStatus,
  Masks,
  OperationType,
  dayjsUtc,
  yupPt,
} from '@2ndmarket/helpers';
import {
  sendFiatTed,
  useBusinessHours,
} from '@2ndmarket/services';
import { IBusinessHours } from '@2ndmarket/types';
import {
  ContentTitle,
  TransactionModal,
  TransferAwait,
} from '../../../../components';
import { IReviewData, ITransferData } from './Types';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  transferData: ITransferData;
  setReviewData: React.Dispatch<
    React.SetStateAction<IReviewData>
  >;
  setFieldsErrors: React.Dispatch<
    React.SetStateAction<HttpError | undefined>
  >;
}

interface TransferData {
  id: number;
  type: string;
  register: boolean;
}

const PasswordStep: React.FC<Props> = ({
  setStep,
  transferData,
  setReviewData,
  setFieldsErrors,
}) => {
  const {
    data: businessHours,
    loading: businessHoursLoading,
    loading: businessHoursError,
  } = useBusinessHours();
  const [showPassword, setShowPassword] = useState(false);
  const [transferDataPromise, setTransferDataPromise] =
    useState<TransferData>();
  const [transactionId, setTransactionId] = useState('');
  const recipePath = '/carteira-digital/comprovante/ted/';
  const [modal, setModal] = useState(false);
  const [intentStatus, setIntentStatus] = useState(
    IntentStatus.NONE,
  );
  const modalTitle = 'Transferência Bank.ai';
  const [modalText, setModalText] = useState<string | null>('');
  setReviewData(transferData);

  const schema = yupPt.object().shape({
    password: yupPt.string().min(6).required(),
  });
  const defaultValues = { password: '' };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: { password: string }) {
    let personType = null;

    if (transferData.cpf_cnpj.length <= 11) {
      personType = 'CPF';
    } else {
      personType = 'CNPJ';
    }
    const accountNumber = transferData.account.replace(
      /[^0-9.]/g,
      '',
    );

    let trasferDate = null;
    if (transferData.schedule_date != trasferDate) {
      trasferDate = transferData.schedule_date
        .split('/')
        .reverse()
        .join('-');
    }

    setIntentStatus(IntentStatus.NONE);
    setModal(true);
    setModalText('');
    sendFiatTed(
      data.password,
      transferData.bank_code,
      transferData.branch,
      trasferDate,
      null,
      accountNumber,
      transferData.account_type,
      transferData.amount,
      '',
      transferData.recipient,
      personType,
      transferData.cpf_cnpj,
    )
      .then(() => {
        const responseTED = localStorage.getItem(
          '@locationHeader',
        );

        const response = responseTED
          ?.split('?')[1]
          .split('&')
          .reduce(
            (accumulator: any, item: any) => {
              const [key, value] = item.split('=');
              accumulator[key] = value;
              return accumulator;
            },
            {
              amount: transferData.amount,
            },
          );
        setTransferDataPromise({
          id: parseInt(response.intent_id),
          type: response.type,
          register: Boolean(response.is_register),
        });
      })
      .catch((error: HttpError) => {
        setValue('password', '');
        setIntentStatus(IntentStatus.FAILURE);
        if (
          error.status ===
          HttpStatus.ClientErrorUnprocessableEntity
        ) {
          setIntentStatus(IntentStatus.FORM_ERROR);
          setFieldsErrors(error);
        } else {
          setModalText(error.error);
          setIntentStatus(IntentStatus.FAILURE);
        }
      });
  }

  const steps = [
    'Início',
    'TED',
    'Valor',
    'Destinatário',
    'Revisão',
    'Senha',
  ];

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <ContentTitle title="Transferir" steps={steps} />
      {!businessHoursLoading && !businessHoursError && (
        <Alert
          variant="outlined"
          severity="warning"
          icon={<Icon name="clock" size={18} />}
          sx={{
            alignItems: 'center',
            bgcolor: 'warning.contrastText',
            display: 'inline-flex',
            color: 'warning.main',
            py: 0,
            mt: -1,
            mb: 2,
          }}
        >
          {businessHours.map(
            (item: IBusinessHours, key: number) => {
              if (item.payment_method == OperationType.TED) {
                return (
                  <Box key={key}>
                    Disponível das{' '}
                    {dayjsUtc(item.start, 'HH:mm:ss').format(
                      'H[h]m',
                    )}{' '}
                    às{' '}
                    {dayjsUtc(item.end, 'HH:mm:ss').format(
                      'H[h]m',
                    )}
                  </Box>
                );
              }
            },
          )}
        </Alert>
      )}
      <TextField
        fullWidth
        id="password"
        label="Senha"
        margin="dense"
        autoComplete="off"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          inputComponent: MaskedInput,
          inputProps: {
            inputMode: 'numeric',
            mask: Masks.CODE,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(
                  e: React.MouseEvent<HTMLButtonElement>,
                ) => e.preventDefault()}
                edge="end"
                sx={{
                  p: 1.5,
                  mr: -1,
                  '& i': { color: 'grey.300' },
                }}
              >
                {showPassword ? (
                  <Icon size={20} name="view" />
                ) : (
                  <Icon size={20} name="hide" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...register('password', { required: true })}
        error={Boolean(errors.password)}
        helperText={errors.password && errors.password.message}
        sx={{ mb: 4 }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          type="button"
          variant="link"
          onClick={() => setStep('step3')}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Confirmar transferência
        </Button>
      </Box>
      {transferDataPromise?.id && (
        <TransferAwait
          data={transferDataPromise}
          setIntentStatus={setIntentStatus}
          setIntentDescription={setModalText}
          redirect="/carteira-digital/comprovante/p2p/"
          setTransactionId={setTransactionId}
        />
      )}
      {modal && (
        <TransactionModal
          open={modal}
          title={modalTitle}
          text={modalText}
          status={intentStatus}
          onClose={() => setStep('step1')}
          target={recipePath + transactionId}
          reviewTarget={() => setStep('step3')}
        />
      )}
    </Box>
  );
};
export default PasswordStep;
