import useSWR from 'swr';
import { AppConstants } from '@2ndmarket/helpers';
import { IServiceResponse, IStatement, IStatementRequest, StatementEmpty } from '@2ndmarket/types';
import authenticatedFetcher from '../authenticatedFetcher';

const useStatement = (
  params?: IStatementRequest,
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IStatement> => {
  const urlParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key as keyof IStatementRequest];
    if (value !== '' && value !== undefined) {
      urlParams.append(key, value.toString());
    }
  }

  const key = {
    endpoint: `/v4/fiat/users/${user_id}/statement?${urlParams.toString()}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IStatement, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? StatementEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useStatement;
