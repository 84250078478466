import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { IPrice, IServiceResponse, PriceEmpty } from '@2ndmarket/types';

const SECONDS_IN_MILLISECONDS = 1000;

const usePrice = (crypto: string): IServiceResponse<IPrice> => {
  const coin_type = crypto.toUpperCase();

  const key = {
    endpoint: `/v4/crypto/coin/${coin_type}/exchange-rate/brl`,
  };

  const options = {
    refreshInterval: 10 * SECONDS_IN_MILLISECONDS,
  };

  const { data, error, mutate } = useSWR<IPrice, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? PriceEmpty,
    loading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export default usePrice;
