import { useRouter } from 'next/router';
import Image from 'next/image';
import { Squares } from '@2ndmarket/components';
import { IntentStatus, Status } from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface Props {
  title?: string;
  text?: string | null;
  status?: IntentStatus;
  onClose: () => void;
  open: boolean;
  target?: string;
  reviewTarget?: () => void;
}

enum ImageIcons {
  CONFIRMED = '/transaction-confirmed.svg',
  PENDING = '/transaction-pending.svg',
  CANCELLED = '/transaction-cancelled.svg',
}

enum Messages {
  CANCELLED_MESSAGE = 'Houve um problema na sua requisição. Por favor, tente novamente mais tarde. Caso o problema persista, entre em contato conosco.',
  PENDING_MESSAGE = 'Esta operação está levando bastante tempo. Acesse o seu extrato para saber mais informações sobre esta operação.',
  FORM_ERROR_MESSAGE = 'Houve um problema nos dados informados, clica abaixo para revisar.',
  DONE_MESSAGE = 'Operação concluída com sucesso!',
}

const TransactionModal: React.FC<Props> = ({
  title,
  text,
  status,
  onClose,
  open,
  target,
  reviewTarget,
}) => {
  const router = useRouter();
  if (open) {
    return (
      <Dialog open={true} maxWidth="sm" fullWidth>
        <Grid paddingX={2} paddingY={3}>
          <Box
            sx={{
              bgcolor: 'grey.400',
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              m: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {IntentStatus.NONE == status ? (
              <Squares loaderState />
            ) : IntentStatus.DONE == status ? (
              <Image
                alt=""
                src={ImageIcons.CONFIRMED}
                width="41px"
                height="37px"
              />
            ) : IntentStatus.PENDING == status ? (
              <Image
                alt=""
                src={ImageIcons.PENDING}
                width="37px"
                height="37px"
              />
            ) : (
              <Image
                alt=""
                src={ImageIcons.CANCELLED}
                width="37px"
                height="37px"
              />
            )}
          </Box>
          {title && (
            <DialogTitle
              component="h2"
              variant="h4"
              color="text.primary"
              sx={{ textAlign: 'center' }}
            >
              {title}
            </DialogTitle>
          )}
          {IntentStatus.NONE != status && (
            <DialogContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                component="p"
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: 'center', maxWidth: '300px' }}
              >
                {IntentStatus.FAILURE == status && !text
                  ? Messages.CANCELLED_MESSAGE
                  : IntentStatus.DONE == status
                  ? Messages.DONE_MESSAGE
                  : IntentStatus.FORM_ERROR == status
                  ? Messages.FORM_ERROR_MESSAGE
                  : IntentStatus.PENDING == status
                  ? Messages.PENDING_MESSAGE
                  : text}
              </Typography>
            </DialogContent>
          )}
          {IntentStatus.NONE != status && (
            <DialogActions
              sx={{
                paddingX: 3,
                mt: 3,
                flexDirection: 'column',
                gap: '12px',
                '.MuiButtonBase-root': {
                  m: 0,
                },
              }}
            >
              {target && IntentStatus.DONE == status && (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => router.push(target)}
                  sx={{
                    boxShadow: 'none',
                  }}
                >
                  Ver comprovante
                </Button>
              )}
              {reviewTarget &&
                IntentStatus.FORM_ERROR == status && (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={reviewTarget}
                    sx={{
                      boxShadow: 'none',
                    }}
                  >
                    Revisar dados
                  </Button>
                )}
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onClose()}
                sx={{
                  boxShadow: 'none',
                }}
              >
                Fechar
              </Button>
            </DialogActions>
          )}
        </Grid>
      </Dialog>
    );
  }

  return null;
};

export default TransactionModal;
