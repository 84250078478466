const textToNumber = (text: string, decimalPlaces: number = 2): number => {
  let amountText = text.trim().replace(/\./g, ",");

  if (amountText == ",") {
    return 0;
  }

  const commaIndex = amountText.indexOf(",");
  const hasComma = commaIndex > -1;

  if (hasComma) {
    const currentDecimalPlaces = amountText.length - commaIndex - 1;

    if (currentDecimalPlaces <= decimalPlaces) {
      const newTotalLength =
        amountText.length + (decimalPlaces - currentDecimalPlaces);
      amountText = amountText.padEnd(newTotalLength, "0").replace(",", "");
    } else {
      amountText = "";
    }
  } else {
    amountText = amountText.padEnd(amountText.length + decimalPlaces, "0");
  }

  let parsedNumber = Number(amountText);

  if (isNaN(parsedNumber)) {
    parsedNumber = 0;
  }

  return parsedNumber;
};

export default textToNumber;
