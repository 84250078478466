import { Icon, themes } from '@2ndmarket/components';
import { formatCpfCnpj } from '@2ndmarket/helpers';
import { useMe, useWallet } from '@2ndmarket/services';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const AccountData: React.FC = () => {
  const { data: me } = useMe();
  const {
    data: wallet,
    loading: walletLoading,
    error: walletError,
  } = useWallet();

  function copyAllData() {
    navigator.clipboard.writeText(
      'Esta conta só recebe depósitos do CPF: ' +
        formatCpfCnpj(me.cpf_cnpj) +
        '\nAgência: ' +
        wallet.bank_branch +
        '\nConta: ' +
        wallet.bank_account_number +
        '\nCPF: ' +
        me.cpf_cnpj +
        '\nInstituição: ' +
        wallet.bank_name +
        ' ' +
        wallet.bank_code,
    );
  }

  return (
    <Button
      onClick={() => copyAllData()}
      variant="text"
      sx={{
        bgcolor: 'common.white',
        width: '100%',
        maxWidth: '956px',
        mt: '4px',
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        '&:hover': {
          bgcolor: 'common.white',
          boxShadow: '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
          i: {
            color: 'primary.main',
          },
        },
      }}
    >
      {!walletLoading && !walletError && (
        <Box
          display="flex"
          gap="12px"
          sx={{
            '.MuiTypography-root': {
              fontSize: '14px',
              color: 'text.primary',
            },
            'span.MuiTypography-root': {
              color: 'grey.300',
            },
          }}
        >
          <Typography component="p" variant="body1">
            <Typography component="span">Banco</Typography>{' '}
            {wallet.bank_code}
          </Typography>
          <Typography component="p" variant="body1">
            <Typography component="span">Ag.</Typography>{' '}
            {wallet.bank_branch}
          </Typography>
          <Typography component="p" variant="body1">
            <Typography component="span">Conta</Typography>{' '}
            {wallet.bank_account_number}
          </Typography>
        </Box>
      )}
      <Icon
        size={20}
        name="copy"
        color={themes.bankai.palette.grey[300]}
      />
    </Button>
  );
};

export default AccountData;
