import { useState } from 'react';

import PasswordStep from './PasswordStep';
import PriceStep from './PriceStep';
import { Crypto } from '@2ndmarket/helpers';
import { ITransferData, TransferDataEmpty } from './Types';

interface Props {
  cryptoName: Crypto;
}

const Trading: React.FC<Props> = ({ cryptoName }) => {
  const [currentStep, setCurrentStep] = useState('step1');
  const [transferData, setTransferData] =
    useState<ITransferData>(TransferDataEmpty);

  return (
    <>
      {'step1' == currentStep && (
        <PriceStep
          cryptoName={cryptoName}
          setTransferData={setTransferData}
          setStep={setCurrentStep}
        />
      )}
      {'step2' == currentStep && transferData && (
        <PasswordStep
          cryptoName={cryptoName}
          setStep={setCurrentStep}
          transferData={transferData}
        />
      )}
    </>
  );
};
export default Trading;
