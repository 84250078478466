import type { AppProps } from 'next/app';
import { useState, useEffect } from 'react';
import { GoogleTagManager, themes } from '@2ndmarket/components';
import { AppContext, LocalStorage } from '@2ndmarket/helpers';
import { useMe } from '@2ndmarket/services';

import CssBaseline from '@mui/material/CssBaseline';
import { OpenAccount } from '../sections';

function MyApp({ Component, pageProps }: AppProps) {
  const { ThemeProvider, bankai } = themes;

  const [showBalance, setShowBalance] = useState(false);

  useEffect(() => {
    const storageShowBalance = localStorage.getItem(
      LocalStorage.BANKAI_BALANCE_VIEW,
    );
    if (storageShowBalance === 'true') {
      setShowBalance(true);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        showBalance: showBalance,
        setShowBalance: setShowBalance,
      }}
    >
      <ThemeProvider theme={bankai}>
        <GoogleTagManager userOrigin={useMe} theme={bankai} />
        <CssBaseline />
        <OpenAccount />
        <Component {...pageProps} />
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default MyApp;
