import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

interface ProductProps {
  product?: string;
}

interface PropsStatus {
  status?: string;
}

export const ContainerAll = styled.div<ProductProps>`
  max-width: 100%;
  width: 550px;

  margin: 60px auto;
  ${props =>
    props.product === 'braex' &&
    css`
      margin: 50px auto;
    `}

  ${props =>
    props.product === 'braex' &&
    css`
      --divisor-color: ${theme('colors.dark.gray.2')};
      --footer-border-top-color: ${theme('colors.secondary.2')};
      --footer-background: ${theme('colors.dark.gray.2')};
    `}

    ${props =>
    props.product === 'bankai' &&
    css`
      --divisor-color: ${theme('colors.secondary.gray.4')};
      --footer-border-top-color: transparent;
      --footer-background: ${theme('colors.background')};
    `}

  @media only screen and (max-width: ${theme(
    'breakpoints.mobileBraex',
  )}) {
    border: none;
    overflow: hidden;
    margin: -30px auto;
    position: relative;
    padding: 20px 20px 50px 20px;
    border-radius: 24px 24px 0 0;
    background-color: ${theme('colors.background')};

    ${props =>
      props.product === 'braex' &&
      css`
        margin: 0;
        padding: 20px 20px 0 20px;
        background-color: transparent;
      `}
  }

  @media only screen and (max-width: ${theme(
      'breakpoints.mobile',
    )}) {
    margin: 25px 0;
    padding: 10px 20px 90px 20px;
  }
`;

export const Container = styled.div<ProductProps>`
  border: 1px solid ${theme('colors.secondary.4')};
  background-color: ${theme('colors.white')};
  padding: 35px 2vw 0 2vw;

  ${props =>
    props.product === 'braex' &&
    css`
      background-color: transparent;
      border: 1px solid ${theme('colors.secondary.2')};
    `}

  label {
    cursor: pointer;
  }

  @media only screen and (max-width: ${theme(
      'breakpoints.mobileBraex',
    )}) {
    padding: 35px 4vw 0 4vw;

    ${props =>
      props.product === 'braex' &&
      css`
        border: transparent;
        padding: 35px 0 0 0;
      `}
  }
`;

export const Header = styled.div<ProductProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    align-items: center;

    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: ${theme('colors.secondary.1')};

      ${props =>
        props.product === 'braex' &&
        css`
          color: ${theme('colors.white')};
        `}
    }

    h2 {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      text-transform: uppercase;
      color: ${theme('colors.secondary.2')};

      ${props =>
        props.product === 'braex' &&
        css`
          color: ${theme('colors.secondary.4')};
        `}
    }
  }

  .logo {
    i {
      font-size: 34px;
      line-height: 1;
      display: block;
      color: ${theme('colors.secondary.1')};
    }
  }
`;

export const ContentActions = styled.div<ProductProps>`
  gap: 40px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  button {
    background-color: transparent;
    cursor: pointer;
    border: none;
    padding: 0;

    i {
      color: ${theme('colors.secondary.3')};
      font-size: 16px;
      display: block;
      line-height: 1;

      &::before {
        display: block;
      }
    }

    &:hover {
      i {
        color: ${theme('colors.primary.main')};
      }
    }

    &.btn-cancel {
      background-color: ${theme('colors.primary.main')};
      color: ${theme('colors.offWhite')};
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 10px;
      display: flex;
      width: 155px;

      span {
        font-size: 14px;
        font-weight: 500;
        position: relative;
      }

      @media only screen and (max-width: ${theme(
          'breakpoints.mobileBraex',
        )}) {
        display: none;
      }
    }
  }

  ${props =>
    props.product === 'braex' &&
    css`
      gap: 15px;
      justify-content: space-between;

      .btn-download {
        justify-content: flex-end;
      }

      .btn-cancel {
        justify-content: flex-start;
      }
    `}

  @media only screen and (max-width: ${theme(
    'breakpoints.mobileBraex',
  )}) {
    padding: 15px 20px 15px 20px;
    margin-bottom: 0;

    ${props =>
      props.product === 'braex' &&
      css`
        padding: 15px 0;

        .title-mobile {
          display: flex;

          a {
            gap: 24px;
            width: 100px;
            display: flex;
            font-size: 16px;
            font-weight: 600;
            align-items: center;
            align-content: center;
            justify-content: center;
            color: ${theme('colors.white')};
          }
        }

        .bt-cancel {
          width: 155px;
        }
      `}
  }

  @media only screen and (min-width: ${theme(
      'breakpoints.mobileBraex',
    )}) {
    ${props =>
      props.product === 'braex' &&
      css`
        .title-mobile {
          display: none;
        }
      `}
  }
`;

export const ContentStatus = styled.div`
  display: flex;
  justify-content: center;
  margin: 28px 0 15px 0;
`;

export const Status = styled.div<ProductProps>`
  border-radius: 50%;
  background-color: ${theme('colors.secondary.4')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;

  ${props =>
    props.product === 'braex' &&
    css`
      background-color: ${theme('colors.secondary.1')};
    `}

  i {
    line-height: 1;
    margin-top: 2px;
    display: block;
  }
`;

export const Line = styled.div<ProductProps>`
  display: flex;
  padding: 13px 0;
  justify-content: space-between;
  border-bottom: 1px solid ${theme('colors.secondary.4')};

  ${props =>
    props.product === 'braex' &&
    css`
      border-bottom: 1px solid ${theme('colors.secondary.2')};
    `}

  &:last-child {
    border-bottom: none;
  }

  &.bank-institution {
    border-bottom: none;
  }

  h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
    white-space: nowrap;
    color: ${theme('colors.secondary.1')};

    ${props =>
      props.product === 'braex' &&
      css`
        color: ${theme('colors.white')};
      `}
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${theme('colors.secondary.2')};
    padding-left: 20px;
    word-break: break-all;

    ${props =>
      props.product === 'braex' &&
      css`
        color: ${theme('colors.secondary.4')};
      `}
  }
`;

export const LineColumn = styled(Line)`
  flex-direction: column;
  gap: 5px;

  p {
    width: auto;
    word-break: break-all;
    text-align: right;
  }
`;

export const Message = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${theme('colors.secondary.2')};
  padding: 15px 0;
  background-color: #f7f7fa;
  text-align: center !important;
`;

export const Title = styled.div<ProductProps>`
  display: flex;
  align-items: center;
  margin: 40px 0 13px 0;

  i {
    display: block;
    font-size: 16px;
    color: ${theme('colors.secondary.3')};

    &::before {
      display: block;
    }
  }

  h1 {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    margin-left: 14px;
    color: ${theme('colors.secondary.2')};

    ${props =>
      props.product === 'braex' &&
      css`
        color: ${theme('colors.secondary.4')};
      `}
  }
`;

export const Footer = styled.div<ProductProps>`
  margin: 30px -2vw 0 -2vw;
  padding: 30px 2vw;
  border-top: 1px solid var(--footer-border-top-color);
  background-color: var(--footer-background);

  ${props =>
    props.product === 'braex' &&
    css`
      background-color: ${theme('colors.secondary.2')};

      @media only screen and (max-width: 1200px) {
        padding: 5vw 8vw 15vw;
        background-color: ${theme('colors.secondary.2')};
        border-top: 1px solid ${theme('colors.secondary.3')};
      }
    `}

  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: ${theme('colors.secondary.2')};

    @media only screen and (max-width: ${theme(
        'breakpoints.mobileBraex',
      )}) {
      font-size: 14px;
    }

    ${props =>
      props.product === 'braex' &&
      css`
        color: ${theme('colors.secondary.4')};
      `}
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${theme('colors.secondary.2')};

    @media only screen and (max-width: ${theme(
        'breakpoints.mobileBraex',
      )}) {
      font-size: 14px;
    }

    ${props =>
      props.product === 'braex' &&
      css`
        color: ${theme('colors.secondary.4')};
      `}

    :last-child {
      margin: 0;
    }

    &.transaction-id {
      ${props =>
        props.product === 'braex' &&
        css`
          @media only screen and (max-width: ${theme(
              'breakpoints.mobileBraex',
            )}) {
            display: block;
          }
        `}
    }
  }

  span {
    color: ${theme('colors.secondary.4')};
    ${props =>
      props.product === 'braex' &&
      css`
        @media only screen and (max-width: ${theme(
            'breakpoints.mobileBraex',
          )}) {
          display: block;
          font-size: 14px;
          color: ${theme('colors.secondary.4')};
        }
      `}
  }

  @media only screen and (max-width: ${theme(
      'breakpoints.mobileBraex',
    )}) {
    margin: 30px -4vw 0 -4vw;
    padding: 30px 4vw;

    ${props =>
      props.product === 'braex' &&
      css`
        margin: 30px -6vw 0 -6vw;
        padding: 30px 6vw;
      `}
  }
`;

export const ContentReceipt = styled.div`
  label {
    cursor: pointer;
  }
`;

export const ContentButton = styled.div<ProductProps>`
  margin-top: 30px;

  ${props =>
    props.product === 'braex' &&
    css`
      gap: 20px;
      display: flex;
      justify-content: space-between;
    `}

  button {
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    color: ${theme('colors.primary.main')};
  }

  @media only screen and (max-width: ${theme(
      'breakpoints.mobileBraex',
    )}) {
    ${props =>
      props.product === 'braex' &&
      css`
        margin-top: 0;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: ${theme('colors.secondary.2')};

        .btn-return {
          display: none;
        }

        .btn-cancel {
          background-color: ${theme('colors.primary.main')};
          color: ${theme('colors.white')};
          justify-content: center;
          padding: 10px 110px;
          display: flex;
        }
      `}
  }

  @media only screen and (min-width: ${theme(
      'breakpoints.mobileBraex',
    )}) {
    .btn-cancel {
      display: none;
    }
  }
`;

export const ModalContent = styled.div`
  gap: 10px;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;

  h1 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    color: ${theme('colors.white')};
  }

  p {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 16px;
    color: ${theme('colors.secondary.4')};
  }

  button {
    margin-top: 21px;
    width: 100%;
    height: 45px;
    background-color: ${theme('colors.primary.main')};
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    color: ${theme('colors.white')};
    transition: 0.5s;
  }
`;
