import regularFetcher from './regularFetcher';

interface Props {
  api?: string;
  endpoint: string;
  headers?: any;
  method: string;
  body?: any;
}

const authenticatedFetcher: (
  params: Props,
) => Promise<any> = async ({
  api = process.env.NEXT_PUBLIC_API_AUTH,
  endpoint = '',
  headers = {},
  method = 'GET',
  body = '',
}) => {
  // @ts-ignore
  if (typeof window == 'undefined') {
    return Promise.resolve({});
  }

  if (!body) {
    body = '';
  }

  // @ts-ignore
  const token = window.localStorage.getItem(
    '@2ndMarket-token',
  );
  return regularFetcher({
    api: api,
    endpoint: endpoint,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    method: method,
    body: body,
  });
};

export default authenticatedFetcher;
