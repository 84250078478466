import { useEffect, useState } from 'react';

import { LoaderProps } from '@2ndmarket/types';
import { themes } from '@2ndmarket/components';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Loader: React.FC<LoaderProps> = ({
  text,
  title,
  subTitle,
  loaderState,
}) => {
  const [loaderShow, setLoaderShow] = useState(loaderState);

  useEffect(() => {
    setLoaderShow(loaderState);
  }, [loaderState]);

  const BoxLoader = styled(Box)({
    padding: 35,
    width: '152px',
    height: '152px',
    borderRadius: '50%',
    position: 'relative',
    '@keyframes loader_one': {
      '0%': {
        top: 0,
        left: 0,
      },
      '25%': {
        top: 0,
        left: 'calc(50% - 50px)',
      },
      '50%': {
        top: 'calc(50% - 50px)',
        left: 'calc(50% - 50px)',
        height: '22px',
        width: '22px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        top: 'calc(50% - 50px)',
        left: 0,
      },
      '100%': {
        top: 0,
        left: 0,
      },
    },
    '@keyframes loader_two': {
      '0%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '22px',
        width: '22px',
      },
      '25%': {
        left: 0,
        top: 'calc(50% - 50px)',
      },
      '50%': {
        left: 0,
        top: 0,
        height: '22px',
        width: '22px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        left: 'calc(50% - 50px)',
        top: 0,
      },
      '100%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '22px',
        width: '22px',
      },
    },
    '@-webkit-keyframes loader_one': {
      '0%': {
        top: 0,
        left: 0,
      },
      '25%': {
        top: 0,
        left: 'calc(50% - 50px)',
      },
      '50%': {
        top: 'calc(50% - 50px)',
        left: 'calc(50% - 50px)',
        height: '22px',
        width: '22px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        top: 'calc(50% - 50px)',
        left: 0,
      },
      '100%': {
        top: 0,
        left: 0,
      },
    },
    '@-webkit-keyframes loader_two': {
      '0%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '22px',
        width: '22px',
      },
      '25%': {
        left: 0,
        top: 'calc(50% - 50px)',
      },
      '50%': {
        left: 0,
        top: 0,
        height: '22px',
        width: '22px',
        background: themes.authentication.palette.grey[100],
      },
      '75%': {
        left: 'calc(50% - 50px)',
        top: 0,
      },
      '100%': {
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
        height: '22px',
        width: '22px',
      },
    },
    '.square': {
      margin: 50,
      borderRadius: '0%',
      position: 'absolute',
      boxShadow: '0 0 12px rgba(0,0,0,0.1)',
      '&.first': {
        width: '22px',
        height: '22px',
        animation: 'loader_one 2s linear infinite',
        borderWidth: '4px',
        borderStyle: 'solid',
        WebkitAnimation: 'loader_one 2s linear infinite',
      },
      '&.second': {
        width: '22px',
        height: '22px',
        top: 'calc(100% - 100px)',
        left: 'calc(100% - 100px)',
        animation: 'loader_two 2s linear infinite',
        borderWidth: '4px',
        borderStyle: 'solid',
        WebkitAnimation: 'loader_two 2s linear infinite',
      },
    },
  });

  return (
    <>
      {loaderShow && (
        <Grid
          item
          gap={1}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          zIndex={1}
          position="absolute"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <BoxLoader sx={{ bgcolor: 'grey.400' }}>
              <Box
                component="div"
                className="square first"
                sx={{ borderColor: 'primary.main' }}
              />
              <Box
                component="div"
                className="square second"
                sx={{ borderColor: 'primary.light' }}
              />
            </BoxLoader>
          </Box>
          <Typography
            component="h2"
            variant="body1"
            color={themes.authentication.palette.text.secondary}
          >
            {title}
          </Typography>
          <Typography
            component="h3"
            variant="body1"
            color={themes.authentication.palette.text.secondary}
          >
            {subTitle}
          </Typography>
          <Typography
            component="p"
            variant="body1"
            color={themes.authentication.palette.text.secondary}
          >
            {text}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Loader;
