import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import Icon from '../Icon';
import Modal from '../Modal';
import { default2nd } from '../themes';
import themeStories from '../themes/theme.stories';
import { Container, Content, HeaderMobile } from './styles';
import TabItem from './TabItem';

export const StatusColor: Record<string, string> = {
  approved: default2nd.colors.status.active,
  processing: default2nd.colors.status.analising,
  pending: default2nd.colors.status.analising,
  analysing: default2nd.colors.status.analising,
  refused: default2nd.colors.status.nokyc,
  none: default2nd.colors.status.nokyc,
  disabled: default2nd.colors.status.deactivated,
};

export interface DataProps {
  id: number;
  title: string;
  body: ReactElement;
  link?: string;
  statusMethod: any;
  crypto?: string;
}

export interface TabsProps {
  data: DataProps[];
  current?: number;
}

const Tabs: React.FC<TabsProps> = ({ data, current }) => {
  const [currentIndex, setCurrentIndex] = useState(current ?? 0);
  const [openModal, setOpenModal] = useState(false);
  const router = useRouter();

  let currentData: DataProps | undefined = undefined;
  for (let i = 0; i < data.length; i++) {
    if (data[i].id == currentIndex) {
      currentData = data[i];
    }
  }

  useEffect(() => {
    if (current !== undefined) {
      setCurrentIndex(current);
    }
  }, [current]);

  const handle = (newIndex: number) => {
    setCurrentIndex(newIndex);
    setOpenModal(false);
  };

  const theme = themeStories.argTypes.theme.control.options;
  return (
    <Container className={theme[3] ? 'boleta' : ''}>
      <div className="desktopOnly tabHeader">
        {data.map(item => (
          <TabItem
            key={item.id}
            active={currentIndex === item.id}
            title={item.title}
            statusMethod={item.statusMethod}
            crypto={item.crypto}
            onClick={() => {
              if (item.link) {
                router.push(item.link);
              } else {
                handle(item.id);
              }
            }}
          />
        ))}
      </div>

      <div className="mobileOnly">
        <HeaderMobile>
          <h1>{currentData?.title}</h1>
          <button onClick={() => setOpenModal(true)}>
            <Icon name="change" color="#FFFFFF" size={18} />
          </button>
        </HeaderMobile>
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Content>
          <h1>Selecione:</h1>
          {data.map(item => (
            <TabItem
              key={item.id}
              active={currentIndex === item.id}
              title={item.title}
              statusMethod={item.statusMethod}
              crypto={item.crypto}
              onClick={() => {
                item.link
                  ? (router.push(item.link), setOpenModal(false))
                  : handle(item.id);
              }}
            />
          ))}
          <button
            className="cancel"
            onClick={() => setOpenModal(false)}
          >
            Cancelar
          </button>
        </Content>
      </Modal>
      <div className="contentBody">{currentData?.body}</div>
    </Container>
  );
};

export default Tabs;
