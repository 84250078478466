export const getInitials = (name: any): string => {
  let initials: any = '';
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  initials = [...name.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
};