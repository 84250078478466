import { useState } from 'react';
import { useRouter } from 'next/router';
import { Icon, themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import TEDDrawer from './TEDDrawer';
import PIXDrawer from './PIXDrawer';

const Deposit: React.FC = () => {
  const router = useRouter();
  const [openTED, setOpenTED] = useState(false);
  const [openPIX, setOpenPIX] = useState(false);
  return (
    <>
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
        sx={{
          gap: '10px',
          width: '100%',
          '.MuiButtonBase-root': {
            height: 'auto',
            bgcolor: 'common.white',
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            borderRadius: '3px',
            '&:hover': {
              transition:
                'box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              boxShadow:
                '0px 10px 25px 0px rgba(26, 36, 137, 0.05)',
            },
            '.leftContent': {
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              '.text': {
                textAlign: 'left',
                color: 'text.primary',
                '& p': {
                  fontWeight: '500',
                  lineHeight: '1',
                },
                '& span': {
                  color: 'text.secondary',
                  fontSize: '14px',
                },
              },
            },
          },
        }}
      >
        <Button variant="text" onClick={() => setOpenTED(true)}>
          <Box className="leftContent">
            <Icon name="bank" size={20} />
            <Box className="text">
              <Typography component="p" variant="body1">
                TED
              </Typography>
              <Typography component="span" variant="body1">
                Copie os dados da sua conta.
              </Typography>
            </Box>
          </Box>
          <Icon
            name="arrow"
            rotate="-90deg"
            size={16}
            color={themes.bankai.palette.text.disabled}
          />
        </Button>
        <Button variant="text" onClick={() => setOpenPIX(true)}>
          <Box className="leftContent">
            <Icon name="pix" size={20} />
            <Box className="text">
              <Typography component="p" variant="body1">
                Pix
              </Typography>
              <Typography component="span" variant="body1">
                Receba na hora e sem custo.
              </Typography>
            </Box>
          </Box>
          <Icon
            name="arrow"
            rotate="-90deg"
            size={16}
            color={themes.bankai.palette.text.disabled}
          />
        </Button>
        <Button
          variant="text"
          onClick={() => router.push('boleto/depositar')}
        >
          <Box className="leftContent">
            <Icon name="boleto" size={20} />
            <Box className="text">
              <Typography component="p" variant="body1">
                Boleto
              </Typography>
              <Typography component="span" variant="body1">
                Receba em até 3 dias úteis.
              </Typography>
            </Box>
          </Box>
          <Icon
            name="arrow"
            rotate="-90deg"
            size={16}
            color={themes.bankai.palette.text.disabled}
          />
        </Button>
      </ButtonGroup>
      <TEDDrawer openTED={openTED} setOpenTED={setOpenTED} />
      <PIXDrawer openPIX={openPIX} setOpenPIX={setOpenPIX} />
    </>
  );
};

export default Deposit;
