import { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  dayjsUtc,
  formatBRL,
  formatCpfCnpj,
  parseBRL,
} from '@2ndmarket/helpers';
import { ContentTitle } from '../../../../components';

import { DateDrawer, Icon } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { useBank, useBill } from '@2ndmarket/services';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  billCode: string;
  billId: number;
  setBillData: React.Dispatch<React.SetStateAction<Object>>;
}

interface FormData {
  amount: number;
  schedule_date: string | null;
}

const steps = ['Início', 'Linha digitável', 'Revisão'];

const ReviewStep: React.FC<Props> = ({
  setStep,
  billCode,
  billId,
  setBillData,
}) => {
  const defaultDateText =
    'Hoje, ' + dayjsUtc().format('DD/MM/YYYY');
  const [dateOpen, setDateOpen] = useState(false);
  const [selectedDate, setSelectedDate] =
    useState(defaultDateText);
  const [amount, setAmount] = useState<number>(0);
  const [disableDate, setDisableDate] = useState(false);

  const {
    data: bill,
    loading: billLoading,
    error: billError,
  } = useBill(billId);
  const { data: bank } = useBank(bill?.bank_id);

  const defaultValues = {
    amount: 0,
    schedule_date: '',
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    if (!billLoading && !billError) {
      const diffLimit = 1;
      const today = dayjsUtc().format('YYYY-MM-DD');
      const dueDate = dayjsUtc(bill.due_date);
      const daysDiff = dueDate.diff(today, 'day');
      if (daysDiff < diffLimit) {
        setDisableDate(true);
      }
    }
  }, [billLoading, billError, bill.due_date, setDisableDate]);

  useEffect(() => {
    setValue('schedule_date', selectedDate);
  }, [selectedDate, setValue]);

  useEffect(() => {
    setAmount(bill.updated_amount);
  }, [bill, setAmount]);

  function onSubmit(data: FormData) {
    if (defaultDateText == data.schedule_date) {
      data.schedule_date = null;
    }
    setBillData({
      bill_id: bill.id,
      amount: amount,
      schedule_date: data.schedule_date,
    });
    setStep('step3');
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <ContentTitle title="Boleto" steps={steps} />
      {!billLoading && (
        <>
          <Box
            sx={{
              mt: 3,
              mb: 5,
              display: 'grid',
              gap: 3,
              alignItems: 'start',
              gridTemplateColumns: {
                xs: '1fr',
                md: '1fr 1fr',
              },
              '& .MuiTextField-root': {
                margin: 0,
              },
            }}
          >
            <TextField
              {...register('amount', { required: true })}
              fullWidth
              margin="dense"
              label="Valor"
              autoComplete="off"
              disabled={
                bill.maximum_amount == bill.minimum_amount
              }
              value={amount ? formatBRL(amount) : ''}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
              ) => {
                setAmount(parseBRL(event.target.value));
              }}
              InputProps={{
                inputProps: {
                  inputMode: 'numeric',
                  maxLength: 16,
                },
              }}
              error={Boolean(errors.amount)}
              helperText={errors.amount && errors.amount.message}
              sx={{ mb: 4 }}
            />
            <TextField
              {...register('schedule_date', { required: true })}
              onClick={() => setDateOpen(true)}
              disabled={disableDate}
              sx={{
                marginBottom: 5,
                input: { cursor: 'pointer' },
              }}
              fullWidth
              label="Quando"
              error={Boolean(errors.schedule_date)}
              helperText={
                errors.schedule_date &&
                errors.schedule_date.message
              }
              InputProps={{
                inputProps: { readOnly: true },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ '& i': { color: 'text.disabled' } }}
                  >
                    <Icon name="calendar" size={16} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Typography
            component="p"
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              mb: 2,
              '& i': {
                color: 'primary.main',
              },
            }}
          >
            <Icon name="boleto" />
            Dados do Boleto
          </Typography>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderTopWidth: '1px',
              borderTopStyle: 'solid',
              borderColor: 'grey.200',
              p: 0,
              mb: 4,
              '& li': {
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: 'grey.200',
                '.MuiListItemText-root': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  py: 2,
                  m: 0,
                  '& span': {
                    color: 'grey.300',
                  },
                  '& p': {
                    color: 'text.primary',
                    maxWidth: {
                      xs: '200px',
                      sm: '240px',
                      md: '500px',
                    },
                    wordBreak: 'break-all',
                  },
                },
              },
            }}
          >
            <ListItem disablePadding>
              <ListItemText
                primary="Data do vencimento"
                secondary={
                  bill.due_date
                    ? dayjsUtc().format('DD/MM/YYYY')
                    : dayjsUtc().format('DD/MM/YYYY')
                }
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary="Código do boleto"
                secondary={bill.typeable_line}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary="Valor do boleto"
                secondary={formatBRL(bill.updated_amount)}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary="Pagando"
                secondary={formatBRL(amount)}
              />
            </ListItem>
          </List>
          <Typography
            component="p"
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              mb: 2,
              '& i': {
                color: 'primary.main',
              },
            }}
          >
            <Icon name="send" />
            Destinatário
          </Typography>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderTopWidth: '1px',
              borderTopStyle: 'solid',
              borderColor: 'grey.200',
              p: 0,
              mb: 4,
              '& li': {
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: 'grey.200',
                '.MuiListItemText-root': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  py: 2,
                  m: 0,
                  '& span': {
                    color: 'grey.300',
                  },
                  '& p': {
                    color: 'text.primary',
                  },
                },
              },
            }}
          >
            <ListItem disablePadding>
              <ListItemText
                primary="Nome"
                secondary={bill.beneficiary_name}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary="CPF/CNPJ"
                secondary={formatCpfCnpj(
                  bill.beneficiary_number,
                )}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary="Instituição"
                secondary={bank.name}
              />
            </ListItem>
          </List>
          <Box display="flex" justifyContent="space-between">
            <Button
              type="button"
              variant="link"
              onClick={() => setStep('step1')}
            >
              Voltar
            </Button>
            <Button type="submit" variant="contained">
              Continuar
            </Button>
          </Box>
        </>
      )}
      <DateDrawer
        dateOpen={dateOpen}
        setDateOpen={setDateOpen}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        defaultDateText={defaultDateText}
      />
    </Box>
  );
};
export default ReviewStep;
