interface Props {
  api?: string;
  endpoint: string;
  headers?: any;
  method?: string;
  body?: any;
}

const regularFetcher: (params: Props) => any = async ({
  api = process.env.NEXT_PUBLIC_API_AUTH,
  endpoint = '',
  headers = {},
  method = 'GET',
  body = '',
}) => {
  const params = {
    method: method,
    headers: headers,
  };
  if (body) {
    // @ts-ignore
    params.body = body;
  }
  // @ts-ignore
  const response = await fetch(api + endpoint, params);

  if (response.status == 204) {
    return;
  }

  if (response.status === 202) {
    if (localStorage.getItem('@locationHeader')) {
      localStorage.removeItem('@locationHeader');
    }

    const locationHeader = response.headers.get('Location');
    localStorage.setItem(
      '@locationHeader',
      locationHeader as string,
    );

    return locationHeader;
  }

  if (response.ok) {
    return response.json();
  }

  if (response.status == 401) {
    if ('backoffice' == process.env.NEXT_PUBLIC_APP) {
      if (
        // @ts-ignore
        window.location.pathname !=
        process.env.NEXT_PUBLIC_LOGIN_URL
      ) {
        // @ts-ignore
        window.location.href = '/login';
      }
    } else if (process.env.NEXT_PUBLIC_APP == 'auth') {
      if (
        // @ts-ignore
        window.location.pathname === '/logout'
      ) {
        // @ts-ignore
        window.location.href = '/';
      }
    } else if (
      // @ts-ignore
      window.location.pathname != '/login'
    ) {
      // @ts-ignore
      window.location.href = '/redirect?auth=none';
    }
  }

  if (response.status >= 400 && response.status < 500) {
    return response.json().then((data: any) => {
      return Promise.reject({
        status: response.status,
        ...data,
      });
    });
  }

  return Promise.reject({
    status: 500,
    error:
      'Não foi possível processar sua requisição. Tente novamente mais tarde.',
  });
};

export default regularFetcher;
