import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { IReceipt, IServiceResponse, ReceiptEmpty } from '@2ndmarket/types';
import { AppConstants } from '@2ndmarket/helpers';

const useReceipt = (
  operation: string,
  uuid: string,
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IReceipt> => {
const key = {
  endpoint: `/v4/fiat/users/${user_id}/receipt/${operation}/${uuid}`,
};

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IReceipt, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? ReceiptEmpty,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useReceipt;
