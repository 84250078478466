import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

interface ProductProps {
  product?: string;
}

export const Container = styled.div<ProductProps>`
  align-items: center;
  background-color: ${theme('colors.blur')};
  backdrop-filter: blur(8px);
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  top: 0;
  padding: 40px;
  position: fixed;
  width: 100%;
  z-index: 11;

  ${props =>
    props.product === 'braex' &&
    css`
      &.modal-resume-order {
        width: 32.5%;
        padding: 20px;
        height: 43.4vh;

        position: absolute !important;
        left: 21px;
      }
    `}

  @media only screen and (max-width: ${theme(
    'breakpoints.mobile',
  )}) {
    padding: 40px 15px;

    &.bottomStyle {
      backdrop-filter: blur(0px);
      background-color: rgba(0, 0, 0, 0.7);
      padding: 70px 0 0 0;
      align-items: flex-end;
    }
  }
`;

export const Content = styled.div<ProductProps>`
  overflow: auto;
  background-color: ${theme('colors.background')};
  border-radius: 2px;
  box-shadow: 0px 10px 25px rgba(26, 36, 137, 0.05);
  border: 1px solid ${theme('colors.white')};
  max-height: 100%;
  max-width: 600px;
  position: relative;
  padding: 50px 30px 20px;

  ${props =>
    props.product === 'braex' &&
    css`
      border: 1px solid ${theme('colors.secondary.1')};

      &.modal-resume-order {
        gap: 40px;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        border: none;
        border-radius: 0;
        box-shadow: none;
        flex-direction: column;
        background-color: transparent;

        .close {
          display: none;
        }
      }
    `}

  .close {
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    color: ${theme('colors.secondary.3')};

    ${props =>
      props.product === '2nd' &&
      css`
        display: flex;
        justify-content: flex-end;
      `}

    @media (max-width: ${theme('breakpoints.mobile')}) {
      font-size: 9px;
    }

    &:hover {
      color: ${theme('colors.primary.dark')};
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}) {
    max-width: 95%;

    &.bottomStyle {
      padding: 50px 30px 30px;
      border-radius: 24px 24px 0 0;
      min-width: 100%;

      .close {
        right: 33px;
        top: 33px;
      }
    }
  }
`;

export const ErrorContent = styled.div`
  min-width: 350px;
  padding: 10px 16px 30px 16px;

  @media (max-width: ${theme('breakpoints.mobile')}) {
    min-width: 220px;
  }

  .wrapIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    .errorIcon {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      background-color: ${theme('colors.error.bg')};
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 14px;
        color: ${theme('colors.error.main')};
        line-height: 1;

        &::before {
          display: block;
        }
      }
    }
  }

  h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: ${theme('colors.secondary.1')};
    margin-bottom: 4px;
  }

  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${theme('colors.secondary.2')};
    word-break: break-word;
  }

  &.braex {
    h3,
    p {
      color: ${theme('colors.offWhite')};
    }

    button {
      background-color: ${theme('colors.primary.main')};
      color: ${theme('colors.offWhite')};

      :hover {
        background-color: ${theme('colors.primary.dark')};
        color: ${theme('colors.offWhite')};
      }
    }
  }

  button {
    margin-top: 40px;
  }
`;
