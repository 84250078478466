import { Crypto } from "@2ndmarket/helpers";

export enum TradeType {
  BUY = 'BUY',
  SELL = 'SELL'
}

export interface ITransferData {
  amount: number | string;
  amountCoin: number;
  valueType: string;
  tradeType: TradeType;
}

export const TransferDataEmpty: ITransferData = {
  amount: 0,
  amountCoin: 0,
  valueType: Crypto.BTC,
  tradeType: TradeType.BUY,
}