const Stepper = (theme: any) => {
  return {
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: 0,
          ':has(~ .MuiStep-error)': {
            '.MuiStepLabel-iconContainer': {
              '.MuiAvatar-root': {
                backgroundColor: theme.palette.grey[200],
                i: {
                  color: theme.palette.grey[300],
                },
              },
              '&.Mui-active': {
                '.MuiAvatar-root': {
                  backgroundColor: theme.palette.primary.main,
                  i: {
                    color: theme.palette.grey[0],
                  },
                },
              },
            },
            '.Mui-error': {
              '.MuiStepLabel-iconContainer': {
                '.MuiAvatar-root': {
                  backgroundColor: theme.palette.grey[800],
                  i: {
                    color: theme.palette.grey[0],
                  },
                },
              },
              '.MuiStepLabel-label': {
                color: theme.palette.grey[600],
              },
            },
            '.MuiStepLabel-label': {
              color: theme.palette.grey[300],
              '&.Mui-active': {
                color: theme.palette.grey[600],
              },
            },
          },
        },
        horizontal: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        horizontal: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        label: {
          fontSize: 14,
          lineHeight: 'normal',
          color: theme.palette.grey[300],
          fontWeight: theme.typography.fontWeightRegular,
          '&.Mui-active': {
            color: theme.palette.grey[600],
          },
          '&.Mui-completed': {
            color: theme.palette.grey[600],
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[300],
          },
          '&.Mui-error': {
            color: theme.palette.grey[600],
          },
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: 6,
          },
        },
        iconContainer: {
          '&.Mui-active': {
            '.MuiAvatar-root': {
              i: {
                color: theme.palette.grey[0],
              },
            },
          },
          '&.Mui-completed': {
            '.MuiAvatar-root': {
              i: {
                color: theme.palette.grey[0],
              },
            },
          },
          '&.Mui-disabled': {
            '.MuiAvatar-root': {
              i: {
                color: theme.palette.grey[300],
              },
            },
          },
          '&.Mui-error': {
            '.MuiAvatar-root': {
              i: {
                color: theme.palette.grey[0],
              },
            },
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          display: 'none',
        },
        horizontal: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.disabled,
          '&$completed': {
            color: theme.palette.primary.main,
          },
          '&$active': {
            color: theme.palette.primary.main,
          },
          '&$error': {
            color: theme.palette.grey[800],
          },
          '&$disabled': {
            color: theme.palette.grey[300],
          },
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        horizontal: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  };
};

export default Stepper;
