import useSWR from 'swr';

const asyncFunc = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(''), 5000);
  });
};

const mockWallets = async () => {
  await asyncFunc();

  return [
    {
      coin: 'BTC',
      amount: 10000,
      soon: false,
    },
    {
      coin: 'BKAI',
      amount: 0,
      soon: true,
    },
    {
      coin: 'ETH',
      amount: 10000,
      soon: true,
    },
    {
      coin: 'ADA',
      amount: 0,
      soon: true,
    },
    {
      coin: 'USDT',
      amount: 0,
      soon: true,
    },
  ];
};

const useWallets = () => {
  const key = {
    endpoint: `/v1/wallets`,
  };

  const options = {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  };

  const { data, error } = useSWR(key, mockWallets, options);

  return {
    wallets: data,
    loading: !error && !data,
    error,
  };
};

export default useWallets;
