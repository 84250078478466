const Box = (theme: any) => {
  return {
    MuiBox: {
      styleOverrides: {
        root: {
          '&.MuiBox-root': {
            with: '100%',
            marginTop: 1,
            padding: theme.spacing(2),
          },
        },
      },
    },
  };
};

export default Box;
