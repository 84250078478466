import { useState } from 'react';
import dayjs from 'dayjs';
import { getFileUrl } from '@2ndmarket/services';

import {
  dayjsUtc,
  HttpError,
  HttpStatus,
} from '@2ndmarket/helpers';

import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import ModalDialog from '../ModalDialog';
import { MuiLoader, themes } from '../..';

type Props = {
  title: string;
  fileName: string;
  children?: React.ReactNode;
  userId?: number;
};

async function openNewTab(url: string) {
  let linkElement = document.createElement('a');
  linkElement.id = url;
  window.document.body.appendChild(linkElement);
  const menuAddress = url;
  const link =
    (document.getElementById(url) as HTMLElement) ?? linkElement;

  if (link) {
    link.setAttribute('target', '_blank');
    link.setAttribute('href', menuAddress);
    link.click();
  }
}

const ButtonFile: React.FC<Props> = ({
  title,
  userId,
  fileName,
  children,
}) => {
  const [expiresAt, setExpiresAt] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();

    if (!title) {
      return;
    }

    const almostNow = dayjs().add(5, 'second');
    const isExpired =
      expiresAt.length == 0 ||
      dayjsUtc(expiresAt).local().isBefore(almostNow);

    var newLocation = window.open();

    if (isExpired) {
      setIsLoading(true);
      getFileUrl(fileName, userId)
        .then(data => {
          setExpiresAt(data.expires_at);
          setFileUrl(data.url);

          if (newLocation) {
            newLocation.location = data.url;
          } else {
            openNewTab(data.url);
          }
        })
        .catch((error: HttpError) => {
          if (error.status == HttpStatus.ClientErrorConflict) {
            setErrorMessage(error.error);
          } else {
            setErrorMessage(
              'Não foi possível gerar o link de acesso ao arquivo.',
            );
          }
          setShowModal(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      openNewTab(fileUrl);
    }
  };

  if (!fileName) {
    return (
      <Typography component="span" variant="body1">
        Nenhum
      </Typography>
    );
  }

  return (
    <>
      <Link
        component="button"
        onClick={onClick}
        className=""
        sx={{
          width: '100%',
          height: '100%',
          boxShadow: 'none',
          borderRadius: '3px',
          textDecoration: 'none',
          color: themes.authentication.palette.primary.main,
          '&:hover': {
            color: themes.authentication.palette.common.white,
            backgroundColor:
              themes.authentication.palette.primary.main,
          },
        }}
      >
        {title}
      </Link>
      <ModalDialog
        error
        maxWidth="sm"
        open={showModal}
        errorMessage={errorMessage}
        onClose={() => setShowModal(false)}
        errorTitle="Erro ao acessar o arquivo"
      />
      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}
    </>
  );
};

export default ButtonFile;
