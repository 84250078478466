export const wrapperStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 520,
  bgcolor: 'grey.100',
  borderRadius: '6px',
  border: 'none',
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    md: '112px 1fr',
  },
  '&:focus': { outline: 'none' },
};

export const boxLeftStyle = {
  bgcolor: 'grey.400',
  height: '100%',
  p: {
    xs: 2,
    md: 4,
  },
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: {
    xs: 1,
    md: 4,
  },
  borderRadius: {
    xs: '6px 6px 0 0',
    md: '6px 0 0 6px',
  },
};