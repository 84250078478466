import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants, Crypto } from '@2ndmarket/helpers';
import { CryptoBalanceEmpty, ICryptoBalance, IServiceResponse } from '@2ndmarket/types';

const useCryptoBalance = (
  crypto: string,
  wallet_id: number,
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<ICryptoBalance> => {
  const coin_type = crypto.toUpperCase();
  let key;

  switch (coin_type) {
    case Crypto.BKAI.toUpperCase():
    case Crypto.BTC.toUpperCase():
      if (wallet_id > 0) {
        key = {
          endpoint: `/v4/crypto/users/${user_id}/coin/${coin_type}/wallet/${wallet_id}/balance`,
        };
      }
      break;
  }

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<ICryptoBalance, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? CryptoBalanceEmpty,
    loading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export default useCryptoBalance;
