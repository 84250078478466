import { useMe } from '@2ndmarket/services';

import {
  OnboardingProgress,
  ExternalUrls,
  ProductCnpjs,
  ProductUrls,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

interface Props {
  props?: any;
  cnpj?: string;
}

const Copyright: React.FC<Props> = ({ props, cnpj }) => {
  const { data: me } = useMe();

  return me.onboarding_progress ===
    OnboardingProgress.NEWUSER ? (
    <Box
      height={64}
      width="100%"
      textAlign="center"
      alignItems="center"
      display="inline-flex"
      alignContent="center"
      justifyContent="center"
      mt={4}
      sx={{
        bgcolor: 'grey.100',
      }}
    >
      <Box>
        <Typography
          {...props}
          align="center"
          variant="body2"
          component="span"
          color="text.disabled"
        >
          {'Copyright © '}
          <Link
            target="_blank"
            color="primary.main"
            href={ExternalUrls.SECOND_MARKET}
          >
            2ND Market
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box
      width="100%"
      marginBottom={2}
      textAlign="center"
      alignItems="center"
      display="inline-flex"
      alignContent="center"
      justifyContent="center"
    >
      <Box>
        <Typography>
          <Link
            target="_blank"
            color="text.primary"
            href={ProductUrls.PRIVACY_POLICY}
          >
            Política de Privacidade
          </Link>
          {'  '}
          <Link
            target="_blank"
            color="text.primary"
            href={ProductUrls.SUPPORT}
          >
            Preciso de Ajuda
          </Link>
        </Typography>
        <Typography
          {...props}
          align="center"
          variant="body1"
          component="span"
          color="text.primary"
        >
          {'© '}
          {new Date().getFullYear()}{' '}
          <Link
            target="_blank"
            color="primary.main"
            href={ExternalUrls.SECOND_MARKET}
          >
            2ND Market
          </Link>
          {' - Todos os direitos reservados '}
        </Typography>
        <Typography component="p" variant="body1">
          CNPJ {cnpj ? cnpj : ProductCnpjs.SECOND_MARKET}
        </Typography>
      </Box>
    </Box>
  );
};

export default Copyright;
