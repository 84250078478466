import styled from 'styled-components';
import { theme } from 'styled-tools';

export const PaginationButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PagButton = styled.button`
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  background-color: ${theme('colors.secondary.1')};

  &.next {
    border: 1px solid ${theme('colors.primary.main')};
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;

    :hover {
      background-color: ${theme('colors.primary.medium')};
    }
  }

  &.prev {
    border: 1px solid ${theme('colors.primary.main')};
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;

    :hover {
      background-color: ${theme('colors.primary.medium')};
    }
  }

  &.disabled {
    display: none;
  }
`;

export const PagInfo = styled.span`
  margin: 0 20px;
  font-size: 14px;
  color: ${theme('colors.secondary.3')};

  .per-page {
    padding: 6px 13px;
    margin-right: 10px;
    background: ${theme('colors.secondary.1')};
    border: 1px solid ${theme('colors.secondary.2')};
  }
`;
