import { themes } from '@2ndmarket/components';
import { Names, Product } from '@2ndmarket/helpers';

const Products = [
  {
    active: true,
    name: Names.dashboard,
    key: Product.DASHBOARD,
    infos: '',
    destination: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}`,
    color: themes.authentication.colors.products.authentication,
    text: '',
    card: false,
  },
  {
    active: true,
    name: Names.bankai,
    key: Product.BANKAI,
    infos: 'TED, Pix e Boleto',
    destination: `${process.env.NEXT_PUBLIC_BANKAI_URL}`,
    color: themes.authentication.colors.products.bankai,
    text: 'Transfira e receba em reais ou em cripto de forma simplificada e movimente sua conta livremente.',
    card: true,
  },
  {
    active: true,
    name: Names.braex,
    key: Product.BRAEX,
    infos: 'Compre, venda e negocie.',
    destination: `${process.env.NEXT_PUBLIC_BRAEX_URL}`,
    color: themes.authentication.colors.products.braex,
    text: 'Transacione criptomoedas na melhor e mais completa plataforma de trade do mercado blockchain.',
    card: true,
  },

  {
    active: false,
    name: Names.tokenaim,
    key: Product.TOKENAIM,
    infos: '',
    destination: `${process.env.NEXT_PUBLIC_TOKENAIM_URL}`,
    color: themes.authentication.colors.products.tokenaim,
    text: '',
    card: false,
  },
];

export default Products;
