import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { theme } from 'styled-tools';

interface SelectProps extends SpaceProps {
  error?: boolean;
  filled?: boolean;
  small?: boolean;
  disabled?: boolean;
  placeholder?: string | undefined;
  icon?: boolean | undefined;
}

const labelFloat = css`
  font-size: 12px;
  line-height: 15px;
  top: 8px;
  font-weight: 500;
`;

export const Parent = styled.div<SelectProps>`
  background-color: ${theme('colors.white')};
  border: none;
  border-color: ${({ error }) =>
    error
      ? theme('colors.error.main')
      : theme('colors.secondary.4')};
  border-radius: 2px;
  color: ${theme('colors.secondary.1')};
  font-weight: 400;
  font-size: 16px;
  height: ${({ small }) => (small ? 32 : 56)}px;
  line-height: 22px;
  position: relative;
  width: 100%;
  ${space}

  & > i {
    cursor: default;
    font-size: 10px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    color: ${theme('colors.secondary.3')};
  }

  :focus-within {
    border-color: ${theme('colors.primary.main')};
    outline: none;
    color: ${theme('colors.primary.main')};

    & > label {
      color: ${theme('colors.primary.main')};
    }
  }

  .selectIcon {
    position: absolute;
    max-width: 32px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SelectCustom = styled.select<SelectProps>`
  appearance: none;
  background-color: transparent;
  color: ${theme('colors.secondary.1')};
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  left: 0;
  line-height: 22px;
  opacity: ${({ filled }) => (filled ? 1 : 0)};
  padding-left: ${({ icon }) => (icon ? 54 : 14)}px;
  padding-top: ${({ placeholder }) =>
    placeholder == undefined ? 2 : 12}px;
  position: absolute;
  transition: all 0.25s ease;
  width: 100%;
  cursor: pointer;

  &:disabled {
    color: ${theme('colors.secondary.3')};
  }
`;

export const Placeholder = styled.label<SelectProps>`
  color: ${({ error, disabled }) =>
    error
      ? theme('colors.error.main')
      : disabled
      ? theme('colors.secondary.3')
      : theme('colors.secondary.2')};
  cursor: text;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  left: 15px;
  position: absolute;
  top: 20px;
  transition: all 0.25s ease;

  ${({ filled }) =>
    filled &&
    css`
      ${labelFloat}
      color: ${theme('colors.secondary.3')};
    `}
`;

export const ErrorMessage = styled.span`
  color: ${theme('colors.error.main')};
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  left: 0;
  bottom: 0px;
  transform: translateY(100%);
`;
