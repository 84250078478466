import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';

const useIntent = (
  user_id: number | AppConstants.ME = AppConstants.ME,
  intent_id: number,
  type: string,
  is_register?: boolean,
  refreshInterval: number = 1000,
) => {
  const key = {
    endpoint: `/v4/users/${user_id}/transaction/intent?intent_id=${intent_id}&type=${type}&is_register=${is_register}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    refreshInterval: refreshInterval,
  };

  const { data, error, mutate } = useSWR(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    intent: data,
    loading: !error && !data,
    error,
    mutate: mutate,
  };
};

export default useIntent;
