export const cryptoAssets = {
	BTC: {
		name: "Bitcoin",
		icon: "bitcoin",
		abbreviation: "btc",
	},
	BKAI: {
		name: "Bkaicoin",
		icon: "bkai-coin",
		abbreviation: "bkai",
	},
	ETH: {
		name: "Ethereum",
		icon: "ethereum",
		abbreviation: "eth",
	},
	ADA: {
		name: "Cardano",
		icon: "cardano",
		abbreviation: "ada",
	},
	USDT: {
		name: "Dólar Tether",
		icon: "tether",
		abbreviation: "usdt",
	},
};

export interface ICryptoAsset {
  name: string,
  icon: string,
  abbreviation: string,
}