import useSWR from 'swr';

import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

import {
  IUser,
  UserEmpty,
  IServiceResponse,
} from '@2ndmarket/types';

const useMe = (
  user_id: number | AppConstants.ME = AppConstants.ME,
): IServiceResponse<IUser> => {
  const key = {
    endpoint: `/v4/users/${user_id}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IUser, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? UserEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useMe;
