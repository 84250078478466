const enum Status {
	ALL = "all",
  ANALYZING = "analysing",
	APPROVED = "approved",
	BANK_VALIDATION = "bank_validation",
	BLOCKED = "blocked",
	CANCELLED = "cancelled",
	CONFIRMED = "confirmed",
	EXECUTED = "executed",
	FINISHED = "finished",
	LOADING = "loading",
	NONE = "none",
	PARTIALLY_EXECUTED = "partially_executed",
	PROCESSING = "processing",
	PHONE_VALIDATION = "phone_validation",
	PENDING = "pending",
	RECEIVED = "received",
	REFUSED = "refused",
	REJECTED = "rejected",
	SCHEDULED = "scheduled",
}

export default Status;
