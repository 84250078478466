import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Crypto,
  cryptoAssets,
  fiatFormat,
  formatBRL,
  parseBRL,
  satoshiFormat,
  toBitcoin,
  yupPt,
} from '@2ndmarket/helpers';
import { useDebounce } from '@2ndmarket/hooks';
import { useCryptoConversion } from '@2ndmarket/services';
import { ITransferData, TradeType } from './Types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

interface Props {
  cryptoName: Crypto;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  setTransferData: React.Dispatch<
    React.SetStateAction<ITransferData>
  >;
}

const PriceStep: React.FC<Props> = ({
  cryptoName,
  setTransferData,
  setStep,
}) => {
  const [operation, setOperation] = useState(TradeType.BUY);
  const [valueType, setValueType] = useState<string>(cryptoName);
  const [amount, setAmount] = useState<string>('');
  const [amountCoin, setAmountCoin] = useState<number>(0);

  const defaultValues = { amount: 0 };
  const schema = yupPt.object().shape({
    amount: yupPt
      .string()
      .transform(originalValue => {
        const formattedValue = originalValue.replace(
          /[^\d]/g,
          '',
        );
        return formattedValue;
      })
      .test(
        'min-amount',
        'Deve ser maior ou igual a 1 satoshi',
        value => {
          const centsValue = Number(value);
          return centsValue >= 0.00000001;
        },
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  let amountDebouncer = useDebounce(amountCoin.toString(), 600);
  const {
    data: cryptoConversion,
    loading: cryptoConversionLoading,
  } = useCryptoConversion(
    cryptoName,
    +amountDebouncer,
    valueType,
    operation,
  );
  const cryptoNameAsset = Object.values(cryptoAssets).find(
    asset => asset.abbreviation === cryptoName,
  );
  const handleOperation = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: TradeType | null,
  ) => {
    if (newAlignment !== null) {
      setOperation(newAlignment);
    }
  };

  useEffect(() => {
    if (valueType == Crypto.BRL) {
      setAmount(formatBRL(parseBRL('')));
      setAmountCoin(0);
    } else {
      setAmount('');
      setAmountCoin(0);
    }
  }, [valueType]);

  function handleNumberChange(value: string) {
    if (valueType == Crypto.BRL) {
      setAmountCoin(parseBRL(value));
      setAmount(formatBRL(parseBRL(value)));
    } else {
      let cleanedValue = value
        .replace(',', '.')
        .replace(/[^0-9.]/g, '');

      const decimalCount = cleanedValue.split('.').length - 1;
      if (decimalCount > 1) {
        cleanedValue = cleanedValue.slice(
          0,
          cleanedValue.lastIndexOf('.'),
        );
      }
      const satoshiAmount = satoshiFormat(+cleanedValue);
      setAmountCoin(Math.floor(satoshiAmount));
      setAmount(cleanedValue);
    }
  }

  function onSubmit(data: { amount: number }) {
    if (!data.amount) {
      return;
    }
    setTransferData({
      amount: amount,
      amountCoin:
        valueType == Crypto.BRL
          ? cryptoConversion.crypto_value
          : amountCoin,
      valueType: valueType,
      tradeType: operation,
    });
    setStep('step2');
  }

  return (
    <Box
      mb={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography
        variant="h4"
        component="h4"
        color="text.primary"
        mb={2}
      >
        Comprar e vender
      </Typography>
      <ToggleButtonGroup
        value={operation}
        exclusive
        onChange={handleOperation}
        sx={{
          mb: 3,
          gap: '10px',
          '.MuiButtonBase-root': {
            border: 'none',
            bgcolor: 'secondary.light',
            height: '48px',
            color: 'text.primary',
            px: '36px',
            '&:not(:first-of-type)': {
              borderRadius: '3px 3px 3px 3px',
            },
            '&:not(:last-of-type)': {
              borderRadius: '3px 3px 3px 3px',
            },
            '&.Mui-selected': {
              bgcolor: 'primary.main',
              color: 'common.white',
              '&:hover': {
                bgcolor: 'primary.main',
              },
            },
          },
        }}
      >
        <ToggleButton
          value={TradeType.BUY}
          aria-label="left aligned"
        >
          Comprar
        </ToggleButton>
        <ToggleButton
          value={TradeType.SELL}
          aria-label="centered"
        >
          Vender
        </ToggleButton>
      </ToggleButtonGroup>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 3fr',
          gap: '10px',
          mb: 4,
        }}
      >
        <TextField
          select
          fullWidth
          margin="dense"
          variant="outlined"
          label="Valor em"
          value={valueType}
          onChange={e => setValueType(e.target.value)}
        >
          <MenuItem key={cryptoName} value={cryptoName}>
            {cryptoNameAsset?.name}
          </MenuItem>
          <MenuItem key={Crypto.BRL} value={Crypto.BRL}>
            Reais
          </MenuItem>
        </TextField>
        <TextField
          {...register('amount', { required: true })}
          fullWidth
          margin="dense"
          label="Valor"
          autoComplete="off"
          value={amount}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
          ) => {
            handleNumberChange(event.target.value);
          }}
          InputProps={{
            inputProps: {
              inputMode: 'numeric',
              maxLength: valueType ? 16 : 10,
            },
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  '.MuiTypography-root': {
                    color: 'grey.300',
                    fontSize: '14px',
                  },
                }}
              >
                {`≈ ${
                  valueType == Crypto.BRL
                    ? toBitcoin(cryptoConversion.crypto_value) +
                      ' ' +
                      cryptoName.toUpperCase()
                    : formatBRL(
                        Number(amount) *
                          cryptoConversion.exchange_value,
                      )
                }`}
              </InputAdornment>
            ),
          }}
          error={Boolean(errors.amount)}
          helperText={errors.amount && errors.amount.message}
          sx={{ mb: 4 }}
        />
      </Box>
      <Box
        sx={{
          mb: 4,
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'grey.200',
          py: 2,
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '.MuiListItem-root': {
              p: 0,
              '.MuiListItemText-root': {
                display: 'flex',
                justifyContent: 'space-between',
                '.MuiListItemText-secondary': {
                  fontSize: '16px',
                },
                '& span.MuiListItemText-secondary': {
                  color: 'primary.main',
                },
              },
            },
          }}
        >
          <ListItem>
            <ListItemText
              primary="Valor de mercado"
              secondary={formatBRL(
                cryptoConversion.exchange_value,
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Taxas"
              secondary={`≈ ${
                cryptoConversion.fees.fee_coin.toLowerCase() ==
                Crypto.BRL
                  ? fiatFormat(cryptoConversion.fees.total_fees)
                  : toBitcoin(cryptoConversion.fees.total_fees)
              } ${cryptoConversion.fees.fee_coin}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                operation == TradeType.BUY
                  ? 'Total da compra'
                  : 'Total da venda'
              }
              secondary={`≈ ${
                cryptoConversion.fees.final_amount_coin.toLowerCase() ==
                Crypto.BRL
                  ? fiatFormat(
                      cryptoConversion.fees.final_amount,
                    )
                  : toBitcoin(cryptoConversion.fees.final_amount)
              } ${cryptoConversion.fees.final_amount_coin}`}
              secondaryTypographyProps={{ component: 'span' }}
            />
          </ListItem>
        </List>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          disabled={cryptoConversionLoading}
        >
          {operation == TradeType.BUY ? 'Comprar' : 'Vender'}
        </Button>
      </Box>
    </Box>
  );
};

export default PriceStep;
