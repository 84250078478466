import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  display: flex;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 34px;
    height: 18px;
    background: ${theme('colors.secondary.4')};
    display: block;
    border-radius: 18px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 1.5px;
    left: 1.5px;
    width: 15px;
    height: 15px;
    background: ${theme('colors.white')};
    border-radius: 90px;
    transition: 0.3s;
  }

  input:disabled {
    pointer-events: none;
  }

  input:disabled + label {
    opacity: 0.15;
  }

  input:checked + label:after {
    left: calc(100% - 1.5px);
    transform: translateX(-100%);
  }

  input:checked + label {
    background-color: ${theme('colors.primary.main')};
  }
`;
