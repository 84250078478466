import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

export interface ContentProps {
  large?: boolean;
  brand?: string;
}

export const Content = styled.div<ContentProps>`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  color: ${theme('colors.white')};
  display: flex;
  height: ${({ large }) => (large ? 105 : 40)}px;
  justify-content: center;
  overflow: hidden;
  padding: 2px;
  width: ${({ large }) => (large ? 105 : 40)}px;

  @media (max-width: ${theme('breakpoints.mobile')}) {
    background-color: ${theme('colors.primary.light')};

    ${prop =>
      prop.brand === 'dashboard' &&
      css`
        background-color: rgba(255, 255, 255, 0.15);
      `}

    ${prop =>
      prop.brand === 'braex' &&
      css`
        background-color: transparent;

        p {
          font-size: 16px;
        }
      `}
  }

  & > img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
  }

  p {
    font-weight: 500;
    font-size: ${({ large }) => (large ? 16 : 12)}px;
    color: ${theme('colors.white')};
  }

  ${prop =>
    prop.brand === 'braex' &&
    css`
      width: 40px;
      height: 30px;
    `}
`;
