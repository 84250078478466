import { useState, useEffect } from 'react';
/**
 * Hook for delaying the state update by a provided milliseconds
 * @param value The state value to be updated
 * @param delay The amount of time to way in milliseconds
 * @returns The debounced value state
 */
export default function useDebounce(value: string, delay: number) {

  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {

    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    
  }, [value, delay]);

  return debouncedValue;
}