export interface IStatementCryptoRequest {
  year: string;
  month: string;
  ordering?: string;
  page: number;
  page_size: number;
}

export interface IStatementCrypto {
  totalRecords: number;
  pageSize: number;
  startRecord: number;
  lastPage: number;
  currentPage: number;
  headers: [];
  results: [];
}

export const StatementCryptoEmpty: IStatementCrypto = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  headers: [],
  results: [],
};
