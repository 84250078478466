import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const fiatVerify = function (
  code: string,
  user_id: number | AppConstants.ME = AppConstants.ME,
) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/users/${user_id}/digital-account/code/verify?code=${code}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });
};

export default fiatVerify;
