const enum Permission {
  Any = "any",
  Bank = "bank",
  City = "city",
  Country = "country",
  Fee = "fee",
  Guest = "guest",
  Logs = "logs",
  Order = "order",
  Permission = "permission",
  Role = "role",
  Sac = "sac",
  State = "state",
  Staff = "staff",
  User = "user",
  User_Address = "user_address",
  User_Bkai = "user_bkai",
  User_Btc = "user_btc",
  User_Card = "user_card",
  User_Document = "user_document",
  User_Fiat = "user_fiat",
  User_Phone = "user_phone",
  User_Profile = "user_profile",
  User_Selfie = "user_selfie",
  Announcement = "announcement",
}

export default Permission;
