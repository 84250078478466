import { UserStatus } from "@2ndmarket/helpers";

export interface IStaffsResults {
  id: number,
  name: string,
  email: string,
  cpf: string,
  phone: string,
  status: UserStatus,
  status_message: string,
  role: {
    id: number,
    created_at: string,
    updated_at: string,
    name: string,
    type: string,
    description: string
  },
  created_at: string,
  updated_at: string
}


export interface IStaffs   {
  totalRecords: number,
  pageSize: number,
  startRecord: number,
  lastPage: number,
  currentPage: number,
  results: Array<IStaffsResults>,
}

export const StaffsEmpty: IStaffs = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [
    {
      id: 0,
      name: '',
      email: '',
      cpf: '',
      phone: '',
      status: UserStatus.APPROVED,
      status_message: '',
      role: {
        id: 0,
        created_at: '',
        updated_at: '',
        name: '',
        type: '',
        description: ''
      },
      created_at: '',
      updated_at: ''
    }
  ],
};