const enum EducationLevel {
	DOCTORATE = "DOCTORATE",
	FIRST_DEGREE = "FIRST_DEGREE",
	FULL_MASTERS = "FULL_MASTERS",
	FULL_SPECIALIZATION = "FULL_SPECIALIZATION",
	GRADUATED = "GRADUATED",
	HIGH_SCHOOL = "HIGH_SCHOOL",
	ILLITERATE = "ILLITERATE",
	INCOMPLETE_HIGHER = "INCOMPLETE_HIGHER",
	INCOMPLETE_MASTERS = "INCOMPLETE_MASTERS",
	INCOMPLETE_SPECIALIZATION = "INCOMPLETE_SPECIALIZATION",
}

export default EducationLevel;