import authenticatedFetcher from '../authenticatedFetcher';

const findTargetUser = function (target: string) {
  return authenticatedFetcher({
    endpoint: `/v4/fiat/find-user?target_cpf_cpnj=${target}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

export default findTargetUser;
