import React, { useState } from 'react';
import Icon from '../Icon';
import { Status } from '@2ndmarket/helpers';
import {
  ErrorMessage,
  Parent,
  Placeholder,
  SelectCustom,
} from './styles';

interface OptionProps {
  value: string | number | string[];
  label: string;
  icon?: string | undefined;
  disabled?: boolean;
}

export type SelectProps = {
  options: OptionProps[];
  placeholder?: string | undefined;
  value: string;
  small?: boolean;
  disabled?: boolean;
  error?: string;
  icon?: boolean;
  onChange: (value: string) => void;
  dataTestid?: string;
};

const Select: React.FC<SelectProps> = React.forwardRef(
  (
    {
      disabled,
      options,
      placeholder,
      small,
      error,
      value,
      icon,
      onChange,
      dataTestid,
    },
    ref,
  ) => {
    const isFilled = !!value;
    const [selectedIcon, setSelectedIcon] = useState('');

    const LoadedIcon = () => {
      const initialIcon = options.find(
        x => x.value == value,
      )?.icon;
      return (
        <img src={initialIcon} alt="" className="selectIcon" />
      );
    };

    function changeSelect(selectedValue: any) {
      onChange(selectedValue);

      if (icon) {
        const changedIcon = options.find(
          x => x.value == selectedValue,
        )?.icon;
        setSelectedIcon(changedIcon ? changedIcon : '');
      }
    }

    return (
      <Parent
        small={small}
        error={error ? true : false}
        filled={isFilled}
      >
        {placeholder && (
          <Placeholder
            error={error ? true : false}
            filled={isFilled}
          >
            {placeholder}
          </Placeholder>
        )}
        <SelectCustom
          data-testid={dataTestid}
          disabled={disabled}
          filled={isFilled}
          value={value}
          icon={icon}
          placeholder={placeholder}
          onChange={e => changeSelect(e.target.value)}
        >
          <option value="" disabled>
            Selecione
          </option>

          {options.map((item, index) => (
            <option
              key={index}
              value={item.value}
              disabled={
                item.value == Status.PROCESSING || item.disabled
              }
            >
              {item.label}
            </option>
          ))}
        </SelectCustom>
        {icon && value && !selectedIcon && <LoadedIcon />}

        {selectedIcon && (
          <img
            src={selectedIcon}
            alt=""
            className="selectIcon"
          />
        )}

        <Icon name="arrow" />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Parent>
    );
  },
);

Select.displayName = 'Select';

export default Select;
