import React from 'react';
interface AppContextInterface {
  showBalance: boolean;
  setShowBalance: (show: boolean) => void;
}

const AppContext =
  React.createContext<AppContextInterface | null>(null);

export default AppContext;
