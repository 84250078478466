declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    clear: true;
  }
}

const Button = (theme: any) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          height: 50,
          borderRadius: 3,
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[200],
          },
          '&.MuiButton-containedSecondary': {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
              border: 'none',
            },
          },
        },
        contained: {},
        containedPrimary: {},
        containedSecondary: {
          boxShadow: 'none',
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.light,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.light,
          },
        },
      },
      variants: [
        {
          props: { variant: 'clear' as const },
          style: {
            padding: '0',
            height: 'auto',
            minWidth: 'auto',
            color: theme.palette.text.secondary,
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
            },
          },
        },
      ],
    },
  };
};

export default Button;
