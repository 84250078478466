import styled from 'styled-components';
import { theme } from 'styled-tools';
import { BulletProps } from './Bullet';

export const Container = styled.span<BulletProps>`
  background-color: ${({ variant }) =>
    theme(`colors.status.${variant}`)};
  border-radius: 50%;
  display: block;
  height: 15px;
  width: 15px;

  @media (max-width: ${theme('breakpoints.lg')}) {
    height: 12px;
    width: 12px;
  }
`;
